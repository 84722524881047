import GroupStore from 'mewe/stores/group-store';
import { ds } from 'mewe/stores/ds';
import { loadingDialog } from 'mewe/utils/dialog-utils';
import { Theme } from 'mewe/constants';

export const openGroupFtueDialog = (group, ctx) => {
  loadingDialog();

  import(/* webpackChunkName: 'app.dialog.invitations-dialog' */ 'mewe/dialogs/group-initialisation-dialog') // jshint ignore:line
    .then((Dialog) => {
      // jshint ignore:line

      Dialog.default
        .make({
          ctx: ctx,
          group: group,
        })
        .send('open');
    });
};

export const showApplicationAlert = (ctx) => {
  loadingDialog();

  import('mewe/dialogs/simple-dialog').then((Dialog) => {
    Dialog.default
      .make({
        ctx: ctx,
        type: 'alert-simple',
        title: __('Application to the group'),
        message: __(
          `Your application has been sent to the group owner for approval. You'll be notified when the owner approves your application to join.`
        ),
        width: 500,
        noEscaping: true,
      })
      .send('open');
  });
};

export const openFtueRecommendations = (ctx, origin, ftueFlow) => {
  loadingDialog();

  import('mewe/dialogs/discover-interests-dialog').then((Dialog) => {
    Dialog.default.make(Object.assign({ ctx: ctx, origin: origin, ftueFlow: ftueFlow })).send('open');
  });
};

export const openPostbox = (params) => {
  // we used to open postbox with preselected group by just passing groupId
  if (params.groupId && !params.preselectedGroup) {
    params.preselectedGroup = GroupStore.getState({ id: params.groupId });
  }

  if (params.eventId && !params.preselectedEvent) {
    params.preselectedEvent = ds.events.for('all-events').items.findBy('id', params.eventId);
  }

  loadingDialog();

  import('mewe/dialogs/postbox-dialog').then((Dialog) => {
    Dialog.default.make(params).send('open');
  });
};

export const showGroupJoinedDialog = (ctx, group) => {
  loadingDialog();

  import('mewe/dialogs/group-joined-dialog').then((Dialog) => {
    Dialog.default
      .make({
        ctx: ctx,
        group: group,
      })
      .send('open');
  });
};

export const showGroupPreviewDialog = (ctx, group, options = {}) => {
  loadingDialog();

  import('mewe/dialogs/group-preview-dialog').then((Dialog) => {
    Dialog.default
      .make({
        ctx: ctx,
        groupFromParent: group,
        closeBtnText: __(`Got it!`),
        options: options,
        dontHideLoader: true,
        theme: Theme.CONTACTS,
      })
      .send('open');
  });
};

export const showNewToMeWePopup = (ctx) => {
  loadingDialog();

  import('mewe/dialogs/new-to-mewe-dialog').then((Dialog) => {
    Dialog.default.make({ ctx: ctx }).send('open');
  });
};
