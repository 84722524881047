import Route from 'mewe/routes/route';

export let GoogleauthRouteBase = {
  queryParams: {
    code: {
      refreshModel: false,
    },
  },

  beforeModel() {
    // this.routeComponent();
  },

  model(params, transition) {
    let { to: toRouteInfo } = transition;

    return {
      code: toRouteInfo.queryParams.code,
    };
  },
};

export default Route.extend(GoogleauthRouteBase);
