import Model from 'mewe/utils/store-utils/model/model.js';
import { attr } from 'mewe/utils/store-utils/model/attribute';
import { modelProfileId } from 'mewe/stores/models/mixins/model-profile-id';

var model = Model.extend(modelProfileId, {
  id: attr('string'),
  publicLinkId: attr('string'),
  descriptionPlain: attr('string'),
  firstName: attr('string'),
  fprint: attr('string'),
  lastName: attr('string'),
  name: attr('string'),
  locale: attr('string'),
  _links: attr('object'),
});

model.reopenClass({
  resourceName: 'user',
});

export default model;
