import Ember from 'ember';
import { each } from 'lodash';
const { inject } = Ember;
import Controller from 'mewe/action-controllers/action-controller';
import PS from 'mewe/utils/pubsub';
import { ds } from 'mewe/stores/ds';
import FeedUtils from 'mewe/utils/feed-utils';
import { removePost } from 'mewe/stores/models/feed-model';
import { handleOtherStores, getWrapperFeed } from 'mewe/fetchers/fetch-feed';
import { fetchWrappers } from 'mewe/fetchers/fetch-wrappers';

export const DEFAULT_TOP_OF_FEED = 400;

export default Controller.extend({
  routing: inject.service('-routing'),

  init() {
    this._super();

    PS.Sub('post.add', this.add.bind(this));
    PS.Sub('post.remove', this.remove.bind(this));
    PS.Sub('post.edit', this.edit.bind(this));
    PS.Sub('post.emoji.add', this.emojiAdd.bind(this));
    PS.Sub('post.emoji.remove', this.emojiRemove.bind(this));
    PS.Sub('wrapper.post.add', this.addPostToWrapper.bind(this));
    PS.Sub('poll.vote.add', this.addVoteToPoll.bind(this));
    PS.Sub('poll.vote.remove', this.removeVoteFromPoll.bind(this));
    PS.Sub('poll.vote.change', this.changeVoteFromPoll.bind(this));
    PS.Sub('page.unpublished', this.removeWrapper.bind(this));
    PS.Sub('post.link.updated', this.updatePostLink.bind(this));
    PS.Sub('group.member.remove', this.cleanAfterGroupMember.bind(this));
  },

  getCurrentTheme() {
    const appRoute = Ember.getOwner(this).lookup(`route:app`);
    return appRoute.get('currentModel.theme');
  },

  add(data) {
    if (!data) return;

    // private post WS updates are all done in chat-service via chat.message.add
    // as it is not in feed directly anymore and it is easier as we need to pull post from server afterwards
    if (data.threadId) return;

    handleOtherStores(data);

    if (data.post) data.post.isWS = true;

    this.dispatch('feed', 'addPost', data.post, data);
  },

  addPostToWrapper(post) {
    const wrapperId = post.pageId ? post.pageId : post.groupId;
    let wrapper = ds.wrappers.items.findBy('id', wrapperId);

    if (wrapper) {
      Ember.set(wrapper, 'type', post.pageId ? 'page' : 'group');

      let posts = getWrapperFeed(wrapperId).posts;

      if (!posts) {
        wrapper.setProperties({
          lastActivity: new Date().getTime() / 1000,
          postsCount: wrapper.postsCount + 1,
        });
      } else {
        // checking for duplicated posts
        let existingPost = posts.findBy('postItemId', post.postItemId);

        if (!existingPost) {
          wrapper.setProperties({
            lastActivity: new Date().getTime() / 1000,
            postsCount: wrapper.postsCount + 1,
          });

          // add post to wrapper only if wrappers are currently opened, otherwise group posts
          // should be fetched after opening to prevent inconsistency in data (like in SG-16944)
          if (document.querySelector('.wrappers-dialog')) {
            posts.unshiftObject(post);
          }
        }
      }
    } else {
      fetchWrappers(ds.wrappers.scope);
    }
  },

  emojiRemove(data) {
    let posts = FeedUtils.matchPostsFromAllFeeds(data.postItemId);

    each(posts, (p) => {
      each(data.emojis, (e) => {
        this.dispatch('emojify', 'removeEmoji', p, e, data.userId);
      });
    });
  },

  emojiAdd(data) {
    let posts = FeedUtils.matchPostsFromAllFeeds(data.postItemId);

    each(posts, (p) => {
      each(data.emojis, (e) => {
        this.dispatch('emojify', 'addEmoji', p, e, data.userId);
      });
    });
  },

  updatePostLink(data) {
    let posts = FeedUtils.matchPostsFromAllFeeds(data.postItemId);

    each(posts, (p) => {
      p.setProperties({
        'link._links.thumbnail': { href: data.photoUrl },
        'link.thumbnailSize': data.size,
      });
    });
  },

  remove(post) {
    // removing from wrappers should be done before general removal from store so it can be checked if post exists in wrapper for some wrapper updates
    // only for posts removed from group
    if (post.groupId) {
      this.dispatch('group', 'decreaseNewPostsCounter', post.postItemId, post.groupId); // this counter should be updated even if group alerts are muted for given group
      this.dispatch('feed', 'removePostFromWrapper', post);
    }

    if (post.pageId) {
      this.dispatch('pages', 'decreaseNewPostsCounter', post.postItemId, post.pageId);
      this.dispatch('feed', 'removePostFromWrapper', post);
    }

    // about photoStream update:
    // currently no useful data to know which photos were deleted, only postItemId in this msg
    // only if post to remove will be found in store then stream will be updated basing on that post's data
    const postsToRemove = FeedUtils.matchPostsFromAllFeeds(post.postItemId);
    if (postsToRemove) {
      each(postsToRemove, (post) => {
        if (post.postType === 'photo' || post.postType === 'photos') {
          PS.Pub('photoStream.update', { post: post, type: 'remove' });
        }
      });
    }

    removePost({ postItemId: post.postItemId });

    this.dispatch('feed', 'refPostRemovedUpdate', post.postItemId);
  },

  cleanAfterGroupMember(data = {}) {
    if (!data.userId || !data.groupId) return;

    // clean group feed/pending feed
    const groupPosts = ds.feeds[data.groupId]?.posts || [];
    const groupPendingPosts = ds.feeds[`pending-${data.groupId}`]?.posts || [];
    this.cleanUsersContentInFeed(groupPosts, data.userId);
    this.cleanUsersContentInFeed(groupPendingPosts, data.userId);

    // clean group events feeds
    const groupEvents = ds.events.for('all-events').get('items').findBy('groupId', data.groupId);
    each(groupEvents, (e) => {
      const eventPosts = ds.feeds[e.id]?.posts || [];
      this.cleanUsersContentInFeed(eventPosts, data.userId);
    });
  },

  cleanUsersContentInFeed(postFeed, userId) {
    if (!postFeed?.length || !userId) return;

    // first remove user's posts from the feed
    const userPosts = postFeed.filter((p) => p.userId === userId);
    each(userPosts, (p) => this.remove(p));

    // then clean user's comments/replies in remaining posts
    each(postFeed, (p) => {
      // remove user's comments
      const userComments = p.comments.feed.filter((c) => c.owner?.id === userId);
      each(userComments, (c) => p.comments.feed.removeObject(c));

      // remove user's replies in comments
      each(p.comments.feed, (c) => {
        const userReplies = c.replies?.filter((r) => r.owner?.id === userId);
        each(userReplies, (r) => {
          c.replies.removeObject(r);
          c.decrementProperty('repliesCount');
        });
      });
    });
  },

  edit(post) {
    let postsE = FeedUtils.matchPostsFromAllFeeds(post.postItemId);

    postsE.forEach((E) => {
      E.set('textServer', post.text);
      E.set('editedAt', post.editedTime / 1000);
    });

    // update post also in wrapper
    const wrapperId = post.pageId ? post.pageId : post.groupId;

    if (wrapperId) {
      const wrapper = ds.wrappers.items.findBy('id', wrapperId);
      const wrapperPosts = wrapper ? wrapper.posts : null;

      if (wrapperPosts && wrapperPosts.length) {
        const wrapperPostE = wrapperPosts.findBy('postItemId', post.postItemId);

        if (wrapperPostE) {
          wrapperPostE.set('textServer', post.text);
          wrapperPostE.set('editedAt', post.editedTime);
        }
      }
    }
  },

  addVoteToPoll(data) {
    const posts = FeedUtils.matchPostsFromAllFeeds(data.postItemId);

    Ember.beginPropertyChanges();
    each(posts, (post) => {
      let option = Ember.A(post.get('poll.options')).objectAt(data.option);
      if (this.get('globals.currentUser.id') !== data.user.id) {
        Ember.set(option, 'votes', option.votes + 1);
      } else if (!option.selected) {
        //current user voted from other place
        Ember.set(option, 'votes', option.votes + 1);
        Ember.set(option, 'selected', true);
      }
    });
    Ember.endPropertyChanges();
  },

  removeVoteFromPoll(data) {
    const posts = FeedUtils.matchPostsFromAllFeeds(data.postItemId);

    Ember.beginPropertyChanges();
    each(posts, (post) => {
      let option = Ember.A(post.get('poll.options')).objectAt(data.option);
      if (this.get('globals.currentUser.id') !== data.userId) {
        if (option.votes > 0) {
          Ember.set(option, 'votes', option.votes - 1);
        }
      } else if (option.selected) {
        //current user removed vote from other place
        Ember.set(option, 'selected', false);
        if (option.votes > 0) {
          Ember.set(option, 'votes', option.votes - 1);
        }
      }
    });
    Ember.endPropertyChanges();
  },

  changeVoteFromPoll(data) {
    const posts = FeedUtils.matchPostsFromAllFeeds(data.postItemId);

    Ember.beginPropertyChanges();
    each(posts, (post) => {
      const options = Ember.A(post.get('poll.options'));
      let oldOption = options.objectAt(data.fromOption);
      let newOption = options.objectAt(data.option);

      if (this.get('globals.currentUser.id') !== data.user.id) {
        if (oldOption.votes > 0) {
          Ember.set(oldOption, 'votes', oldOption.votes - 1);
        }
        Ember.set(newOption, 'votes', newOption.votes + 1);
      } else if (!newOption.selected) {
        //current user changed vote from other place
        if (oldOption.votes > 0) {
          Ember.set(oldOption, 'votes', oldOption.votes - 1);
          Ember.set(oldOption, 'selected', false);
        }
        Ember.set(newOption, 'votes', newOption.votes + 1);
        Ember.set(newOption, 'selected', true);
      }
    });
    Ember.endPropertyChanges();
  },

  removeWrapper(data) {
    ds.wrappers.items.removeObject(ds.wrappers.items.findBy('id', data.pageId));
  },
});
