import { computed } from '@ember/object';
import { A } from '@ember/array';
import { attr } from 'mewe/utils/store-utils/model/attribute';
import PermissionsUtils from 'mewe/utils/group-permissions-utils';
import { modelEmojisReady } from 'mewe/stores/models/mixins/model-emojis-ready';
import toDisplay from 'mewe/stores/text-parsers/to-display';
import { parsersDefaultBasic } from 'mewe/stores/text-parsers/to-display';
import SimpleGroupModel from 'mewe/stores/models/simple-group-model';
import EnvironmentUtils from 'mewe/utils/environment-utils';

const defaultAvatar = '/assets/images/group-photo-placeholder-full.png';

var model = SimpleGroupModel.extend(modelEmojisReady, {
  chatMode: attr('string'),
  applicantsCount: attr('number', {
    defaultValue: 0,
  }),
  isMember: attr('boolean'),
  followPosts: attr('boolean'),
  descriptionPlain: attr('string', { defaultValue: '' }),
  description: attr('string', { defaultValue: '' }),
  isConfirmed: attr('boolean'),
  isMuted: attr('boolean'), // muted group alerts
  groupMuted: attr('boolean'), // muted posts in feed
  hiddenChat: attr('boolean'),
  publicUrlId: attr('string'),
  language: attr('string'),
  isPublic: attr('boolean', {
    defaultValue: false,
  }),
  isPublicApply: attr('boolean', {
    defaultValue: false,
  }),
  directoryNotAllowed: attr('boolean'),
  discoveryOptIn: attr('boolean'),
  publicUrl: attr('string'),
  publicRole: attr('string'),
  rolesPermissions: attr('array', {
    defaultValue: function () {
      return A();
    },
  }),
  groupThematicType: attr('string'),
  permissions: attr('array', {
    defaultValue: function () {
      return A();
    },
  }),
  role: attr('string'),
  ownerId: attr('string'),
  adminIds: attr(),
  isInvited: attr('boolean'),
  invitedBy: attr('object'),
  membersCount: attr('number'),
  newPosts: attr('number'),
  newPostsIds: attr('array', {
    defaultValue: A(),
  }),
  applyQuestions: attr('array', {
    defaultValue: A(),
  }),
  mandatoryQuestions: attr('boolean', {
    defaultValue: false,
  }),
  hideAnswers: attr('boolean', {
    defaultValue: false,
  }),
  loggedIn: attr('boolean'),
  owner: attr('object'),
  canReshare: attr('boolean', {
    defaultValue: false,
  }),

  pdFeaturePosition: attr('number'),
  isUniversal: attr('boolean'),
  nameDisplay: attr('string'), // needed for SS

  isUniversalComputed: computed('isUniversal', 'publicUrlId', function () {
    return this.get('isUniversal') || this.get('publicUrlId') === 'mewe';
  }),

  canJoin: computed('isMember', 'alreadyApplied', '_links.inviteConfirm', function () {
    return !this.get('isMember') && !this.get('alreadyApplied') && !this.get('_links.inviteConfirm');
  }),

  groupQuestions: computed('applyQuestions', 'emojisReady', function () {
    return this.applyQuestions.map((q) => {
      let question = typeof q === 'string' ? q : q.question; // in some places (e.g. smart search) questions can be objects instead of array of strings
      return {
        question: question,
        questionDisplay: toDisplay(question, parsersDefaultBasic),
        answer: '',
      };
    });
  }),

  publicUrlNoProtocol: computed('publicUrl', function () {
    if (this.get('publicUrlId'))
      return `${window.location.protocol}//${window.location.host}/join/${this.get('publicUrlId')}`;
    else if (this.get('publicUrl')) return this.get('publicUrl').replace('https://', '');
  }),

  isOwnerAdmin: computed('role', function () {
    return this.get('role') === 'Owner' || this.get('role') === 'Admin';
  }),

  isOwner: computed('role', function () {
    return this.role === 'Owner';
  }),

  isAdmin: computed('role', function () {
    return this.role === 'Admin';
  }),

  isMemberOrInvited: computed('id', function () {
    return this.id && typeof this.id === 'string';
  }),

  canPost: computed('permissions', function () {
    return PermissionsUtils.canPost(this.permissions);
  }),

  canPostWithoutModeration: computed('canPost', 'permissions', function () {
    return this.get('canPost') && !PermissionsUtils.requireModeration(this.get('permissions'));
  }),

  canInvite: computed('permissions', function () {
    return PermissionsUtils.canInvite(this.get('permissions'));
  }),

  // Frontend hack so only owners and admins can create group events inside selective and open groups.
  // Private groups are fine to keep how they are.
  canCreateEvent: computed('canPost', 'canPostWithoutModeration', function () {
    if (this.get('isPublic')) return this.get('isOwnerAdmin');

    return this.get('canPost') && this.get('canPostWithoutModeration');
  }),

  isFetching: attr('boolean'),

  lastVisited: attr('number'),

  profileImage400x400FullUrl: computed('avatarLink', function () {
    return this.get('avatarLink').replace('{imageSize}', '400x400').replace('{static}', '1');
  }),

  avatarLink: computed('_links.{publicAvatar,groupAvatar,avatar}', function () {
    if (this.get('_links.publicAvatar.href')) {
      return EnvironmentUtils.getImgHost(true) + this.get('_links.publicAvatar.href');
    } else if (this.get('_links.avatar.href')) {
      return EnvironmentUtils.getImgHost(true) + this.get('_links.avatar.href');
    } else if (this.get('_links.groupAvatar.href')) {
      return EnvironmentUtils.getImgHost(true) + this.get('_links.groupAvatar.href');
    } else if (this.isFetching) {
      return '';
    } else {
      return defaultAvatar; // cdnHost is added automatically in build process
    }
  }),
});

model.reopenClass({
  resourceName: 'group',
});

export default model;
