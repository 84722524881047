import EmberObject from '@ember/object';
import 'mewe/utils/log-utils';
import { debounce } from '@ember/runloop';
import { isSafari, isChrome85, isFirefox81 } from 'mewe/shared/utils';

// mechanism to not bind scrolling when postbox is opened;
// (can be neutralised - check canBeInPostbox if needed)
let postboxOpen = false;

export const setPostboxOpen = (val) => {
  postboxOpen = val;
};

export const getPostboxOpen = () => {
  return postboxOpen;
};

/**
 * Provides the basic helper functions for infinite scrolling
 *
 * import ScrollingUtils from 'mewe/utils/scrolling-utils';
 */
export default function () {
  return EmberObject.create({
    // call fireFunction ever x moved
    bindScrollTreshold: function (el, fireFunction, x = 100) {
      if (!el) {
        return console.error('scrolling-utils bindScrollTreshold no element');
      }
      if (el.get) {
        el = el.get(0);
      }

      let initial = el.scrollTop;

      const scrollFunc = (e) => {
        let currentScrollTop = e.currentTarget.scrollTop;

        if (initial + x < currentScrollTop || initial - x > currentScrollTop) {
          this.unbindScrollTreshold(el);
          fireFunction();
        }
      };

      if (this.scrollThresholdBind) {
        el.removeEventListener('scroll', this.scrollThresholdBind);
      }

      this.scrollThresholdBind = scrollFunc.bind(this);

      el.addEventListener('scroll', this.scrollThresholdBind);
    },

    unbindScrollTreshold: function (el) {
      if (!el) {
        return console.error('scrolling-utils unbindScrollTreshold no element');
      }
      if (el.get) {
        el = el.get(0);
      }

      el.removeEventListener('scroll', this.scrollThresholdBind);
      this.scrollThresholdBind = null;
    },

    //infinite scrolling for whole page
    bindScrollDown: function (loadFunction, rangeToRun = 450, debounceTime = 100) {
      if (typeof loadFunction !== 'function') {
        return false;
      }

      //Ember.debug('infinite.scrolling: binding scrolling for whole window');

      if (this.scrollDownBind) {
        window.removeEventListener('scroll', this.scrollDownBind);
      }

      const scrollFunc = () => {
        const heightContainer = document.scrollingElement.scrollHeight,
          windowHeight = document.scrollingElement.offsetHeight,
          currentScrollTop = document.scrollingElement.scrollTop;

        if (currentScrollTop + rangeToRun >= heightContainer - windowHeight) {
          if (!postboxOpen) {
            this.unbindScrollDown(window, this.scrollDownBind);
            debounce(this, loadFunction, debounceTime);
          }
        }
      };

      this.set('scrollDownBind', scrollFunc.bind(this));
      window.addEventListener('scroll', this.scrollDownBind);
    },

    //infinite scrolling inside element/div/list
    /**
     * @param {Element} el container
     * @param {Function} loadFunction
     * @param {Number} rangeToRun how 'soon' - how far away from bottom run
     *                            the loading next part - here it means 200px
     *                            from bottom loading is started
     */
    bindScrollDownElement: function (el, loadFunction, rangeToRun = 200, canBeInPostbox = false) {
      if (typeof loadFunction !== 'function') {
        return false;
      }

      // remove after complete transition to plain JS
      if (el && el.get) {
        el = el.get(0);
      }

      //Ember.debug('infinite.scrolling: binding scrolling for element on the page');

      const scrollFunc = () => {
        const heightContainer = el.offsetHeight,
          heightScrolling = el.scrollHeight,
          currentScrollTop = el.scrollTop;

        if (heightContainer + currentScrollTop + rangeToRun >= heightScrolling) {
          //Ember.debug('infinite.scrolling: loading after scrolling element on the page');

          if (!postboxOpen || canBeInPostbox) {
            this.unbindScrollDown(el, this.scrollDownElementBind);
            loadFunction();
          }
        }
      };

      if (!el) {
        el = document.scrollingElement;

        if (this.scrollDownElementBind) {
          window.removeEventListener('scroll', this.scrollDownElementBind);
        }
        this.scrollDownElementBind = scrollFunc.bind(this);
        window.addEventListener('scroll', this.scrollDownElementBind);
      } else {
        if (this.scrollDownElementBind) {
          el.removeEventListener('scroll', this.scrollDownElementBind);
        }
        this.scrollDownElementBind = scrollFunc.bind(this);
        el.addEventListener('scroll', this.scrollDownElementBind);
      }
    },

    //turnoff infinite scrolling inside element/div/list
    unbindScrollDown: function (el, boundFunc) {
      if (!el || el === document.scrollingElement) {
        el = window;
      }
      if (el.get) {
        el = el.get(0);
      }

      if (boundFunc) {
        el.removeEventListener('scroll', boundFunc);
      }
      if (this.scrollDownBind) {
        el.removeEventListener('scroll', this.scrollDownBind);
      }
      if (this.scrollDownElementBind) {
        el.removeEventListener('scroll', this.scrollDownElementBind);
      }
      this.set('scrollDownBind', null);
      this.set('scrollDownElementBind', null);
    },

    // when user hits the bottom/top of scrolling area, background page is not scrolling further
    // event/element
    stopScrollBubbling: function (ev, el, isInvertedEl) {
      //if element doesn't have scroll bar allow to bubble
      if (el.scrollHeight === el.innerHeight) {
        return;
      }

      let scrollTop = el.scrollTop,
        scrollHeight = el.scrollHeight,
        height = el.offsetHeight, // we need to count also paddings
        delta = ev.type == 'DOMMouseScroll' ? ev.originalEvent.detail * -40 : ev.originalEvent.wheelDelta,
        up = delta > 0;

      const prevent = function () {
        ev.stopPropagation();
        ev.preventDefault();
        ev.returnValue = false;
        return false;
      };

      // scrolling is calculated in different way on safari for 'flex column-reverse' order of elements
      const isInvertedScroll = isInvertedEl && (isSafari() || isChrome85() || isFirefox81());

      if (isInvertedScroll) {
        scrollTop = scrollHeight + scrollTop - height;
      }

      if (!up && -delta > scrollHeight - height - scrollTop) {
        // Scrolling down, but this will take us past the bottom.
        el.scrollTop = scrollHeight;
        return prevent();
      } else if (up && delta > scrollTop) {
        // Scrolling up, but this will take us past the top.
        if (isInvertedScroll) {
          el.scrollTop = -scrollHeight - height;
        } else {
          el.scrollTop = 0;
        }
        return prevent();
      }
    },

    disableWindowScroll() {
      // Get the current page scroll position
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;

      // If any scroll is attempted, set this to the previous value
      if (!window.scrollDisabled) {
        window.scrollDisabled = true;
        document.querySelector('body').classList.add('o-hidden');
        window.onscroll = () => {
          window.scrollTo(scrollLeft, scrollTop);
        };
      }
    },

    enableWindowScroll() {
      if (window.scrollDisabled) {
        window.scrollDisabled = false;
        document.querySelector('body').classList.remove('o-hidden');
        window.onscroll = () => {};
      }
    },
  });
}
