import { belongsTo } from 'mewe/utils/store-utils/model/attribute';
import CurrentUserStore from 'mewe/stores/current-user-store';
import SimpleUser from 'mewe/stores/models/simple-user-model';
import { ds } from 'mewe/stores/ds';

export const modelOwner = {
  owner: belongsTo(SimpleUser, {
    defaultValue: function () {
      // both userId and ownerId can occur, depends on particular API
      const userId = this.userId || this.ownerId,
        currentUser = CurrentUserStore.getState();

      if (userId === currentUser.get('id')) {
        return currentUser;
      } else {
        return ds.authors[userId] || this.user;
      }
    },
  }),
};
