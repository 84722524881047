import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

import { getQueryStringParams } from 'mewe/shared/utils';
import PublicPagesApi from 'mewe/api/public-pages-api-unauth';
import Storage from 'mewe/shared/storage';
import jstz from 'jstimezonedetect';
import MathUtils from 'mewe/utils/math-utils';
import { afterRegistration } from 'mewe/shared/registration';
import * as Sentry from '@sentry/ember';
import { isMobile } from 'mewe/shared/utils';
import config from 'mewe/config';

export default class MwDsnpSignupConfirmComponent extends Component {
  @service analytics;
  @service dynamicDialogs;

  @tracked sameBrowserError = false;
  @tracked requestFailed = false;

  // captcha challenge
  challengeOrigin = 'registration';
  arkoseKey = config.arkoseKeyReg;
  hcaptchaSiteKey = config.hcaptcha.registration;
  hcaptchaElemClass = 'h-captcha_registration';
  @tracked afterChallengeCallback; // callback function to captcha test should be set to this to trigger captcha

  @action
  challengeReadyCallback() {
    this.urlParams = getQueryStringParams();

    const platform = this.urlParams.platform;

    if ((platform === 'ios' || platform === 'android') && isMobile()) {
      this.showAppDialog();

      // we want to trigger this only once, that's why it's done
      // here in constructor instead of showAppDialog function
      this.analytics.sendEvent('popupViewed', 'Confirmation Link Redirect');
    } else {
      this.doRegister();
    }
  }

  showAppDialog() {
    this.dynamicDialogs.openDialog('simple-dialog-new', {
      closeable: false, // can be closed by "cancel" button only
      confirmBtnText: __('Open MeWe app'),
      cancelBtnText: __('Use browser'),
      message: __(
        `We recommend using the MeWe app on your mobile device. If you have it installed the button below will open it for you.`
      ),
      onConfirm: () => {
        window.location = `mewe://${window.location.host}${window.location.pathname}${window.location.search}`;
        // confirm button closes the popup. In case the app is not installed we show the popup again so that user can try browser option
        setTimeout(() => this.showAppDialog(), 2000);

        if (!this.redirectAppTracked) {
          this.analytics.sendEvent('buttonClicked', 'Confirmation Link Redirect - app');
          this.redirectAppTracked = true; // we want to track this only once
        }
      },
      onClose: () => {
        if (!this.redirectBrowserTracked) {
          this.analytics.sendEvent('buttonClicked', 'Confirmation Link Redirect - browser');
          this.redirectAppTracked = true; // we want to track this only once
        }

        this.doRegister();
      },
    });
  }

  doRegister() {
    const dsnpAuthDataRaw = Storage.get(Storage.keys.dsnpAuthData);

    let { token, sessionId, email, handle, fname, lname, invitationId, trc, t } = this.urlParams;

    // ultimately we will use query params only, for now we
    // support also storage solution for the transition period
    if (!email || !handle || !fname || !lname) {
      if (dsnpAuthDataRaw) {
        const dsnpAuthData = JSON.parse(dsnpAuthDataRaw);

        email = dsnpAuthData.email;
        handle = dsnpAuthData.handle;
        fname = dsnpAuthData.fname;
        lname = dsnpAuthData.lname;
        invitationId = dsnpAuthData.invitationId;
      }
    }

    // state info for Sentry in case of errors in the process
    let errorInfo = {
      queryParams: window.location.search,
      storageData: dsnpAuthDataRaw,
      missingEmail: !email,
      missingHandle: !handle,
      missingFname: !fname,
      missingLname: !lname,
      missingTraceId: !trc,
      missingSessionId: !sessionId,
      missingValidationCode: !token,
    };

    if (!email || !handle || !fname || !lname) {
      Sentry.captureException(new Error(`Web3 signup email confirmation - missing params.`, errorInfo));
      this.sameBrowserError = true;
      return;
    }

    if (token && sessionId) {
      const registerFunc = (challengeToken, provider, onChallengeFailed) => {
        const params = {
          password: MathUtils.generateId(),
          email: email,
          firstName: fname,
          lastName: lname,
          imOver16: true,
          agreeWithTOS: true,
          publicProfile: true,
          timeZone: jstz.determine().name(),
          validationCode: token,
          sessionId: sessionId,
          session_token: challengeToken,
          challenge_provider: provider,
          t: t || Date.now(),
        };

        // invitationId from email invitation
        if (invitationId) {
          params.invitationId = invitationId;
        }

        PublicPagesApi.registerAccount(params, trc)
          .then((data) => {
            // handle will be needed for popup in FTUE, store it in LS in case it comes from url param
            // other stored values are not needed anymore so they are overwritten
            Storage.set(Storage.keys.dsnpAuthData, JSON.stringify({ handle: handle }));

            afterRegistration(params, data.user);

            window.setTimeout(() => {
              window.location = '/myworld#dsnp';
            }, 1000);
          })
          .catch((error) => {
            if (error.status === 400 && error.data?.errorCode == 117) {
              onChallengeFailed();
            } else {
              this.requestFailed = true;

              errorInfo.respError = error;
              Sentry.captureException(new Error(`Web3 signup email confirmation - registration failed.`, errorInfo));
            }
          });
      };

      const checkEmailFunc = (challengeToken, provider, onChallengeFailed) => {
        // check email request has to be done in order to get the `optcnr` cookie needed for registration request
        PublicPagesApi.checkEmailAvailability(email)
          .then(() => {
            registerFunc(challengeToken, provider, onChallengeFailed);
          })
          .catch((error) => {
            this.requestFailed = true;

            errorInfo.respError = error;
            Sentry.captureException(
              new Error(`Web3 signup email confirmation - check email availability failed.`, errorInfo)
            );
          });
      };

      this.afterChallengeCallback = checkEmailFunc;
    }
  }
}
