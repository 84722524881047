import Component from '@glimmer/component';
import EmberObject from '@ember/object';

export default class MwHomeDsnpFaq extends Component {
  questions = [
    EmberObject.create({
      title: `What is the “Social Web?”`,
      content: `The Social Web is a collection of apps made by developers that believe in empowering individuals to control their digital experience. Unlike the current web, where big platforms control our data and digital lives, the Social Web puts people first. It provides a transparent technology infrastructure that allows individuals to take control of their digital world and prioritize human connections.`,
      isOpened: false,
    }),
    EmberObject.create({
      title: `What is Amplica Access?`,
      content: `Amplica Access is a cutting-edge service that empowers users to create and control their Social Identity for the Social Web. Once created, users can take their Universal Handle, follows and connections with people to any application that is on the Social Web. With this novel system, users are in complete control of their Social Identity without other companies owning and hosting their identity.`,
      isOpened: false,
    }),
    EmberObject.create({
      title: `What is my "Social Identity"? `,
      content: `Your Social Identity is like an online profile that you use to access the The Social Web, and to connect with others on it. The first step to building your Social Identity is to claim your unique Universal Handle now.`,
      isOpened: false,
    }),
    EmberObject.create({
      title: `What is my "Universal Handle" and why does it have a suffix?`,
      content: `When you create your Social Identity, choose a name that's easy to read and consistent across all Social Web apps. This will help you find connections easily and make it easier for others to find you. We'll automatically add a suffix to your chosen name so you don't have to worry about making it unique. In most cases, the suffix won't be displayed.`,
      isOpened: false,
    }),
    EmberObject.create({
      title: `How do I give feedback?`,
      content: `You can submit feedback to support@mewe.com`,
      isOpened: false,
    }),
    EmberObject.create({
      title: `What does this migration mean for an existing MeWe member`,
      content: `Our integration with the Social Web is currently in beta for new users only. We are fast at work towards making the Social Web available for all MeWe users as soon as possible so we can usher in a new era for how MeWe users access the Internet. Once available to all users, the same experience and features you love and know will be availble on MeWe.`,
      isOpened: false,
    }),
    EmberObject.create({
      title: `Can I still log in using my password?`,
      content: `Once you have migrated, we've made it more convenient for you and no longer need a password to log into MeWe. If using email to login, simply click the link we email to login and for users who chose their mobile phone number, enter the pin we text you to log in.`,
      isOpened: false,
    }),
    EmberObject.create({
      title: `I migrated my account, but I did not receive an email or SMS code to login. What can I do?`,
      content: `For email, please check your spam and deleted folder in case it was accidentally deleted. If you cannot find the text message or email, please contact support@mewe.com `,
      isOpened: false,
    }),
    EmberObject.create({
      title: `Will migrating to the social web change what I like about MeWe?`,
      content: `No. MeWe is the same product you know and love. We are giving you more control over your data by implementing the Social Identity. The first step includes claiming your Universal Handle. Once you have claimed your Universal Handle, the core MeWe experience will remain the same.`,
      isOpened: false,
    }),
  ];
}
