import { A } from '@ember/array';
import Service from '@ember/service';
import { each } from 'lodash';
import MathUtils from 'mewe/utils/math-utils';
import FunctionalUtils from 'mewe/shared/functional-utils';
import { default as fuHelper, errorDialog } from 'mewe/utils/fileupload-utils';
import {
  fileUploadLimit,
  videoMaxWeightToUploadToServer,
  videoMaxWeightToUploadToServerBig,
  usersAllowedToUploadBiggerVideo,
  maxAttachmentsPerPost,
  photoMaxWeightToUploadToServer,
  prettyWeight as weight,
} from 'mewe/constants';
import { inject as service } from '@ember/service';

const VIDEO = 'video';
const IMAGE = 'image';
const DOCUMENT = 'document';

const limits = {
  image: photoMaxWeightToUploadToServer,
  video: videoMaxWeightToUploadToServer,
  document: fileUploadLimit,
};

const getFileType = (type) => {
  let t = type.split('/')[0];
  return (t == IMAGE && fuHelper.isImageFileTypeSupported(type)) || t == VIDEO ? t : DOCUMENT;
};

const arrayType = (arr) => {
  let types = arr.map((el) => el.type);

  let isVideo = types.indexOf(VIDEO) > -1;
  let isDocument = types.indexOf(DOCUMENT) > -1;

  if (isVideo && arr.length == 1) return VIDEO;
  if (isVideo && arr.length > 1) return DOCUMENT;
  if (isDocument) return DOCUMENT;
  return IMAGE;
};

export default Service.extend({
  account: service(),

  uploadingQueue: A(),

  postboxOpened: false,

  add(files) {
    let candidateFiles = A();

    each(files, (file) => {
      if (!file) return errorDialog(this, __('The file is invalid'), __('Photo upload error'));

      // https://stackoverflow.com/questions/49918319/jquery-file-upload-cannot-upload-file-in-safari-11
      if (file.name === 'NotFoundError')
        return errorDialog(this, __('The file path is invalid'), __('File upload error'));

      const fileType = getFileType(file.type);

      if (usersAllowedToUploadBiggerVideo.includes(this.account.activeUser.id))
        limits.video = videoMaxWeightToUploadToServerBig;

      if (file.size >= limits[fileType]) {
        errorDialog(
          this,
          __('File was too big {name} was {size}, limit is {limit}', {
            name: file.name,
            size: weight(file.size),
            limit: weight(limits[fileType]),
          }),
          __('File upload error')
        );
        return;
      }

      let uploadId = MathUtils.generateId();

      file.uploadId = uploadId;

      candidateFiles.push({ type: fileType, file: file, uploadId: uploadId });
    });

    if (!candidateFiles.length) return;

    const currentQueueLength = this.uploadingQueue.length;

    if (currentQueueLength + candidateFiles.length > maxAttachmentsPerPost) {
      // TODO make plural text in translation files after it will be approved
      FunctionalUtils.error(
        __('There can be maximum of {count} attachments per post.', { count: maxAttachmentsPerPost })
      );

      if (currentQueueLength < maxAttachmentsPerPost) {
        candidateFiles = candidateFiles.slice(0, maxAttachmentsPerPost - currentQueueLength); // upload only as many attachments to don't exceed limit
      } else {
        return false;
      }
    }

    if (this.canAdd(candidateFiles, this.uploadingQueue)) {
      this.uploadingQueue.pushObjects(candidateFiles);

      switch (arrayType(this.uploadingQueue)) {
        case IMAGE:
          this.addImages(candidateFiles.map((el) => el.file));
          break;
        case DOCUMENT:
          this.addDocuments(candidateFiles.map((el) => el.file));
          break;
        case VIDEO:
          this.addVideo(candidateFiles.map((el) => el.file));
          break;
      }
    }
  },

  canAdd(candidateFiles, uploadingQueue) {
    if (!uploadingQueue.length) {
      return true;
    } else {
      let uploadingQueueType = arrayType(uploadingQueue);

      if (uploadingQueueType == VIDEO) {
        errorDialog(this, __('Sorry, you cannot upload other files with video'), __('File upload error'));

        return false;
      }

      if (uploadingQueueType == IMAGE && arrayType(candidateFiles) == VIDEO) {
        errorDialog(this, __('Posting videos and photos together is not allowed.'), __('File upload error'));

        return false;
      }

      if ((uploadingQueueType == IMAGE || uploadingQueueType == VIDEO) && arrayType(candidateFiles) == DOCUMENT) {
        errorDialog(this, __('Posting documents and photos together is not allowed.'), __('File upload error'));

        return false;
      }

      return true;
    }
  },

  prefillImages(files) {
    each(files, (file) => {
      this.uploadingQueue.push({ type: 'image', file: file, uploadId: file.tempId });
    });
  },

  prefillVideo(fileId) {
    this.uploadingQueue.push({ type: 'video', uploadId: fileId });
  },

  prefillFiles(files) {
    each(files, (file) => {
      this.uploadingQueue.push({ type: 'document', file: file, uploadId: file.id });
    });
  },

  clear() {
    this.uploadingQueue.clear();
  },

  remove(uploadId) {
    let file = this.uploadingQueue.findBy('uploadId', uploadId);
    this.uploadingQueue.removeObject(file);
  },

  setControllers(postPhoto, postDocument, postVideo) {
    this.postPhoto = postPhoto;
    this.postDocument = postDocument;
    this.postVideo = postVideo;
  },

  addImages(files) {
    this.postPhoto.handlePhoto({
      files: files,
    });
  },

  addDocuments(files) {
    this.postDocument.handleFile({
      files: files,
    });
  },

  addVideo(files) {
    this.postVideo.handleVideo({
      files: files,
    });
  },
});
