import Session from 'mewe/shared/session';
import config from 'mewe/config';
import storage from 'mewe/shared/storage';

const unsubAndDecline = [
  '/account/notifications-unsubscribe/',
  '/mycontacts/invite-unsubscribe/',
  '/groups/invite-unsubscribe/',
  '/account/newsletter-unsubscribe/',
  '/contactsimport',
  '/googleauth',
];

// pages that are in app, but can be viewed without valid session
const appAllowed = [
  '/',
  '/i/',
  '/i-front/',
  '/e/',
  '/e-front/',
  '/p/',
  '/p-front/',
  '/signup',
  '/account/validate-email/',
  '/cloudsponge-proxy',
  '/share',
  '/welcome',
  ...unsubAndDecline,
  '/congratulations',
  '/confirm',
  '/myworld',
  '/chat',
  '/groups',
  '/events',
  '/mycloud',
  '/mycloud/',
  '/settings',
  '/profile/',
  '/post/',
  '/file/',
  '/group/',
  '/event/',
  '/videocall',
  '/purchase',
  '/store',
  '/lockout',
  '/verify',
];

const loginNextPages = [
  '/myworld',
  '/chat',
  '/groups',
  '/events',
  '/mycloud',
  '/mycloud/',
  '/settings',
  '/profile',
  '/post/',
  '/file/',
  '/event/',
];

const matchWithWindowLocation = function (arr) {
  const pathname = window.location.pathname;

  if (!pathname) return false;

  // /group/ expect params, should be compared to start of string
  return arr.find((el) => {
    if (el.substr(-1) === '/') return pathname.indexOf(el) === 0;

    return pathname === el;
  });
};

export const checkForRedirects = () => {
  // for unit testing
  if (config.testing) {
    const appPreloadingDummyExclude = [
      '/login',
      '/join/',
      '/join-front/',
      '/invite',
      '/i/',
      '/i-front/',
      '/e/',
      '/e-front/',
      '/p/',
      '/p-front/',
      '/signup',
      '/welcome',
      '/cloudsponge-proxy',
      '/validate-email',
      '/404',
      '/share',
      ...unsubAndDecline,
      '/congratulations',
      '/confirm',
      '/store',
      '/lockout',
      '/verify',
      '/paypal-success',
      '/paypal-failure',
      '/web3',
      '/web3/login',
    ];

    if (!matchWithWindowLocation(appPreloadingDummyExclude)) {
      dummy.forApp();
    }
  }

  // check first if user is jailed or unconfirmed, some views that are loaded
  // before this request is finished may attempt to log out, but as promises are queued
  // this callback will run first

  Session.isAuthenticated().then(({ isAuthenticated, isConfirmed }) => {
    if (!isAuthenticated) {
      let hasGroupInUrl = matchWithWindowLocation(['/group/']);
      let hasProfileInUrl = matchWithWindowLocation(['/profile/']);
      let hasEventInUrl = matchWithWindowLocation(['/event/']);
      let groupId = location.href.slice(location.href.indexOf('/group/') + 7);
      let eventId = location.href.slice(location.href.indexOf('/event/') + 7);
      let profileId = location.href.slice(location.href.indexOf('/profile/') + 9);

      // go to join page to check if public page can be opened for given group
      if (hasGroupInUrl && groupId.indexOf('/') === -1) {
        window.location = `/join-front/id=${groupId}`;
        return;
      } else if (hasEventInUrl && eventId.indexOf('/') === -1) {
        window.location = `/e-front/id=${eventId}`;
        return;
      } else if (hasProfileInUrl && profileId.indexOf('/') === -1) {
        window.location = `/i-front/id=${profileId}`;
        return;
      } else if (matchWithWindowLocation(appAllowed)) {
        // if view is already allowed for unathorized view, do not redirect;
        return;
      } else if (matchWithWindowLocation(loginNextPages)) {
        // inner app pages that should be opened after successful login (not included group which should redirect to grp public page)
        if (!matchWithWindowLocation(['/groups/invite-unsubscribe/'])) {
          // hack needed for routing unsubscribe link, it interferes with /groups path - SG-11602
          window.location = `/login?next=${location.pathname}${location.hash}`;
          return;
        }
      }
    }

    // redirect for new accounts
    if (
      !isConfirmed &&
      window.location.pathname != '/confirm' &&
      window.location.pathname != '/welcome' &&
      window.location.pathname != '/cloudsponge-proxy' &&
      !~window.location.pathname.indexOf('/contacts-import/') &&
      !~window.location.pathname.indexOf('/account/confirm/')
    ) {
      window.location.href = `/confirm`;
      return;
    }
  });

  if (~location.pathname.indexOf('/500') || matchWithWindowLocation(appAllowed)) {
    // when error being handled, we assume we cant get real session info because BE is not working
  } else {
    Session.logout().then(() => {
      window.location = `/login?next=${location.pathname}${location.hash}`;
    });
  }
};
