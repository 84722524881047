import { computed } from '@ember/object';
import Model from 'mewe/utils/store-utils/model/model.js';
import { attr } from 'mewe/utils/store-utils/model/attribute';
import { isMeWeTeam } from 'mewe/helpers/is-mewe-team';

const periodKeys = {
  month: 'monthly',
  year: 'yearly',
};

var model = Model.extend({
  // productIdBase should be the final result unless there are tiers to select or there is choice between subscription/onetime purchase
  productId: computed('productIdBase', 'selectedTierId', 'optionalSubState', 'selectedPeriod', function () {
    let productId = this.productIdBase;

    if (this.optionalSubState) {
      productId += `.${this.get('optionalSubState')}`;
    }
    // if there is optionalSubState then subscriptionPeriod should be skipped (case: donation)
    if (this.subscriptionPeriods && !this.optionalSubState) {
      productId += `.${periodKeys[this.get('selectedPeriod.name')]}`;
    }
    if (this.selectedTierId) {
      productId += `.${this.get('selectedTierId')}`;
    }

    return productId;
  }),

  itemId: attr('string'),
  productIdBase: attr('string'),
  tiers: attr('array'),
  selectedTierId: attr('string'),

  subscriptionPeriods: attr('array'),
  selectedPeriod: attr('object', {
    defaultValue: function () {
      return this.get('subscriptionPeriods.firstObject');
    },
  }),
  optionalSubState: attr('string'), // 'onetime' / 'monthly', present only for items that can optionaly be subscriptions (donation)
  isSubscription: computed('subscriptionPeriods.length', 'optionalSubState', function () {
    return this.get('subscriptionPeriods.length') && this.get('optionalSubState') !== 'onetime';
  }),

  category: attr('string'),
  name: attr('string'),
  class: attr('string'),
  source: attr('object'),
  isCancelled: attr('boolean'),
  packLogoSticker: attr('object'),
  iconSticker: attr('object'),
  previewUrl: attr('string'),
  iconEmoji: attr('string'),
  coverImage: attr('string'),
  previewEmojisFour: attr('array'),
  previewEmojisFive: attr('array'),
  previewStickers: attr('array'),
  previewStickersTwo: attr('array'),
  emojisList: attr('array'),
  stickers: attr('array'),
  isInCart: attr('boolean'),
  isFeatured: attr('boolean'),
  hiddenForWorld: attr('boolean'),
  hiddenForMewe: attr('boolean'),
  provider: attr('string'),
  grantedBy: attr('array'),
  isFree: attr('boolean'),
  expiresAt: attr('number'),
  isVisible: computed('hiddenForMewe', 'hiddenForWorld', function () {
    return !this.hiddenForWorld || (!this.hiddenForMewe && isMeWeTeam());
  }),
  isPurchased: computed('provider', 'isGranted', function () {
    return this.provider && !this.isGranted;
  }),
  canBuy: computed(
    'isPurchased',
    'isCancelled',
    'isGranted',
    'expiresAt',
    'optionalSubState',
    'isFree',
    'itemId',
    'provider',
    function () {
      // free items are really free and can't be purchased
      if (this.isFree) return false;
      // granted items are free
      if (this.isGranted) return false;
      // page subscription can be purchased multiple times
      if (this.itemId === 'page') return true;
      // 'onetime' purchases AKA consumables can be purchased multiple times
      if (this.optionalSubState === 'onetime') return true;
      // in case of paypal don't allow buying subscription until cancelled one expires
      // because there is no way to 'update' subscription and new one would be purchased and paid for
      if (this.isCancelled && this.provider === 'paypal' && this.expiresAt * 1000 > Date.now()) return false;

      // default case, user can buy item if it is not purchased already
      return !this.isPurchased || this.isCancelled;
    }
  ),

  packLogo: attr('string'),
  packLogoDark: attr('string'),
  packLogoWidth: attr('number'),
  hasPackImage: attr('boolean'),

  price: attr('number'),
  priceComputed: computed('price', 'selectedTierId', 'selectedPeriod', 'isSubscription', function () {
    if (this.selectedTierId) return this.tiers.findBy('tierId', this.selectedTierId).price;
    if (this.isSubscription && this.get('selectedPeriod.price')) return this.selectedPeriod.price;
    return this.price;
  }),
  priceRangeString: computed('price', 'tiers', 'selectedPeriod', 'isSubscription', function () {
    // edgecase: storage is subscription but there is no price per period but per tier
    if (this.isSubscription && this.get('selectedPeriod.price')) return this.get('selectedPeriod.price');
    if (this.tiers?.length) return `${this.get('tiers.firstObject.price')} - ${this.get('tiers.lastObject.price')}`;
    return this.price;
  }),

  subscriptionText: computed('isSubscription', 'selectedPeriod', function () {
    return this.get('isSubscription')
      ? __('1-{period} Subscription', { period: this.get('selectedPeriod.name').capitalize() })
      : '';
  }),

  description: attr('string'),

});

model.reopenClass({
  resourceName: 'store-item',
});

export default model;
