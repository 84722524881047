import Ember from 'ember';
import Route from '@ember/routing/route';
import { slashed, registerSimpleTemplate } from './utils';

export default class BaseRoute extends Route {
  constructor() {
    super(...arguments);

    if (window.location.hash?.length >= 2) {
      this.set('hash', window.location.hash);
    }
  }

  cleanRouteHash() {
    if (this.hash) {
      // clean hash in route
      this.set('hash', null);

      // clean hash in url
      if (window.location.hash) {
        history.pushState('', document.title, window.location.pathname + window.location.search);
      }
    }
  }

  routeComponent(_name) {
    let name = dashed(_name || this.routeName);

    if (Ember.TEMPLATES[slashed(this.routeName)]) return;

    if (name.indexOf('-') == -1) name += '-route';

    registerSimpleTemplate(this.routeName, name);
  }

  activate() {
    if (this.modelFor('app')) {
      const appRoute = Ember.getOwner(this).lookup('route:app');
      if (appRoute) appRoute.set('theme', this.theme);

      this.modelFor('app').setProperties({
        theme: this.theme,
        locationHash: window.location.hash,
      });
    }
  }
}
