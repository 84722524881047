/* eslint-disable lines-between-class-members */
import Service from '@ember/service';
import { setComponentTemplate } from '@ember/component';
import { getOwner } from '@ember/application';
import { tracked } from '@glimmer/tracking';
import { A } from '@ember/array';
import ScrollingUtils from 'mewe/utils/scrolling-utils';
export default class DynamicDialogsService extends Service {
  @tracked components = A();

  async openDialog(dialogName, args = {}) {
    const owner = getOwner(this);
    const factory = owner.factoryFor(`component:${dialogName}`);
    if (!factory) {
      let [template, module] = await Promise.all([
        import(`../dialogs/${dialogName}/template.hbs`),
        import(`../dialogs/${dialogName}`),
      ]);
      setComponentTemplate(template.default, module.default);
      owner.register(`component:${dialogName}`, module.default);
    }
    this.components.pushObject({
      componentName: dialogName,
      componentArgs: args,
    });

    ScrollingUtils().disableWindowScroll();
  }

  close(id) {
    const comp = this.components.find((c) => c.componentName === id);
    if (comp) {
      this.components.removeObject(comp);
      document.getElementById('mw-wormhole-dialog').removeChild(document.getElementById(`c-${id}`));

      ScrollingUtils().enableWindowScroll();
    }
  }

  closeAll() {
    const nodeList = document.querySelector('#mw-wormhole-dialog').childNodes;
    nodeList.forEach((node) => {
      document.getElementById('mw-wormhole-dialog').removeChild(node);
    });
    this.components = A();

    ScrollingUtils().enableWindowScroll();
  }
}
