import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import PublicPagesApi from 'mewe/api/public-pages-api-unauth';
import Verbose from 'mewe/utils/verbose';

const verbose = Verbose({ prefix: '[Login/config]', color: 'orange', enabled: true }).log;

export default class MwHomeLoginPageComponent extends Component {
  @tracked content = this.args.model?.content;
  @tracked useSmsV2Flow = false; // true / false
  @tracked configLoaded = false;

  constructor() {
    super(...arguments);

    this.storyblokBridge = new window.StoryblokBridge();

    this.storyblokBridge.on('input', (e) => {
      this.content = e?.story.content;
    });

    PublicPagesApi.getRegistrationInfo()
      .then((res) => {
        this.setSmsVersion(res.useSmsV2Flow);
      })
      .catch(() => {
        this.setSmsVersion(false); // fallback to mewe just in case
      })
      .finally(() => {
        this.configLoaded = true;

        verbose(`smsV2=${this.useSmsV2Flow}`);
      });
  }

  setSmsVersion(valueFromBe) {
    if (~window.location.hash.indexOf('smsV2=')) {
      const smsV2 = window.location.hash.slice('smsV2='.length + 1);
      this.useSmsV2Flow = smsV2 === 'true';
    }
    // only if there is no forced version from url, then use BE value
    else {
      this.useSmsV2Flow = valueFromBe || false; // fallback to v1 just in case
    }
  }
}
