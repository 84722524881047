import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { reads } from '@ember/object/computed';
import MathUtils from 'mewe/utils/math-utils';
import { inject as service } from '@ember/service';

import jstz from 'jstimezonedetect';
import Session from 'mewe/shared/session';
import PublicPagesApi from 'mewe/api/public-pages-api-unauth';
import FunctionalUtils from 'mewe/shared/functional-utils.js';
import { afterRegistration } from 'mewe/shared/registration';
import { trimAndLower, getWalletHost } from 'mewe/utils/miscellaneous-utils';
import { emailRegex, dsnpHandleRegex, getQueryParam } from 'mewe/shared/utils';
import config from 'mewe/config';
import Storage from 'mewe/shared/storage';

export default class MwHomeRegistrationFormComponent extends Component {
  @service dynamicDialogs;
  @service analytics;
  @service phoneInput;
  @service router;

  @reads('args.model') model;

  // captcha challenge
  challengeOrigin = 'registration';
  arkoseKey = config.arkoseKeyReg;
  hcaptchaSiteKey = config.hcaptcha.registration;
  hcaptchaElemClass = 'h-captcha_registration';
  @tracked challengeReady; // whether or not captcha challenge is loaded
  @tracked afterChallengeCallback; // callback function to captcha test should be set to this to trigger captcha

  // general varaibles not connected to any step
  @tracked stepNumber = 0;
  @tracked isEmailFlow = true; // email registration by default
  @tracked forceMeweMode;
  @tracked tmpPhoneId;

  // step-0 - identity/contact info
  @tracked fname = '';
  @tracked lname = '';
  @tracked email = '';
  @tracked phoneCode = '';
  @tracked phoneNumber = '';
  @tracked invalidEmail = true;
  @tracked invalidPhone = true;
  @tracked phoneTaken = false;
  @tracked emailTaken = false;
  @tracked isEmailDisabled = false; // disabled when reg form auto filled from invitation data
  @tracked isIdentitySubmitted; // was there any update in identity form since submitting it
  @tracked isCheckingIndentity; // is there pending request checking passed email/phone

  // step-1 - handle
  @tracked handle = '';

  // step-2 - dsnp iframe process
  @tracked dsnpParams; // params passed to DSNP request, have to be in JSON format and named 'jsonBody'
  @tracked iframeLoadInProgress; // is iframe loading, used to postpone showing iframe until it loads

  // step-3 - password (and phone code for SMS registration)
  @tracked password = '';
  @tracked smsCode = '';
  @tracked passwordError = true;
  @tracked smsCodeError = false;
  @tracked smsLimitError = false;
  @tracked isPasswordSubmitted; // was there any update in password form since submitting it
  @tracked phoneRequestInProgress; // is there pending request for sending SMS code
  @tracked isRegistrationInProgress; // is there pending request for registration

  constructor() {
    super(...arguments);
    this.phoneInput.load();

    // remove 'xTraceId' that could remain e.g. from previous loggin attempt
    Storage.remove(Storage.keys.xTraceId);

    this.challengeReadyCallbackBind = () => {
      this.challengeReady = true;

      // mergeId/invitationId comes from email invitation links (user invitation or private group invitation)
      // and should be sent in registration request to auto accept invitation and auto confirm account
      const mergeId = getQueryParam('mergeId');
      if (mergeId) {
        PublicPagesApi.inviteeData(mergeId).then((data) => {
          if (data.email) {
            this.preSetEmailData(data.email);
          }
          this.invitationId = mergeId;
          this.forceMeweMode = true;
        });
      } else {
        try {
          const invitationData = JSON.parse(Storage.get(Storage.keys.emailInvitationData));
          if (invitationData?.email) {
            this.preSetEmailData(invitationData.email);
          }
          this.invitationId = invitationData.invitationId;
          this.forceMeweMode = true;
        } catch (e) {}
      }
    };

    this.iframeMessageListenerBind = this.iframeMessageListener.bind(this);
  }

  preSetEmailData(email) {
    this.email = email;
    this.invalidEmail = false;
    this.isEmailDisabled = true;
  }

  get isDsnpFlow() {
    return this.args.authMode === 'dsnp_amplica' && !this.forceMeweMode;
  }

  get fnameValue() {
    return this.fname.trim();
  }

  get lnameValue() {
    return this.lname.trim();
  }

  get emailValue() {
    return trimAndLower(this.email || '');
  }

  get handleValue() {
    return trimAndLower(this.handle).replaceAll(' ', '');
  }

  get showEmailError() {
    return this.isIdentitySubmitted && (this.invalidEmail || this.emailTaken);
  }

  get isHandleValid() {
    return dsnpHandleRegex.test(this.handleValue);
  }

  get showPhoneCodeInput() {
    return !this.isEmailFlow && !!this.tmpPhoneId;
  }

  get passwordValue() {
    return (this.password || '').trim();
  }

  get showPasswordError() {
    return this.isPasswordSubmitted && this.passwordError;
  }

  @action
  passwordUpdated() {
    this.isPasswordSubmitted = false;
    this.passwordError = this.passwordValue.length < 8;
  }

  @action
  emailUpdated() {
    // reset submitted state on any change
    this.isIdentitySubmitted = false;
    // reset errors on any change
    this.emailTaken = false;

    const isValid = emailRegex.test(this.emailValue);
    this.invalidEmail = !isValid;
  }

  @action
  phoneUpdated(number, params) {
    // hack for testers to be able to use +89 as a country code
    if (number.length === 12 && number.slice(0, 3) === '+89') {
      params.selectedCountryData.dialCode = '89';
      params.isValidNumber = true;
    }

    // reset submitted state on any change
    this.isIdentitySubmitted = false;
    // reset API errors on any change
    this.phoneTaken = false;

    this.phoneCode = `+${params.selectedCountryData?.dialCode}`;
    this.phoneNumber = number.replace(this.phoneCode, '');
    this.invalidPhone = !params.isValidNumber;

    // reset tmpPhoneId when phone number changes,
    // user could request SMS for given number which sets tmpPhoneId and then change the number
    this.tmpPhoneId = null;
    this.smsCodeError = false;
    this.smsLimitError = false;
  }

  get showPhoneError() {
    return this.isIdentitySubmitted && (this.invalidPhone || this.phoneTaken);
  }

  get isIdentitySubmitDisabled() {
    const invalidInputs = this.hasIdentityFormErrors || !this.fnameValue.length || !this.lnameValue.length;
    return !this.args.configLoaded || !this.challengeReady || invalidInputs || this.isCheckingIndentity;
  }

  // are there any errors in form fields (no matter if displayed or not)
  get hasIdentityFormErrors() {
    if (this.isEmailFlow) {
      return this.invalidEmail || this.emailTaken || !this.emailValue.length;
    } else {
      return this.invalidPhone || this.phoneTaken || !this.phoneNumber.length;
    }
  }

  get isPasswordSubmitDisabled() {
    if (this.isRegistrationInProgress || this.phoneRequestInProgress) return true;

    const smsCodeError = this.showPhoneCodeInput ? this.smsCode.length < 4 : false;
    return this.passwordError || smsCodeError;
  }

  get dsnpRequestUrl() {
    const host = getWalletHost();
    const version = `version=${this.args.useSmsV2Flow ? '2' : '1'}`;

    return `${host}/signup/${this.isEmailFlow ? 'email' : 'sms_code'}/form?${version}`;
  }

  get showFooter() {
    return this.stepNumber === 0 || this.stepNumber === 1;
  }

  get showBackArrow() {
    return this.stepNumber === 1 || this.stepNumber === 2 || this.stepNumber === 3;
  }

  get analyticsContactInfo() {
    return { login_type: this.isEmailFlow ? 'email' : 'phone' };
  }

  // did-insert #dsnp-form callback
  @action
  dsnpFormReady() {
    // submit the form as soon as the form is rendered to minimise waiting time for user
    // so when he will go to DSNP iframe view then it might be already loaded and displayed
    document.querySelector('#dsnp-form').submit();

    this.loadIframe = new Promise((resolve) => {
      // wait until iframe is loaded to display already rendered view
      const iframe = document.getElementById('dsnp-iframe');
      iframe.addEventListener('load', () => {
        resolve();
      });
    });

    this.loadIframe.then(() => {
      this.iframeLoadInProgress = false;
      this.stepNumber = 2;
      this.focusHandleInput();
    });
  }

  // called on step 0 submit
  @action
  submitIdentity() {
    // display possible errors
    this.isIdentitySubmitted = true;

    if (this.hasIdentityFormErrors || this.isCheckingIndentity) {
      return false;
    }

    this.isCheckingIndentity = true;

    const identityCheckCallback = (res) => {
      // identity check should return `trc` which is an ID generated by the backend
      // and used to identify user in following requests in order to debugg issues
      this.traceId = res.trc;

      Storage.set(Storage.keys.xTraceId, res.trc);

      if (res.exists) {
        if (this.isEmailFlow) {
          this.emailTaken = true;
        } else {
          this.phoneTaken = true;
        }
      } else {
        this.stepNumber = 1;
      }
      this.isCheckingIndentity = false;
    };

    if (this.isDsnpFlow) {
      if (this.isEmailFlow) {
        PublicPagesApi.checkAccountByEmail(this.emailValue).then(identityCheckCallback);
      } else {
        PublicPagesApi.checkAccountByPhone(this.phoneCode, this.phoneNumber).then(identityCheckCallback);
      }

      this.analytics.sendEvent('web3ContactInfoSubmitted', this.analyticsContactInfo);
    } else {
      if (this.isEmailFlow) {
        PublicPagesApi.checkEmailAvailability(this.emailValue)
          .then((data) => {
            if (data?.errorCode) {
              this.invalidEmail = data.errorCode === 100;
              this.emailTaken = data.errorCode === 109;
            } else {
              this.stepNumber = 3; // password setting step
            }
          })
          .finally(() => {
            this.isCheckingIndentity = false;
          })
          .catch(() => {
            FunctionalUtils.showDefaultErrorMessage();
          });
      } else {
        this.isCheckingIndentity = false;
        this.stepNumber = 3; // password setting step
      }
    }
  }

  @action
  submitPassword() {
    // show possible errors after submitting
    this.isPasswordSubmitted = true;

    if (this.passwordError) {
      return;
    }

    if (this.isEmailFlow) {
      this.doRegister();
    } else {
      // phone already validated, proceed with registration
      if (this.tmpPhoneId) {
        this.doRegister();
      } else {
        // process with SMS code validation after completing captcha
        this.afterChallengeCallback = this.validatePhone.bind(this);
      }
    }
  }

  doRegister(dsnpSignupPayload) {
    this.isRegistrationInProgress = true;

    const registerFunc = (challengeToken, provider, onChallengeFailed) => {
      const params = {
        firstName: this.fnameValue,
        lastName: this.lnameValue,
        imOver16: true,
        agreeWithTOS: true,
        publicProfile: true,
        timeZone: jstz.determine().name(),
        session_token: challengeToken,
        challenge_provider: provider,
      };

      if (this.isDsnpFlow) {
        if (this.args.useSmsV2Flow) {
          params.sessionId = dsnpSignupPayload.sessionId;
        } else {
          params.dsnpSignup = dsnpSignupPayload;
        }

        params.validationCode = dsnpSignupPayload.token;
        params.password = MathUtils.generateId(); // password can't be empty, generating it for dsnp users

        // email flow for DSNP can't occur here because it goes through email link
        // to confirmation page, but keeping this condition with comment here for easier understanding
        if (!this.isEmailFlow) {
          params.tmpPhoneId = this.tmpPhoneId;
        }
      } else {
        params.password = this.passwordValue;

        if (this.isEmailFlow) {
          params.email = this.emailValue;
        } else {
          params.tmpPhoneId = this.tmpPhoneId;
          params.validationCode = this.smsCode;
        }
      }

      if (this.invitationId) {
        params.invitationId = this.invitationId;
      }

      PublicPagesApi.registerAccount(params, this.traceId)
        .then((data) => {
          data.user.email = params.email;
          data.user.tmpPhoneId = params.tmpPhoneId;

          afterRegistration(params, data.user);

          this.redirectAfterRegistration(data);
        })
        .catch((error) => {
          const resp = error.data;

          if (resp?.errorCode === 109) {
            this.emailTaken = true;
            this.stepBack();
          } else if (error.status === 400 && resp?.errorCode == 117) {
            onChallengeFailed();
          } else if (resp?.message === 'wrong validation code') {
            this.smsCodeError = true;
            this.focusCodeInput();
          } else if (resp?.limitReached) {
            this.smsLimitError = true;
          } else {
            FunctionalUtils.showDefaultErrorMessage();
          }
        })
        .finally(() => {
          this.isRegistrationInProgress = false;
        });
    };

    this.afterChallengeCallback = registerFunc;
  }

  redirectAfterRegistration(data) {
    // if user registered from invitation link (with `invitationId` param) then his account might be
    // auto-confirmed and he will be logged in automatically, so we check authentication status to know
    // if that was the case (registration response itself doens't indicate if user was auto-confirmed and we
    // can't rely on checking that `invitationId` was sent because it could be invalid)
    Session.isAuthenticated().then(({ isAuthenticated, isConfirmed }) => {
      if (isAuthenticated && isConfirmed) {
        if (this.isDsnpFlow) {
          window.setTimeout(() => {
            window.location = '/myworld#dsnp';
          }, 1000);
        } else if (ds.publicProfileRedirection?.publicId) {
          window.location = `/${ds.publicProfileRedirection.publicId}`;
        } else {
          this.router.transitionTo('welcome');
        }
      } else {
        Storage.set(Storage.keys.ftueUnconfirmedUser, JSON.stringify(data.user));
        this.router.transitionTo('welcome');
      }
    });
  }

  validatePhone(challengeToken, provider, onChallengeFailed) {
    const params = {
      phone: this.phoneNumber,
      countryCode: this.phoneCode,
    };

    if (challengeToken) params.session_token = challengeToken;
    if (provider) params.challenge_provider = provider;

    this.smsCodeError = false;
    this.phoneRequestInProgress = true;

    return PublicPagesApi.validatePhone(params, this.traceId)
      .then((data) => {
        // for automated tests, on production there is no code
        if (data.code) {
          window.setTimeout(() => {
            const phoneEl = document.getElementById('reg-phone-v-code');
            if (phoneEl) {
              phoneEl.value = data.code;
              this.smsCode = data.code;
            }
          }, 500);
        }

        if (data.phoneNumber) {
          this.tmpPhoneId = data.tmpPhoneId;
        } else if (data.limitReached) {
          this.smsLimitError = true;
        }
      })
      .catch((error) => {
        if (error.status === 400) {
          if (error.data?.errorCode === 117) {
            onChallengeFailed();
          } else if (error.data.errorCode === 115) {
            this.invalidPhone = true;
            this.stepBack();
          } else if (error.data.alreadyTaken) {
            this.phoneTaken = true;
            this.stepBack();
          }
        } else {
          FunctionalUtils.showDefaultErrorMessage();
        }
      })
      .finally(() => {
        this.focusCodeInput();
        this.phoneRequestInProgress = false;
      });
  }

  focusCodeInput() {
    const regPhoneEl = document.getElementById('reg-phone-v-code');
    if (regPhoneEl) {
      regPhoneEl.focus();
      regPhoneEl.select();
    }
  }

  @action toggleEmailOrPhone() {
    this.email = '';
    this.phoneNumber = '';
    this.isIdentitySubmitted = false;
    this.isEmailFlow = !this.isEmailFlow;

    // track click when switched to phone registration
    if (this.isDsnpFlow && !this.isEmailFlow) {
      this.analytics.sendEvent('buttonClicked', 'Web3 Signup Use Phone');
    }
  }

  @action
  submitHandle() {
    if (!this.isHandleValid) {
      return;
    }

    this.iframeLoadInProgress = true;

    const failback = (res) => {
      this.iframeLoadInProgress = false;

      const err = res.data;

      // 122 - handle is taken, 120 - handle is forbidden
      if (err?.errorCode === 122 || err?.errorCode === 120) {
        this.dynamicDialogs.openDialog('simple-dialog-new', {
          message: __('Please use a different handle. This one is forbidden or taken.'),
          confirmBtnText: __('Ok'),
        });
        return;
      }
    };

    const callback = (res) => {
      if (res?.errorCode) {
        this.invalidEmail = res.errorCode === 100;
        this.emailTaken = res.errorCode === 109;
        this.iframeLoadInProgress = false;
        this.stepNumber = 0;
        return;
      }

      // check if user is still in step 1 (he could go back to step 0 during request)
      if (this.stepNumber === 1 && res.payload) {
        this.dsnpParams = JSON.stringify(res.payload);

        if (this.isEmailFlow) {
          Storage.set(
            Storage.keys.dsnpAuthData,
            JSON.stringify({
              fname: this.fnameValue,
              lname: this.lnameValue,
              email: this.emailValue,
              handle: this.handleValue,
              invitationId: this.invitationId,
            })
          );
        } else {
          Storage.set(
            Storage.keys.dsnpAuthData,
            JSON.stringify({
              phone: this.phoneNumber,
              handle: this.handleValue,
            })
          );

          // remove possible previous listener if user moved to previous step and then back to this step
          window.removeEventListener('message', this.iframeMessageListenerBind);
          window.addEventListener('message', this.iframeMessageListenerBind);
        }

        // send this event only when user actually is moved to next step by setting dsnpParams
        this.analytics.sendEvent('web3HandleSubmitted');
      } else {
        this.iframeLoadInProgress = false;
      }
    };

    if (this.isEmailFlow) {
      PublicPagesApi.checkHandleWithEmail(
        {
          handle: this.handleValue,
          email: this.emailValue,
          fname: this.fnameValue,
          lname: this.lnameValue,
        },
        this.traceId
      )
        .then(callback)
        .catch(failback);
    } else {
      PublicPagesApi.checkHandleWithPhone(
        {
          handle: this.handleValue,
          countryCode: this.phoneCode,
          phone: this.phoneNumber,
        },
        this.traceId
      )
        .then(callback)
        .catch(failback);
    }
  }

  focusHandleInput() {
    document.getElementById('handle-input').focus();
  }

  iframeMessageListener(e) {
    if (e.origin !== getWalletHost()) {
      return;
    }

    if (e.data?.type === 'startSmsProcess') {
      this.sessionId = e.data.payload.sessionId;

      const smsRequestFunc = (challengeToken, provider, onChallengeFailed) => {
        PublicPagesApi.sendDsnpRegistrationSms(
          {
            countryCode: this.phoneCode,
            phone: this.phoneNumber,
            walletSessionId: this.sessionId,
            session_token: challengeToken,
            challenge_provider: provider,
          },
          this.traceId
        )
          .then((data) => {
            this.tmpPhoneId = data.tmpPhoneId;
          })
          .catch((err) => {
            if (err.response?.status === 400 && err.response?.data?.errorCode == 117) {
              onChallengeFailed();
            }
          });
      };

      this.afterChallengeCallback = smsRequestFunc;
    }

    // for SMS resistration only
    if (this.args.useSmsV2Flow) {
      if (e.data?.type === 'smsPayloadV2') {
        this.doRegister(e.data.payload);
      }
    } else {
      if (e.data?.type === 'signupPayload') {
        this.doRegister(e.data.payload.response);
      }
    }

    if (e.data?.type === 'error') {
      this.analytics.sendEvent('custodialWalletFailed', {
        error_id: e.data.payload.id,
        description: e.data.payload.description,
        stack_trace: e.data.payload.stackTrace,
        trace_id: this.isEmailFlow ? 'signup/email' : 'signup/sms_code',
        session_id: this.sessionId ? this.sessionId : null,
      });
    }
  }

  @action
  stepBack() {
    if (this.stepNumber === 1) {
      this.stepNumber = 0;
      this.handle = '';
      this.iframeLoadInProgress = false;
    } else if (this.stepNumber === 2) {
      this.stepNumber = 1;
      this.dsnpParams = null;
      // event send when user moved back from AA iframe
      this.analytics.sendEvent('web3ToSCancelled', this.analyticsContactInfo);
    } else if (this.stepNumber === 3) {
      // from password step we need to go back to identity step 0
      this.stepNumber = 0;
    }
  }

  @action
  resendSMSCode() {
    this.afterChallengeCallback = this.validatePhone.bind(this);
  }
}
