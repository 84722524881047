import { assert } from '@ember/debug';
import ApiUtil from './api';
import { FeedTypes } from 'mewe/constants';

class Api extends ApiUtil {
  buckets({ ref = null, limit = 10 } = {}) {
    return this.get('/pages/buckets', {
      data: {
        ref,
        limit,
      },
    });
  }

  fetchCategoriesAll() {
    return this.get('/pages/categories');
  }

  publicLink(id) {
    return this.get(`/public/${id}`);
  }

  categoriesForBucket({ id = null, ref = null, limit = 100, nonEmpty = null } = {}) {
    assert('id is missing', id);

    return this.get(`/pages/bucket/${id}/categories`, {
      data: { ref, limit, nonEmpty },
    });
  }

  pagesForCategory({ id = null, limit = 20, nextPage = null } = {}) {
    assert('id is missing', id);

    const params = { limit, nextPage };

    return this.getNextPageOr(`/pages/category/${id}/pages`, params);
  }

  pagesByIds(ids) {
    return this.get(`/pages?pageIds=${ids}`);
  }

  createPage({
    categoryId,
    name,
    street,
    zipCode,
    cityAndState,
    country,
    profileImageId,
    coverImageId,
    website,
    purchase,
    publicLinkId,
  } = {}) {
    assert('categoryId is missing', categoryId);
    assert('name is missing', name);

    return this.post(`/pages/category/${categoryId}/page`, {
      data: JSON.stringify({
        name,
        street,
        zipCode,
        cityAndState,
        country,
        profileImageId,
        coverImageId,
        website,
        purchase,
        publicLinkId,
      }),
    });
  }

  featuredAll({ limit = 20, nextPage = null } = {}) {
    const params = { limit, nextPage };

    return this.getNextPageOr(`/pages/featured`, params);
  }

  trendingForBucket({ id = null } = {}) {
    assert('id is missing', id);
    return this.get(`/pages/bucket/${id}/pages/trending`);
  }

  trendingForCategory({ id = null } = {}) {
    assert('id is missing', id);
    return this.get(`/pages/category/${id}/pages/trending`);
  }

  page({ id = null } = {}) {
    assert('id is missing', id);
    return this.get(`/pages/page/${id}`);
  }

  pageByUrlId({ urlId = null } = {}) {
    assert('id is missing', urlId);
    return this.get(`/pages/page?publicUrlId=${urlId}`);
  }

  editPage(pageId, params) {
    assert('id is missing', pageId);
    return this.put(`/pages/page/${pageId}/edit`, {
      data: JSON.stringify(params),
    });
  }

  publish({ id = null } = {}) {
    assert('id is missing', id);
    return this.post(`/pages/page/${id}/publish`);
  }

  unpublish({ id = null } = {}) {
    assert('id is missing', id);
    return this.del(`/pages/page/${id}/publish`);
  }

  followed(params) {
    return this.getNextPageOr('/pages/followed', params);
  }

  fetchFeed(pageId, params) {
    assert('pages-api: You need to pass pageId.', pageId);

    if (pageId === FeedTypes.PAGES) {
      return this.getNextPageOr(`/api/v2/pages/postsfeed`, params);
    } else {
      return this.getNextPageOr(`/pages/page/${pageId}/postsfeed`, params);
    }
  }

  fetchScheduledFeed(pageId, params) {
    assert('pages-api: You need to pass pageId.', pageId);

    return this.getNextPageOr(`/api/v2/pages/page/${pageId}/scheduled/postsfeed`, params);
  }

  fetchAnalyticsFeed(pageId, params) {
    assert('pages-api: You need to pass pageId.', pageId);

    return this.getNextPageOr(`/api/v2/pages/page/${pageId}/statistics/posts/postsfeed`, params);
  }

  // returns all contacts, with page followers being marked with .isFollower = true
  getContactsInPage(pageId, params) {
    assert('pageId is missing', pageId);
    return this.getNextPageOr(`/pages/page/${pageId}/contacts`, params);
  }

  // returns only contacts who also follow the page
  getFollowingContacts(pageId, params) {
    assert('pageId is missing', pageId);
    return this.getNextPageOr(`/pages/page/${pageId}/followers/contacts`, params);
  }

  inviteUsers(pageId, params) {
    // backend supports inviting only users now, sms & email may be added later
    if (params && pageId && params.userIds && params.userIds.length) {
      return this.post(`/pages/page/${pageId}/invite`, {
        data: JSON.stringify(params),
      });
    }
  }

  invitations(params) {
    return this.getNextPageOr('/pages/invitations', params);
  }

  rejectInvitation(pageId) {
    assert('pageId is missing', pageId);
    return this.del(`/pages/page/${pageId}/invitation`);
  }

  toggleFollow(pageId, follow) {
    assert('pageId is missing', pageId);
    return follow
      ? this.post(`/pages/page/${pageId}/follow?followStories=true`)
      : this.del(`/pages/page/${pageId}/follow`);
  }

  toggleFollowStories(pageId, follow) {
    assert('pageId is missing', pageId);
    return this.put(`/pages/page/${pageId}/follow/settings`, {
      data: JSON.stringify(follow),
    });
  }

  visited(id) {
    return this.put(`/pages/page/${id}/visited`);
  }

  notifications({ id = null, ref = null, limit = 10 } = {}) {
    assert('id is missing', id);
    return this.get(`/pages/page/${id}/notifications`, {
      data: { ref, limit },
    });
  }

  notificationMarkVisited({ id = null, notificationId = null } = {}) {
    assert('id is missing', id);
    assert('notificationId is missing', notificationId);
    return this.post(`/pages/page/${id}/notifications/${notificationId}/markVisited`);
  }

  notificationMarkVisitedAll({ id = null } = {}) {
    assert('id is missing', id);
    return this.post(`/pages/page/${id}/notifications/markVisited`);
  }

  notificationMarkSeen({ id = null, notificationId = null } = {}) {
    assert('id is missing', id);
    assert('notificationId is missing', notificationId);
    return this.post(`/pages/page/${id}/notifications/markSeen`, {
      data: JSON.stringify({ upTo: notificationId }),
    });
  }

  unseenCount({ id = null } = {}) {
    assert('id is missing', id);
    return this.get(`/pages/page/${id}/notifications/unseenCount`);
  }

  getAdmins(id) {
    return this.get(`/pages/page/${id}/admins`);
  }

  addAdmin(id, userId) {
    return this.post(`/pages/page/${id}/admin/${userId}`);
  }

  removeAdmin(id, userId) {
    return this.del(`/pages/page/${id}/admin/${userId}`);
  }

  setNotificationsInterval(id, params) {
    return this.post(`/pages/page/${id}/notifications/settings`, {
      data: JSON.stringify(params),
    });
  }

  getNotificationsInterval(id) {
    return this.get(`/pages/page/${id}/notifications/settings`);
  }

  // possible params: limit, with-admins, str (search string), ref (used in nextPage link)
  followers(pageId, params) {
    return this.getNextPageOr(`/pages/page/${pageId}/followers`, params);
  }

  banned({ pageId, ref, limit = 20 }) {
    return this.get(`/pages/page/${pageId}/banned`, {
      data: { ref, limit },
    });
  }

  ban({ pageId, userId }) {
    return this.post(`/pages/page/${pageId}/user/${userId}/ban`);
  }

  unban({ pageId, userId }) {
    return this.del(`/pages/page/${pageId}/user/${userId}/ban`);
  }

  removeEmojiFromPost({ pageId, userId, postItemId, emojis }) {
    return this.del(`/pages/page/${pageId}/post/${postItemId}/emojis/user/${userId}?emojis=${emojis}`);
  }

  removeEmojiFromComment({ userId, commentId, emojis }) {
    return this.del(`/comments/${commentId}/emojis/user/${userId}?emojis=${emojis}`);
  }

  administrated({ limit } = {}) {
    return this.get(`/pages/administrated`, {
      data: { limit },
    });
  }

  transferPageOwnership(pageId, params) {
    return this.post(`/pages/page/${pageId}/ownership/transfer`, {
      data: JSON.stringify(params),
    });
  }

  deletePage(pageId, password) {
    const params = {};
    // web3 users don't require password
    if (password?.length) {
      params.password = password;
    }
    return this.del(`/pages/page/${pageId}`, {
      data: JSON.stringify(params),
    });
  }

  buySubscription(pageId, params) {
    return this.put(`/api/v2/pages/page/${pageId}/subscribe`, {
      data: JSON.stringify(params),
    });
  }

  followersCountries(pageId) {
    return this.get(`/pages/page/${pageId}/statistics/followers/countries`);
  }

  followersIntervals(pageId, params) {
    return this.get(`/pages/page/${pageId}/statistics/followers/intervals`, {
      data: params,
    });
  }

  analyticsOverview(pageId, params) {
    return this.get(`/pages/page/${pageId}/statistics/overview`, {
      data: params,
    });
  }

  getSettings(pageId) {
    return this.get(`/api/v2/pages/page/${pageId}/settings`);
  }

  saveSettings(pageId, params) {
    return this.post(`/api/v2/pages/page/${pageId}/settings`, {
      data: JSON.stringify(params),
    });
  }

  getModerationCommentsCount(pageId, moderationLevel) {
    return this.get(`/api/v2/pages/page/${pageId}/settings/moderation/${moderationLevel}/comments/count`);
  }
}

export default new Api();
