import ApiUtil from './api';

class Api extends ApiUtil {
  productsList() {
    return this.get('/store/desktop/products');
  }

  purchased() {
    return this.get('/store/items/purchased');
  }

  getSubscriptions() {
    return this.get('/store/subscriptions');
  }

  getPayments() {
    return this.get('/store/purchases');
  }

  unsubscribe(params) {
    // transactionId present only when cancelling paypal subscriptions
    if (params.transactionId) {
      return this.post('/store/paypal/subscription/cancel', {
        data: JSON.stringify(params),
      });
    } else {
      return this.post('/store/desktop/unsubscribe', {
        data: JSON.stringify(params),
      });
    }
  }

  // params: productIds (req), entityId (opt; in case of creating page subscription)
  openStripeTransaction(params) {
    return this.post('/store/desktop/transaction/open', {
      data: JSON.stringify(params),
    });
  }

  // params: transactionId (req), failureReason (opt)
  transactionFail(params) {
    return this.post('/store/desktop/transaction/failed', {
      data: JSON.stringify(params),
    });
  }

  openPaypalTransaction(params) {
    return this.post('/store/paypal/transaction/open', {
      data: JSON.stringify(params),
    });
  }

  purchasePaypalSubscription(params) {
    return this.post('/store/paypal/subscription/purchase', {
      data: JSON.stringify(params),
    });
  }

  createPaypalOrder(params) {
    return this.post('/store/paypal/order/create', {
      data: JSON.stringify(params),
    });
  }

  checkTrials() {
    return this.get('/store/usedtrials');
  }

  updatePaypalFailTrans(params) {
    return this.post('/store/paypal/transaction/failed', {
      data: JSON.stringify(params),
    });
  }
}

export default new Api();
