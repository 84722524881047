import Component from '@glimmer/component';
import GroupApi from 'mewe/api/group-api';
import Session from 'mewe/shared/session';
import { inject as service } from '@ember/service';
import { isMobile } from 'mewe/shared/utils';
import storage from 'mewe/shared/storage';
import tokenManager from 'mewe/shared/token-manager';
import fetchGroups from 'mewe/fetchers/fetch-groups';
import GroupStore from 'mewe/stores/group-store';

export default class ConfirmGroupInvite extends Component {
  isMobile = isMobile();

  @service router;

  constructor() {
    super(...arguments);
    this.processInvitation();
  }

  processInvitation() {
    if (!this.args.groupId || !this.args.invitationId) {
      window.location = '/';
      return;
    }

    GroupApi.confirmGroupEmailInvitation(this.args.invitationId, true)
      .then((data) => {
        this.groupInvitationConfirmation(data);
      })
      .catch((err) => {
        Session.isAuthenticated().then(({ isAuthenticated }) => {
          if (isAuthenticated) {
            // is another user is logged in we let him choose to logout or continue
            window.location = `/401?next=${document.location.pathname}${document.location.hash}`;
          } else {
            // if noone is logged in we let user to log in and get back to confirmation afterwards
            window.location = `/login?next=${document.location.pathname}${document.location.hash}`;
          }
        });
      });
  }

  // https://github.com/sgrouples/wiki/blob/master/api/ConfirmInvitations.md
  // https://github.com/sgrouples/wiki/issues/92
  // now we only log user in and redirect to group (because there can be required questions) and don't auto merge invitation
  groupInvitationConfirmation(data) {
    // if user is already logged in, we just need to redirect him
    if (data.confirmed) {
      if (this.isMobile) {
        this.getGroupAndShowMobileLanding();
      } else {
        this.redirectToGroup();
      }
    } else {
      this.processGroupConfirmation(data);
    }
  }

  redirectToGroup() {
    window.location = `/group/${this.args.groupId}`;
  }

  getGroupAndShowMobileLanding() {
    if (this.args.groupId) {
      fetchGroups();

      GroupStore.getState()
        .deferred.promise.then(() => {
          const group = GroupStore.getGroupIfMember(this.args.groupId);

          if (group) {
            if (group.isPublicApply) {
              window.location = '/congratulations/application/' + group.publicUrlId;
            } else if (group.publicUrlId) {
              window.location = '/congratulations/public/' + group.publicUrlId;
            } else {
              window.location = '/congratulations/private/' + encodeURIComponent(group.name);
            }
          } else {
            this.redirectToGroup();
          }
        })
        .catch(() => {
          this.redirectToGroup();
        });
    }
  }

  processGroupConfirmation(data) {
    if (data.needRegister) {
      storage.set(
        storage.keys.emailInvitationData,
        JSON.stringify({
          invitationId: this.args.invitationId,
          email: data.email,
          groupId: this.args.groupId,
          groupPublicId: data?.groupPublicUrlId,
        })
      );

      if (data.groupPublicUrlId) {
        // move to group public page only if public group
        window.location = `/join/${data.groupPublicUrlId}`;
      } else if (this.isMobile) {
        window.location = `/signup`;
      } else {
        window.location = `/register`; // move to homepage registration if private group
      }
    } else {
      this.logInAndRedirectAfterConfirmation(data);
    }
  }

  logInAndRedirectAfterConfirmation(data) {
    Session.isAuthenticated().then(({ isAuthenticated }) => {
      if (isAuthenticated) {
        tokenManager.set(data);

        if (data.confirmInvite) {
          if (this.isMobile) {
            this.getGroupAndShowMobileLanding();
          } else {
            this.redirectToGroup();
          }
        } else {
          window.location = `/401?next=${document.location.pathname}${document.location.hash}`;
        }
      } else {
        window.location = `/login?next=${document.location.pathname}${document.location.hash}`;
      }
    });
  }
}
