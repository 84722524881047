/* eslint-disable lines-between-class-members */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import Session from 'mewe/shared/session';

export default class MwContentPage extends Component {
  @tracked content = this.args.content;
  @tracked isMobileSocialWeb;
  @tracked isLoggedIn = false;
  @tracked isAuthFetched = false;

  constructor() {
    super(...arguments);
    Session.isAuthenticated().then(({ isAuthenticated }) => {
      this.isAuthFetched = true;
      this.isLoggedIn = isAuthenticated;

      this.adjustContentOnClient();

      this.storyblokBridge = new window.StoryblokBridge();

      this.storyblokBridge.on(['change', 'input'], (e) => {
        this.content = e?.story.content;
        this.adjustContentOnClient();
      });
    });
  }

  adjustContentOnClient() {
    // https://sgrouples.atlassian.net/browse/UL-433
    const isMobileToS = ~window.location.href.indexOf('/terms?mobileApp=true');
    const isMobilePrivacy = ~window.location.href.indexOf('/privacy?mobileApp=true');

    // https://sgrouples.atlassian.net/browse/UL-432
    const isMobileSocialWeb = ~window.location.href.indexOf('/socialweb#info');

    if (isMobileToS || isMobilePrivacy || isMobileSocialWeb) {
      this.content.hideLogin = true;
      this.content.hideRegistration = true;
    }

    this.isMobileSocialWeb = isMobileSocialWeb;
  }
}
