import Mentions from 'mewe/utils/mentions-utils';

const template = (prefix, baseUrl, id, name) => {
  return `<span class="mention text-editor_embed color-app" data-text="@${name}" data-id="${id}"><span contenteditable="false">@${name}</span></span>`;
};

export default {
  toDisplay(text, options) {
    return Mentions.extractMentions(text, options);
  },

  toEdit(text) {
    return Mentions.extractMentions(text, {}, template);
  },

  // textEdit => textServer
  // TODO
  toServer(text) {
    return text;
  },
};
