import twitterText from 'twitter-text';
import { gifUrlRegExp } from 'mewe/utils/gif-utils';
import { each } from 'lodash';

export default {
  toDisplay(text, options) {
    if (options && options.textPartsHighlighted && options.textPartsHighlighted.length) {
      // urls contain the link twice, once in href property and second time in anchor tag text
      // only the anchor tag text should be highlighted
      let urls = twitterText.extractUrls(text).filter((url) => url.indexOf(window.location.origin) === -1); // SG-30421 - edge case when search for "emoji" keyword was found in link for background for emoji

      each(options.textPartsHighlighted, (textToHighlight) => {
        let startingIndex = 0;
        let urlsWithHlText;
        let firstUrlWithHlText;

        if (urls.length) {
          urlsWithHlText = urls.filter((url) => url.indexOf(textToHighlight.text) > -1);

          // if url was found in url extracted form text then set startingIndex to search for second occurence of link which should be anchor text
          if (urlsWithHlText.length) {
            if (urlsWithHlText.length > 2) return;
            else firstUrlWithHlText = urlsWithHlText[0];

            const highlightLength = textToHighlight.text.length;
            const highlightIndex = text.indexOf(textToHighlight.text);
            startingIndex = highlightLength + highlightIndex;
          }
        }

        // if firstUrlWithHlText is a gif link or youtube video preview image then don't highlight anything as it's image
        if (
          !firstUrlWithHlText ||
          (!firstUrlWithHlText.match(gifUrlRegExp) && firstUrlWithHlText.indexOf('.youtube.com') === -1)
        ) {
          // .youtube.com for both www.- and img.- youtube

          let index = this.findIndex(text, textToHighlight.text, startingIndex);

          if (index > -1)
            text =
              text.slice(0, index) +
              '<span class="word-highlight">' +
              text.slice(index, index + textToHighlight.text.length) +
              '</span>' +
              text.slice(index + textToHighlight.text.length);
        }
      });
    }

    return text;
  },

  toEdit(text) {
    return text;
  },

  toServer(text) {
    return text;
  },

  findIndex(text, phrase, startingIndex) {
    if (text.indexOf(phrase) === -1) return -1;

    // match is preceeded by '<span class="word-highlight">' => phrase already highlighted and should not be again
    const forbiddenPrefixes = ['<span class="word-highlight">'];
    // match is inside given text => phrase is e.g. inside some class attribute and should not be highlighted there
    // '<img\s.*?\sclass="emoji sticker">' => stickers inside text use img tag
    const forbiddenTexts = [
      'class="ht post_text_hashtag color-app"',
      'span class="word-highlight"',
      'span class="emoji"', // search for emoji word in text containing also emoji image
      'rel="nofollow noopener noreferrer"',
      '<span\\s.*?></span>', // empty span represents here an emoji inside text which is span element with emoji sprite in background
      "<a class='mention color-app'\\s.*?\\sdata-userid", // same as above
      '<img\\s.*?\\sclass="emoji sticker">',
      'href="mailto:.*?" target="',
      'dir="auto"',
      '</span>',
    ];

    let index = text.indexOf(phrase, startingIndex);

    // checking if found phrase is preceeded by any of forbiddenPrefixes that we don't allow
    const hasIllegalPrefix = () => {
      return forbiddenPrefixes.some((p) => {
        if (index > p.length) {
          if (text.substring(index - p.length, index) === p) return true;
        }
      });
    };

    const isInsideForbiddenText = () => {
      return forbiddenTexts.some((t) => {
        let regex = new RegExp(t, 'gi'),
          result,
          indices = new Array();
        while ((result = regex.exec(text))) {
          indices.push(result.index);
          t = result[0]; // forbiddenText string can be a regex so replace t with matched result because it has dynamic length that is used later
        }

        return indices.find((i) => {
          return index >= i && index <= i + t.length;
        });
      });
    };

    while (index > -1 && (hasIllegalPrefix() || isInsideForbiddenText())) {
      index = text.indexOf(phrase, index + 1);
    }

    return index > -1 && !hasIllegalPrefix() && !isInsideForbiddenText() ? index : -1;
  },
};
