import { tryInvoke } from '@ember/utils';
import Evented from '@ember/object/evented';
import Mixin from '@ember/object/mixin';
import Ember from 'ember';
import { next, run } from '@ember/runloop';
var noop = function () {
  return this;
};

var State = Mixin.create(Evented, {
  isNew: true,
  isLoaded: false,
  isDirty: false,
  isSaving: false,
  isError: false,
  errors: null,
  didCreate: noop,
  didUpdate: noop,
  didLoad: noop,
  didDelete: noop,
  becameError: noop,

  onSaved: function (wasNew) {
    this.setProperties({
      isDirty: false,
      isSaving: false,
      isLoaded: true,
      isError: false,
      errors: null,
    });
    this._triggerEvent(wasNew ? 'didCreate' : 'didUpdate', this);
    this._triggerEvent('didLoad', this);
  },

  onDeleted: function () {
    this._triggerEvent('didDelete', this);
    next(this, function () {
      this.destroy();
    });
  },

  onLoaded: function () {
    this.setProperties({
      isDirty: false,
      isSaving: false,
      isLoaded: true,
      isError: false,
      errors: null,
    });
    this._triggerEvent('didLoad', this);
  },

  onError: function (errors) {
    this.setProperties({
      isSaving: false,
      isError: true,
      errors: errors,
    });
    this._triggerEvent('becameError', errors);
  },

  clearErrors: function () {
    this.setProperties({
      isError: false,
      errors: null,
    });
    return this;
  },

  copyState: function (clone) {
    var mixins = State.mixins;
    var props = mixins[mixins.length - 1].properties,
      p;

    Ember.beginPropertyChanges(clone);
    for (p in props) {
      if (props.hasOwnProperty(p) && typeof props[p] !== 'function') {
        clone.set(p, this.get(p));
      }
    }
    Ember.endPropertyChanges(clone);
    return clone;
  },

  _isReady: false,

  _triggerEvent: function (event, data) {
    run(this, function () {
      tryInvoke(this, event, [data]);
      this.trigger(event, data);
    });
  },
});

export default State;
