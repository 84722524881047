
import { importSync as i, macroCondition, getGlobalConfig } from '@embroider/macros';
let w = window;
let d = w.define;


d("mewe/abilities/actor", function(){ return i("../abilities/actor.js");});
d("mewe/app", function(){ return i("../app.js");});
d("mewe/check-for-redirects", function(){ return i("../check-for-redirects.js");});
d("mewe/component-managers/glimmer", function(){ return i("../component-managers/glimmer.js");});
d("mewe/config", function(){ return i("../config.js");});
d("mewe/config/environment", function(){ return i("../config/environment.js");});
d("mewe/constants", function(){ return i("../constants.js");});
d("mewe/dispatcher", function(){ return i("../dispatcher.js");});
d("mewe/initializers/app-version", function(){ return i("../initializers/app-version.js");});
d("mewe/initializers/component-styles", function(){ return i("../initializers/component-styles.js");});
d("mewe/initializers/container-debug-adapter", function(){ return i("../initializers/container-debug-adapter.js");});
d("mewe/initializers/export-application-global", function(){ return i("../initializers/export-application-global.js");});
d("mewe/initializers/globals-initializer", function(){ return i("../initializers/globals-initializer.js");});
d("mewe/initializers/main", function(){ return i("../initializers/main.js");});
d("mewe/initializers/setup-ember-can", function(){ return i("../initializers/setup-ember-can.js");});
d("mewe/initializers/translation-initializer", function(){ return i("../initializers/translation-initializer.js");});
d("mewe/initializers/viewport-config", function(){ return i("../initializers/viewport-config.js");});
d("mewe/instance-initializers/phone-input", function(){ return i("../instance-initializers/phone-input.js");});
d("mewe/instance-initializers/route-styles", function(){ return i("../instance-initializers/route-styles.js");});
d("mewe/instance-initializers/sentry-performance", function(){ return i("../instance-initializers/sentry-performance.js");});
d("mewe/moment-timezone", function(){ return i("../moment-timezone.js");});
d("mewe/moment", function(){ return i("../moment.js");});
d("mewe/pro-constants", function(){ return i("../pro-constants.js");});
d("mewe/router", function(){ return i("../router.js");});
d("mewe/services/-ensure-registered", function(){ return i("../services/-ensure-registered.js");});
d("mewe/services/Task", function(){ return i("../services/Task.js");});
d("mewe/services/abilities", function(){ return i("../services/abilities.js");});
d("mewe/services/account", function(){ return i("../services/account.js");});
d("mewe/services/analytics", function(){ return i("../services/analytics.js");});
d("mewe/services/can", function(){ return i("../services/can.js");});
d("mewe/services/chat", function(){ return i("../services/chat.js");});
d("mewe/services/dialogs", function(){ return i("../services/dialogs.js");});
d("mewe/services/dynamic-dialogs", function(){ return i("../services/dynamic-dialogs.js");});
d("mewe/services/dynamic-popups", function(){ return i("../services/dynamic-popups.js");});
d("mewe/services/features", function(){ return i("../services/features.js");});
d("mewe/services/in-viewport", function(){ return i("../services/in-viewport.js");});
d("mewe/services/journals", function(){ return i("../services/journals.js");});
d("mewe/services/page-title-list", function(){ return i("../services/page-title-list.js");});
d("mewe/services/page-title", function(){ return i("../services/page-title.js");});
d("mewe/services/phone-input", function(){ return i("../services/phone-input.js");});
d("mewe/services/pickers", function(){ return i("../services/pickers.js");});
d("mewe/services/postbox-upload", function(){ return i("../services/postbox-upload.js");});
d("mewe/services/purchase", function(){ return i("../services/purchase.js");});
d("mewe/services/settings", function(){ return i("../services/settings.js");});
d("mewe/services/storyblok", function(){ return i("../services/storyblok.js");});
d("mewe/services/tasks", function(){ return i("../services/tasks.js");});
d("mewe/services/websocket", function(){ return i("../services/websocket.js");});
d("mewe/workers/detect-wakeup-worker", function(){ return i("../workers/detect-wakeup-worker.js");});
d("mewe/workers/encode-mp3-worker", function(){ return i("../workers/encode-mp3-worker.js");});
d("mewe/ws-controllers/comment-ws-controller", function(){ return i("../ws-controllers/comment-ws-controller.js");});
d("mewe/ws-controllers/index", function(){ return i("../ws-controllers/index.js");});
d("mewe/ws-controllers/post-ws-controller", function(){ return i("../ws-controllers/post-ws-controller.js");});
d("mewe/pods/application/template", function(){ return i("../pods/application/template.hbs");});
d("mewe/controllers/application", function(){ return i("../controllers/application.js");});
d("mewe/routes/application", function(){ return i("../routes/application.js");});
d("mewe/templates/application/application", function(){ return i("../templates/application/application.hbs");});
d("mewe/templates/application/index", function(){ return i("../templates/application/index.js");});
d("mewe/templates/contacts-import", function(){ return i("../templates/contacts-import.hbs");});
d("mewe/controllers/contacts-import", function(){ return i("../controllers/contacts-import.js");});
d("mewe/routes/contacts-import", function(){ return i("../routes/contacts-import.js");});
d("mewe/templates/paypal-failure", function(){ return i("../templates/paypal-failure.hbs");});
d("mewe/controllers/paypal-failure", function(){ return i("../controllers/paypal-failure.js");});
d("mewe/routes/paypal-failure", function(){ return i("../routes/paypal-failure.js");});
d("mewe/templates/paypal-success", function(){ return i("../templates/paypal-success.hbs");});
d("mewe/controllers/paypal-success", function(){ return i("../controllers/paypal-success.js");});
d("mewe/routes/paypal-success", function(){ return i("../routes/paypal-success.js");});
d("mewe/routes/about", function(){ return i("../routes/about.js");});
d("mewe/templates/confirm-account", function(){ return i("../templates/confirm-account.hbs");});
d("mewe/routes/confirm-account", function(){ return i("../routes/confirm-account.js");});
d("mewe/templates/confirm-group-invite", function(){ return i("../templates/confirm-group-invite.hbs");});
d("mewe/routes/confirm-group-invite", function(){ return i("../routes/confirm-group-invite.js");});
d("mewe/routes/deprecated-profile", function(){ return i("../routes/deprecated-profile.js");});
d("mewe/templates/google-auth", function(){ return i("../templates/google-auth.hbs");});
d("mewe/routes/google-auth", function(){ return i("../routes/google-auth.js");});
d("mewe/routes/help", function(){ return i("../routes/help.js");});
d("mewe/routes/index", function(){ return i("../routes/index.js");});
d("mewe/routes/mixins/events-route-base", function(){ return i("../routes/mixins/events-route-base.js");});
d("mewe/routes/mixins/profile-route-base", function(){ return i("../routes/mixins/profile-route-base.js");});
d("mewe/routes/mixins/unsub-route-base", function(){ return i("../routes/mixins/unsub-route-base.js");});
d("mewe/templates/move-to-web3", function(){ return i("../templates/move-to-web3.hbs");});
d("mewe/routes/move-to-web3", function(){ return i("../routes/move-to-web3.js");});
d("mewe/routes/privacy", function(){ return i("../routes/privacy.js");});
d("mewe/routes/route", function(){ return i("../routes/route.js");});
d("mewe/templates/set-new-password", function(){ return i("../templates/set-new-password.hbs");});
d("mewe/routes/set-new-password", function(){ return i("../routes/set-new-password.js");});
d("mewe/templates/start", function(){ return i("../templates/start.hbs");});
d("mewe/routes/start", function(){ return i("../routes/start.js");});
d("mewe/templates/storyblok", function(){ return i("../templates/storyblok.hbs");});
d("mewe/routes/storyblok", function(){ return i("../routes/storyblok.js");});
d("mewe/routes/terms", function(){ return i("../routes/terms.js");});
d("mewe/routes/utils", function(){ return i("../routes/utils.js");});
d("mewe/templates/web3-login-confirm", function(){ return i("../templates/web3-login-confirm.hbs");});
d("mewe/routes/web3-login-confirm", function(){ return i("../routes/web3-login-confirm.js");});
d("mewe/templates/web3-login", function(){ return i("../templates/web3-login.hbs");});
d("mewe/routes/web3-login", function(){ return i("../routes/web3-login.js");});
d("mewe/templates/web3-register-confirm", function(){ return i("../templates/web3-register-confirm.hbs");});
d("mewe/routes/web3-register-confirm", function(){ return i("../routes/web3-register-confirm.js");});
d("mewe/templates/web3-register", function(){ return i("../templates/web3-register.hbs");});
d("mewe/routes/web3-register", function(){ return i("../routes/web3-register.js");});
d("mewe/templates/externalhit", function(){ return i("../templates/externalhit.hbs");});
d("mewe/templates/web3-migrate", function(){ return i("../templates/web3-migrate.hbs");});



w._embroiderRouteBundles_ = [
  {
    names: ["app.chat","app.chat.create-thread","app.chat.requests-thread","app.chat.requests","app.chat.requests.thread.message","app.chat.thread","app.chat.thread.message","app.chat.index"],
    load: function() {
      return import("./_route_/app.chat.js");
    }
  },
  {
    names: ["app.groups"],
    load: function() {
      return import("./_route_/app.groups.js");
    }
  },
  {
    names: ["app.mycloud"],
    load: function() {
      return import("./_route_/app.mycloud.js");
    }
  },
  {
    names: ["app.myworld","app.myworld.single-post"],
    load: function() {
      return import("./_route_/app.myworld.js");
    }
  },
  {
    names: ["app.events","app.events.birthdays","app.events.hosting","app.events.index","app.events.invited","app.events.past","app.events.upcoming"],
    load: function() {
      return import("./_route_/app.events.js");
    }
  },
  {
    names: ["app.layout"],
    load: function() {
      return import("./_route_/app.layout.js");
    }
  },
  {
    names: ["app.settings","app.settings.account","app.settings.groups","app.settings.notification","app.settings.preferences","app.settings.privacy","app.settings.purchases"],
    load: function() {
      return import("./_route_/app.settings.js");
    }
  },
  {
    names: ["app.store","app.store.emojis","app.store.index","app.store.stickers","app.store.subscriptions","app.store.themes"],
    load: function() {
      return import("./_route_/app.store.js");
    }
  },
  {
    names: ["app","app.cloudsponge-proxy","app.event","app.event.feed","app.event.attendees","app.event.attendees.attending","app.event.attendees.interested","app.event.attendees.invited","app.event.attendees.notattending","app.event.attendees.profile","app.event.feed-pending","app.event.media","app.event.settings","app.event.single-post","app.file","app.group","app.group.index","app.group.index.feed","app.group.index.profile","app.group.index.feed-pending","app.group.members","app.group.members.profile","app.group.members.admins","app.group.members.all","app.group.members.blocked","app.group.members.invited","app.group.members.pending","app.group.calendar","app.group.calendar.birthdays","app.group.calendar.hosting","app.group.calendar.index","app.group.calendar.invited","app.group.calendar.past","app.group.calendar.upcoming","app.group.documents","app.group.media","app.group.member-invite","app.group.schedule","app.group.settings","app.group.single-post-approval","app.group.single-post","app.invite","app.pages","app.pages.statistics","app.publicid","app.publicid.contacts","app.publicid.feed","app.publicid.groups.posts","app.publicid.posts","app.publicid.statistics","app.publicid.statistics.followers","app.publicid.statistics.index","app.publicid.statistics.posts","app.publicid.about","app.publicid.media.album","app.publicid.media.albums","app.publicid.media.stream","app.publicid.media.tags","app.publicid.notifications","app.publicid.schedule","app.publicid.settings","app.publicid.settings.admin","app.publicid.settings.contact","app.publicid.settings.general","app.publicid.settings.moderation","app.publicid.settings.notifications","app.publicid.settings.permissions","app.publicid.settings.subscription","app.publicid.settings.users","app.publicid.settings.users.banned","app.publicid.settings.users.followers","app.publicid.single-post","app.share-to-mewe","app.delete-account","app.index","app.page","app.profileid"],
    load: function() {
      return import("./_route_/app.js");
    }
  },
  {
    names: ["login"],
    load: function() {
      return import("./_route_/login.js");
    }
  },
  {
    names: ["register"],
    load: function() {
      return import("./_route_/register.js");
    }
  },
  {
    names: ["videocall"],
    load: function() {
      return import("./_route_/videocall.js");
    }
  },
  {
    names: ["401"],
    load: function() {
      return import("./_route_/401.js");
    }
  },
  {
    names: ["404"],
    load: function() {
      return import("./_route_/404.js");
    }
  },
  {
    names: ["500"],
    load: function() {
      return import("./_route_/500.js");
    }
  },
  {
    names: ["cloudsponge-proxy"],
    load: function() {
      return import("./_route_/cloudsponge-proxy.js");
    }
  },
  {
    names: ["confirm"],
    load: function() {
      return import("./_route_/confirm.js");
    }
  },
  {
    names: ["congratulations"],
    load: function() {
      return import("./_route_/congratulations.js");
    }
  },
  {
    names: ["invite"],
    load: function() {
      return import("./_route_/invite.js");
    }
  },
  {
    names: ["join-event-front"],
    load: function() {
      return import("./_route_/join-event-front.js");
    }
  },
  {
    names: ["join-event"],
    load: function() {
      return import("./_route_/join-event.js");
    }
  },
  {
    names: ["join-group-front"],
    load: function() {
      return import("./_route_/join-group-front.js");
    }
  },
  {
    names: ["join-group"],
    load: function() {
      return import("./_route_/join-group.js");
    }
  },
  {
    names: ["join-page"],
    load: function() {
      return import("./_route_/join-page.js");
    }
  },
  {
    names: ["lockout"],
    load: function() {
      return import("./_route_/lockout.js");
    }
  },
  {
    names: ["share-to-mewe"],
    load: function() {
      return import("./_route_/share-to-mewe.js");
    }
  },
  {
    names: ["signup"],
    load: function() {
      return import("./_route_/signup.js");
    }
  },
  {
    names: ["unsub-contact-invite"],
    load: function() {
      return import("./_route_/unsub-contact-invite.js");
    }
  },
  {
    names: ["unsub-group-invite"],
    load: function() {
      return import("./_route_/unsub-group-invite.js");
    }
  },
  {
    names: ["unsub-newsletter"],
    load: function() {
      return import("./_route_/unsub-newsletter.js");
    }
  },
  {
    names: ["unsub-notifications"],
    load: function() {
      return import("./_route_/unsub-notifications.js");
    }
  },
  {
    names: ["validate-email"],
    load: function() {
      return import("./_route_/validate-email.js");
    }
  },
  {
    names: ["verify"],
    load: function() {
      return import("./_route_/verify.js");
    }
  },
  {
    names: ["welcome"],
    load: function() {
      return import("./_route_/welcome.js");
    }
  },
  {
    names: ["modern-browser"],
    load: function() {
      return import("./_route_/modern-browser.js");
    }
  },
]


if (!runningTests) {
  i("../app").default.create({"name":"mewe","version":"4.48.0+cc39b2b4926346f53802de75349ab216d05eceab"});
}

