import { assert } from '@ember/debug';
import EmberObject from '@ember/object';
import Ember from 'ember';
import dispatcher from 'mewe/dispatcher';

// @deprecated
export default EmberObject.extend(Ember.ActionHandler, {
  getState() {
    assert('Store: function getState needs to be overwritten.');
  },

  dispatch: dispatcher.dispatch,
});
