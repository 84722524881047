import FunctionalUtils from 'mewe/shared/functional-utils';
import Session from 'mewe/shared/session';
import Verbose from 'mewe/utils/verbose';
const verbose = Verbose({ prefix: '[Fileupload Utils]', color: 'navy', enabled: true }).log;

export const errorDialog = (ctx, msg, title) => {
  import('mewe/dialogs/simple-dialog').then((Dialog) => {
    Dialog.default
      .make({
        ctx: ctx,
        type: 'alert-simple',
        title: title,
        message: msg,
      })
      .send('open');
  });
  return false;
};

const imageTypes = /^image\/(gif|jpeg|png|bmp)$/;

const assert = (value, msg) => {
  if (!value) verbose(msg);
  return value;
};

const getIdealSizes = ({ maxWidthParam, maxHeightParam, imageSrcWidth, imageSrcHeight }) => {
  const maxWidth = maxWidthParam || 1200,
    maxHeight = maxHeightParam || 1200;

  let sourceHeight = maxWidth,
    sourceWidth = maxHeight;

  const sideDecision = imageSrcWidth / imageSrcHeight - maxWidth / maxHeight;

  // image is vertical, height is bigger than width
  if (sideDecision < 0) {
    sourceHeight = imageSrcHeight > maxHeight ? maxHeight : imageSrcHeight;
    sourceWidth = imageSrcWidth * (sourceHeight / imageSrcHeight);
  } else if (sideDecision > 0) {
    // image is horizontal, width is higher than height
    sourceWidth = imageSrcWidth > maxWidth ? maxWidth : imageSrcWidth;
    sourceHeight = imageSrcHeight * (sourceWidth / imageSrcWidth);
  }

  return { width: sourceWidth, height: sourceHeight };
};

export default {
  wrongFormatMsg: function () {
    return FunctionalUtils.error(__('Wrong file format'));
  },

  isDestroying: function (postbox) {
    return !assert(!(postbox.isDestroying || postbox.isDestroyed), 'prevent when postbox is destroying');
  },

  // handling non-image files
  isNonImage: function (postbox, originalFile) {
    if (!this.isImageFileTypeSupported(originalFile.type)) {
      verbose('image file type not supported: ' + originalFile.type);
      return true;
    }
    return false;
  },

  defaultFileSizeExceedingMessage: function () {
    return __('File should not exceed 500MB.');
  },

  showDefaultFileErrorMessage: function () {
    FunctionalUtils.error(this.defaultFileSizeExceedingMessage());
  },

  fileSizeErrorDialog: function (ctx) {
    errorDialog(ctx, this.defaultFileSizeExceedingMessage(), __('File upload error'));
  },

  beforeSend: function (xhr) {
    const csrfToken = Session.getCsrfToken();

    if (csrfToken) {
      xhr.setRequestHeader('X-CSRF-Token', csrfToken);
    }
  },

  isImageFileTypeSupported: function (fileType) {
    return imageTypes.test(fileType);
  },

  getFail401Handler: function (that, handleNon401Fail) {
    return function (e, data) {
      if (data.jqXHR && data.jqXHR.status !== 401) {
        if (handleNon401Fail) {
          handleNon401Fail.call(that, e, data);
        }
      }
    };
  },
};

export const getImageOrientation = (file) => {
  return new Promise((resolve, reject) => {
    if (!file || file.size < 12 || file.type !== 'image/jpeg') {
      // Nothing to parse
      return resolve(-1);
    }
    // 256 KiB should contain all EXIF/ICC/IPTC segments:
    const maxMetaDataSize = 262144;

    const reader = new FileReader();

    reader.onerror = reject;
    reader.onabort = reject;

    reader.onload = function (e) {
      // code from https://stackoverflow.com/questions/7584794/accessing-jpeg-exif-rotation-data-in-javascript-on-the-client-side/20600869
      var view = new DataView(e.target.result);
      if (view.getUint16(0, false) != 0xffd8) {
        return resolve(-1);
      }
      const length = view.byteLength;
      let offset = 2;
      while (offset < length) {
        if (view.getUint16(offset + 2, false) <= 8) {
          return resolve(-1);
        }
        var marker = view.getUint16(offset, false);
        offset += 2;
        if (marker == 0xffe1) {
          if (view.getUint32((offset += 2), false) != 0x45786966) {
            return resolve(-1);
          }

          const little = view.getUint16((offset += 6), false) == 0x4949;
          offset += view.getUint32(offset + 4, little);
          const tags = view.getUint16(offset, little);
          offset += 2;
          for (let i = 0; i < tags; i++) {
            if (view.getUint16(offset + i * 12, little) == 0x0112) {
              return resolve(view.getUint16(offset + i * 12 + 8, little));
            }
          }
        } else if ((marker & 0xff00) != 0xff00) {
          break;
        } else {
          offset += view.getUint16(offset, false);
        }
      }
      return resolve(-1);
    };

    reader.readAsArrayBuffer(file.slice(0, maxMetaDataSize));
  });
};

// keep in mind that this funciton will always load image to canvas - in some cases increasing it's size - thus function has no use for upload resizing (MW)
export const loadImage = (file, params = {}) => {
  return new Promise((resolve, reject) => {
    var canvas = document.createElement('canvas');
    var ctx = canvas.getContext('2d');
    var reader = new FileReader();

    reader.onload = function (e) {
      var imageSrc = new Image();

      imageSrc.onload = function () {
        const { width, height } = getIdealSizes({
          maxWidthParam: params.maxWidth,
          maxHeightParam: params.maxHeight,
          imageSrcWidth: imageSrc.width,
          imageSrcHeight: imageSrc.height,
        });

        canvas.width = width;
        canvas.height = height;

        try {
          // resize
          ctx.drawImage(imageSrc, 0, 0, width, height);
        } catch (error) {
          reject(error);
        }

        return resolve(canvas);
      };

      imageSrc.onerror = function (event) {
        return reject(imageSrc, event);
      };

      imageSrc.src = e.target.result;
    };

    reader.readAsDataURL(file);
  });
};

// similar as loadImage - but loadImage will always load file to a canvas (increasing it's size sometimes)
// this will resize if needed, and return file (MW)
export const resizeImage = (file, params = {}) => {
  return new Promise((resolve, reject) => {
    var canvas = document.createElement('canvas');
    var ctx = canvas.getContext('2d');
    var reader = new FileReader();

    reader.onload = function (e) {
      var imageSrc = new Image();

      imageSrc.onload = function () {
        if (imageSrc.width <= params.maxWidth && imageSrc.height <= params.maxHeight) resolve(file);

        const { width, height } = getIdealSizes({
          maxWidthParam: params.maxWidth,
          maxHeightParam: params.maxHeight,
          imageSrcWidth: imageSrc.width,
          imageSrcHeight: imageSrc.height,
        });

        canvas.width = width;
        canvas.height = height;

        try {
          // resize
          ctx.drawImage(imageSrc, 0, 0, width, height);
        } catch (error) {
          reject(error);
        }

        return resolve(blobToFile(canvas.toDataURL()));
      };

      imageSrc.onerror = function (event) {
        return reject(imageSrc, event);
      };

      imageSrc.src = e.target.result;
    };

    reader.readAsDataURL(file);
  });
};

export const blobToFile = (blob, fileName) => {

    const blobRegexp = /^data\:([\w\d\/\-]+)\;base64\,(.*)$/.exec(blob);
    const [contentType, rawData] = blobRegexp.slice(1);
    const binaryImageData = new Uint8Array(Array.prototype.map.call(atob(rawData), (char) => char.charCodeAt(0)));

    const newFile = new Blob([binaryImageData], { type: contentType });
    newFile.name = fileName;
    newFile.lastModified = new Date();

    return newFile;

};
