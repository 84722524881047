import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { later } from '@ember/runloop';

import Storage from 'mewe/shared/storage';
import PublicPagesApi from 'mewe/api/public-pages-api-unauth';
import CurrentUserStore from 'mewe/stores/current-user-store';
import AccountApi from 'mewe/api/account-api';
import { emailRegex, dsnpHandleRegex, getRedirToInnerAppQueryParam } from 'mewe/shared/utils';
import { isInJail, isLocked } from 'mewe/utils/jail-utils';
import { trimAndLower, getWalletHost, isDefined } from 'mewe/utils/miscellaneous-utils';
import { timeFromNow } from 'mewe/utils/datetime-utils.js';
import FunctionalUtils from 'mewe/shared/functional-utils.js';
import tokenManager from 'mewe/shared/token-manager';
import Session from 'mewe/shared/session';
import cookie from 'mewe/shared/cookie';
import config from 'mewe/config';
import i18n from 'i18next';

export default class MwHomeLoginFormComponent extends Component {
  challengeOrigin = 'login';
  arkoseKey = config.arkoseKeyLog;
  hcaptchaSiteKey = config.hcaptcha.login;
  hcaptchaElemClass = 'h-captcha_login';

  @tracked afterChallengeCallback;
  @tracked challengeReady;

  @service router;
  @service analytics;
  @service phoneInput;
  @service dynamicDialogs;

  @tracked email = '';
  @tracked password = '';
  @tracked handle = '';
  @tracked stepNumber; // unknown by default, to be set in constructor
  @tracked isEmailFlow = true; // on Web show email input by default
  @tracked isDsnpLogin; // true if login via AA, false if login using password
  @tracked isDsnpUser; // true if user is DSNP user

  @tracked invalidEmail = true; // invalid because empty by default
  @tracked invalidPhone = true; // invalid because empty by default
  @tracked invalidPassword = false; // invalidated only by API error response
  @tracked needConfirmEmail = false;
  @tracked emailSentText;
  @tracked initialCountry = 'us';
  @tracked isIdentifierSubmitted;
  @tracked isPasswordSubmitted;
  @tracked isCheckingIndentity;
  @tracked loginRequestInProgress;
  @tracked isLoadingSignupView;
  @tracked dsnpParams;
  @tracked error;

  minPasswordLength = 6;

  // This component is used for 2 flows:
  // - login flow, can be done with password or with AA authentication
  // - migration flow, including login as first step, then handle claiming and then migration

  // ====== STEPS ======
  // 0 - email/phone input, identification step
  // 1 - password input, authentication step
  // 2 - handle input, handle selection step (migration flow only)
  // 3 - amplica iframe view, authentication of DSNP user or migration of non-DSNP user
  // 4 - forgot password

  constructor() {
    super(...arguments);

    const blockedUntil = cookie.get('loginBlockedUntil') || 0;
    const isLockedError = +blockedUntil > new Date().getTime();
    if (isLockedError) {
      this.loginLockedUntil = timeFromNow(+blockedUntil, Date.now(), i18n.language);
      this.loginBlocked = true;
      return;
    }

    this.iframeLoginMessageListenerBind = this.iframeLoginMessageListener.bind(this);
    this.iframeMigrationMessageListenerBind = this.iframeMigrationMessageListener.bind(this);

    Session.isAuthenticated().then(({ isAuthenticated }) => {
      this.isLoggedIn = isAuthenticated;

      if (this.isLoggedIn) {
        if (this.isMigrationPage) {
          this.fetchIdentifier();
        } else {
          this.goToApp(false);
        }
      } else {
        // not logged in user, show identification step
        this.phoneInput.load();
        this.stepNumber = 0;

        if (~document.location.search.indexOf('next=')) {
          this.error = __(
            `Page you are trying to reach is available only for logged in users. Please log in and you will be redirected.`
          );
        }

        // remove 'xTraceId' that could remain e.g. from previous loggin attempt
        Storage.remove(Storage.keys.xTraceId);
      }
    });
  }

  get isMigrationPage() {
    return this.args.isMigrationPage && !this.migrationAbandoned;
  }

  get emailValue() {
    return trimAndLower(this.email);
  }

  @action
  emailUpdated() {
    // reset submitted state on any change
    this.isIdentifierSubmitted = false;

    const isValid = emailRegex.test(this.emailValue);
    this.invalidEmail = !isValid;
  }

  get showEmailError() {
    return this.isIdentifierSubmitted && this.invalidEmail;
  }

  get handleValue() {
    return trimAndLower(this.handle).replaceAll(' ', '');
  }

  get isHandleValid() {
    return dsnpHandleRegex.test(this.handleValue);
  }

  @action
  phoneUpdated(number, params) {
    // hack for testers to be able to use +89 as a country code
    if (number.length === 12 && number.slice(0, 3) === '+89') {
      params.selectedCountryData.dialCode = '89';
      params.isValidNumber = true;
    }

    // reset submitted state on any change
    this.isIdentifierSubmitted = false;

    this.phoneCode = `+${params.selectedCountryData?.dialCode}`;
    this.phoneNumber = number.replace(this.phoneCode, '');
    this.invalidPhone = !params.isValidNumber;

    // migration usecase when logged in user opened migration page and uses phone number
    // then we need to submit identitifier after phone number is tested as valid
    if (this.submitAfterPhoneChecking) {
      this.checkIdentifier();
    }
  }

  get showPhoneError() {
    return this.isIdentifierSubmitted && this.invalidPhone;
  }

  get passwordValue() {
    return this.password.trim();
  }

  get showPasswordError() {
    return this.isPasswordSubmitted && (this.invalidPassword || this.passwordValue.length < this.minPasswordLength);
  }

  @action
  passwordUpdated() {
    this.invalidPassword = false;
  }

  get isSubmitIdentifierDisabled() {
    return (
      !this.challengeReady ||
      !this.args.configLoaded ||
      this.isCheckingIndentity ||
      (this.isEmailFlow && this.invalidEmail) ||
      (!this.isEmailFlow && this.invalidPhone)
    );
  }

  get isConfirmPasswordDisabled() {
    return this.loginRequestInProgress || this.passwordValue.length < this.minPasswordLength;
  }

  get dsnpRequestUrl() {
    const host = getWalletHost();
    const version = `version=${this.args.useSmsV2Flow ? '2' : '1'}`;

    if (this.isMigrationPage) {
      return `${host}/signup/onboard/form?${version}`;
    } else {
      return `${host}/login/${this.isEmailFlow ? 'email' : 'sms_code'}/form?${version}`;
    }
  }

  get showFooter() {
    return ~[0, 2].indexOf(this.stepNumber);
  }

  get showBackArrow() {
    // step 0, nothing to go back to
    // step 5 reset password has no stepping back
    if (this.stepNumber === 0 || this.stepNumber === 5) return false;
    // step 2, handle claim. Can't go back to login from here, user already logged in
    if (this.stepNumber === 2 && this.isMigrationPage) return false;
    // step 4, password reset
    if (this.stepNumber === 4) return false;

    return isDefined(this.stepNumber); // initially stepNumber is not defined
  }

  get hasErrors() {
    if (this.isEmailFlow) {
      return this.invalidEmail;
    } else {
      return this.invalidPhone;
    }
  }

  fetchIdentifier() {
    // need to fetch user data to get his email/phone for migration
    AccountApi.getCurrentUser().then((data) => {
      CurrentUserStore.send('handle', data, true); // current user promise has to be resolved for analytics to work

      if (data.primaryEmail) {
        this.email = data.primaryEmail;
        this.emailUpdated(); // validate email
      } else if (data.primaryPhoneNumber) {
        this.phoneNumber = data.primaryPhoneNumber;
        this.initialCountry = null; // unset because it will be extracted from the phoneNumber
        this.isEmailFlow = false; // switch to phone flow only after setting phoneNumber/initialCountry
        this.submitAfterPhoneChecking = true;
      }

      this.handle = data.publicLinkId; // prefill dsnp handle with mewe handle

      this.checkIdentifier();
    });
  }

  @action
  submitIdentifier(isDsnpLogin) {
    this.isDsnpLogin = isDsnpLogin;

    this.analytics.sendEvent('logInIdentifierEntered', {
      is_web3_migration: this.isMigrationPage,
      login_type: this.isEmailFlow ? 'email' : 'phone',
    });

    // we track user choice of login method only on login page, not on migration page
    if (!this.isMigrationPage) {
      this.analytics.sendEvent('buttonClicked', isDsnpLogin ? 'Login with Custodial Wallet' : 'Login with Password');
    }

    this.checkIdentifier();
  }

  checkIdentifier() {
    // display possible errors
    this.isIdentifierSubmitted = true;

    if (this.hasErrors) {
      return false;
    }

    this.isCheckingIndentity = true;

    if (this.isEmailFlow) {
      PublicPagesApi.checkAccountByEmail(this.emailValue)
        .then((res) => this.identitifierCheckCallback(res))
        .catch((error) => {
          if (error.data?.errorCode === 100) {
            this.invalidEmail = true;
          } else {
            FunctionalUtils.showDefaultErrorMessage();
          }

          this.isCheckingIndentity = false;
        });
    } else {
      PublicPagesApi.checkAccountByPhone(this.phoneCode, this.phoneNumber)
        .then((res) => this.identitifierCheckCallback(res))
        .catch((error) => {
          if (error.data?.errorCode === 115) {
            this.invalidPhone = true;
          } else {
            FunctionalUtils.showDefaultErrorMessage();
          }

          this.isCheckingIndentity = false;
        });
    }
  }

  // identifier check is done after:
  // A: submitting email/phone in step 0 when user is not logged in
  // B: during component initialisation when user is logged in and migration flow is active
  identitifierCheckCallback(res = {}) {
    // identifier check should return `trc` which is an ID generated by the backend
    // and used to identify user in following requests in order to debugg issues
    this.traceId = res.trc;

    Storage.set(Storage.keys.xTraceId, res.trc);

    // non-existing account for this identificator
    if (!res.exists) {
      if (this.isEmailFlow) {
        this.invalidEmail = true;
      } else {
        this.invalidPhone = true;
      }

      this.isCheckingIndentity = false;
      return false;
    }

    // ======= existing non-DSNP account ========
    if (res.exists && (!res.dsnp || !res.hasMsaId)) {
      // === logged in user ===
      // - if migration page then go to handle claiming (step 2)
      // - if login page then redirect to app
      if (this.isLoggedIn) {
        this.stepNumber = this.isMigrationPage ? 2 : this.goToApp(false);
      }
      // === not logged in user ===
      // non-DSNP user is always moved to login by password (step 1),
      // if he selected to login via AA then there will be message explaining why he can't
      else {
        this.stepNumber = 1;
      }

      this.isCheckingIndentity = false;
      return;
    }

    // ======= existing DSNP account - Amplica login ========
    if (res.exists && res.dsnp && res.hasMsaId) {
      this.isDsnpUser = true;

      if (this.isMigrationPage) {
        if (this.isLoggedIn) {
          this.goToApp(true);
        } else {
          // if we detect DSNP user trying to do migration then we switch to regular login flow,
          // it's important to do this before setting dsnpParams to get proper dsnpRequestUrl
          this.migrationAbandoned = true;

          this.handleDsnpLoginPayload(res);
        }
      } else {
        // DSNP user can choose login method - direct him to the selected one
        if (this.isDsnpLogin) {
          this.handleDsnpLoginPayload(res);
        } else {
          this.stepNumber = 1;

          // if password login then identityChecking ends here, for AA login it ends when iframe is loaded
          this.isCheckingIndentity = false;
        }
      }
    }
  }

  handleDsnpLoginPayload(res) {
    // existing DSNP account - login payload exists and can be used for DSNP login,
    // setting payload to 'dsnpParams' triggers loading Amplica screen in the iframe
    // (login payload is returned also when user is already logged in)
    this.dsnpParams = JSON.stringify(res.login);

    // send this event only when user actually submits handle and not during login process
    if (this.isMigrationPage) {
      this.analytics.sendEvent('web3HandleSubmitted');
    }

    // remove possible previous listener if user moved to previous step and then back to this step
    window.removeEventListener('message', this.iframeLoginMessageListenerBind);
    window.addEventListener('message', this.iframeLoginMessageListenerBind);
  }

  iframeLoginMessageListener(e) {
    if (e.origin !== getWalletHost()) {
      return;
    }

    if (e.data?.type === 'startSmsProcess') {
      this.sessionId = e.data.payload.sessionId;

      const smsRequestFunc = (challengeToken, provider, onChallengeFailed) => {
        PublicPagesApi.sendDsnpLoginSms(
          {
            countryCode: this.phoneCode,
            phone: this.phoneNumber,
            walletSessionId: this.sessionId,
            session_token: challengeToken,
            challenge_provider: provider,
          },
          this.traceId
        )
          .then((data) => {
            if (data.limitReached) {
              FunctionalUtils.error(__(`Sorry, you've reached the limit.`));
              return;
            }

            this.tmpPhoneId = data.tmpPhoneId;
            this.validationCode = data.code;
          })
          .catch((err) => {
            if (err.status === 400 && err.data?.errorCode == 117) {
              onChallengeFailed();
            }
          });
      };

      this.afterChallengeCallback = smsRequestFunc;
    }

    // callbacks differ depending on used version
    if (this.args.useSmsV2Flow) {
      if (e.data?.type === 'smsPayloadV2') {
        this.doLogin(e.data.payload);
      }
    } else {
      if (e.data?.type === 'loginPayload') {
        this.doLogin({ code: e.data.payload.response.token });
      }
    }

    if (e.data?.type === 'error') {
      this.custodialWalletFailed(e.data, 'login');
    }
  }

  iframeMigrationMessageListener(e) {
    if (e.origin !== getWalletHost()) {
      return;
    }

    if (e.data?.type === 'onboardPayload') {
      PublicPagesApi.confirmMigration({ sessionId: e.data.payload.sessionId }, this.traceId)
        .then(() => (window.location = '/myworld#dsnp'))
        .catch(() => FunctionalUtils.showDefaultErrorMessage());
    }

    if (e.data?.type === 'error') {
      this.custodialWalletFailed(e.data, 'migration');
    }
  }

  custodialWalletFailed(data, loginOrMigration) {
    this.analytics.sendEvent('custodialWalletFailed', {
      error_id: data.payload.id,
      description: data.payload.description,
      stack_trace: data.payload.stackTrace,
      session_id: this.sessionId ? this.sessionId : null,
      trace_id: loginOrMigration === 'migration' ? 'migration' : `login/${this.isEmailFlow ? 'email' : 'sms_code'}`,
    });
  }

  // called when iframe with form is inserted into DOM
  @action
  dsnpFormReady() {
    // submit form in the iframe to load DSNP login view
    document.querySelector('#dsnp-form').submit();

    this.loadIframe = new Promise((resolve) => {
      // wait until iframe is loaded and display the iframe when it's ready
      const iframe = document.getElementById('dsnp-iframe');
      iframe.addEventListener('load', () => {
        resolve();
        this.stepNumber = 3;
        this.isCheckingIndentity = false;
        this.isLoadingSignupView = false;
      });
    });

    // store email because it's needed in login process for login request on confirmation page opened from email link
    // store handle because it's needed for the dsnp FTUE popup when redirected to /myworld#dsnp
    if (this.isMigrationPage || this.isEmailFlow) {
      Storage.set(
        Storage.keys.dsnpAuthData,
        JSON.stringify({
          email: this.emailValue,
          handle: this.handleValue,
        })
      );
    }
  }

  @action
  submitPassword(e) {
    // prevent default form submittion
    e.preventDefault();

    this.isPasswordSubmitted = true;
    this.loginRequestInProgress = true;

    this.afterChallengeCallback = (challengeToken, provider, onChallengeFailed) => {
      this.doLogin({ challengeToken, provider, onChallengeFailed });
    };
  }

  @action
  submitHandle() {
    if (!this.isHandleValid) {
      return;
    }

    this.isLoadingSignupView = true;

    PublicPagesApi.getMigrationPayload(this.handleValue, this.traceId)
      .then((res) => {
        // it's migration flow if 'signup' payload is returned
        if (res.payload) {
          // setting params will initialise iframe load and it will be displayed when ready
          this.dsnpParams = JSON.stringify(res.payload);
        }

        // send this event only when user actually is moved to next step by setting dsnpParams
        this.analytics.sendEvent('web3HandleSubmitted');

        // remove possible previous listener if user moved to previous step and then back to this step
        window.removeEventListener('message', this.iframeMigrationMessageListenerBind);
        window.addEventListener('message', this.iframeMigrationMessageListenerBind);
      })
      .catch((res) => {
        this.isLoadingSignupView = false;

        const err = res.data;

        // 122 - handle is taken, 120 - handle is forbidden
        if (err?.errorCode === 122 || err?.errorCode === 120) {
          this.dynamicDialogs.openDialog('simple-dialog-new', {
            message: __('Please use a different handle. This one is forbidden or taken.'),
            confirmBtnText: __('Ok'),
          });
          return;
        }

        FunctionalUtils.showDefaultErrorMessage();
      });
  }

  // login can be called from 2 flows:
  // - login of DSNP user with phone using SMS code,
  //   in this case 'options.code' param should be passed
  // - migration of non-DSNP user after login with regular password,
  //   in this case options should contain captcha challenge data
  doLogin(options = {}) {
    let params;

    // non-DSNP user can try DSNP login,
    // checking both conditions to be sure he's elligible for DSNP login
    if (this.isDsnpLogin && this.isDsnpUser) {
      params = {
        username: `${this.phoneCode}${this.phoneNumber}`,
        sessionId: this.sessionId,
        code: this.args.useSmsV2Flow ? options.token : options.code,
      };
    } else {
      params = {
        password: this.password,
        username: this.isEmailFlow ? this.emailValue : `${this.phoneCode}${this.phoneNumber}`,
        session_token: options.challengeToken,
        challenge_provider: options.provider,
      };
    }

    AccountApi.login(params, this.traceId)
      .then((response) => {
        tokenManager.set(response, false);
        CurrentUserStore.send('handle', response.user, true);

        // this login event is for all kinds of login
        this.analytics.sendEvent('loggedIn', {
          // `is_web3` is superproperty set before sending each event in the service,
          // but for `loggedIn` it has to be set here because service won't know yet if user is DSNP
          is_web3: this.isDsnpUser || false,
          is_web3_migration: this.isMigrationPage,
          login_type: this.isEmailFlow ? 'email' : 'phone',
        });

        // postponed to allow analytics to record events before reload,
        // this can be removed once we redirect without reloading page
        later(
          this,
          () => {
            CurrentUserStore.getState().deferred.promise.then(() => {
              if (isInJail(response)) {
                this.router.transitionTo('lockout');
              } else if (isLocked(response)) {
                this.router.transitionTo('verify');
              } else {
                // - after login in migration flow go to handle claiming step
                // - after DSNP login go to app
                if (this.isMigrationPage) {
                  this.handle = CurrentUserStore.getState().publicLinkId; // prefill dsnp handle with mewe handle
                  this.stepNumber = 2;
                } else {
                  this.goToApp(false);
                }
              }
            });
          },
          1000
        );
      })
      .catch((response) => {
        this.loginRequestInProgress = false;

        if (response.status === 409) {
          if (!this.isDestroying && !this.isDestroyed) {
            this.needConfirmEmail = true;
          }
        } else if (response.status === 400 && response.data.errorCode == 117) {
          options.onChallengeFailed();
        } else if (
          response.status === 400 &&
          ((response.data && response.data.message) || '').indexOf('Something wrong happened') === 0
        ) {
          FunctionalUtils.showDefaultErrorMessage();
          later(this, () => window.location.reload(), 1000);
        } else if (response.status === 401 && response.data.block) {
          if (!this.isDestroying && !this.isDestroyed) {
            this.loginLockedUntil = timeFromNow(response.data.block * 1000, Date.now(), i18n.language);
            this.loginBlocked = true;
          }

          cookie.set('loginBlockedUntil', response.data.block * 1000);
        } else {
          if (!this.isDestroying && !this.isDestroyed) {
            this.invalidPassword = true;
          }
        }
      })
      .then(() => {
        if (!this.isDestroying && !this.isDestroyed) {
          this.sendingLoginRequest = false;
        }
      });
  }

  @action
  toggleEmailOrPhone() {
    this.email = '';
    this.phoneNumber = '';
    this.isIdentifierSubmitted = false;
    this.isEmailFlow = !this.isEmailFlow;
  }

  @action
  stepBack() {
    if (this.isMigrationPage) {
      if (this.stepNumber === 3) {
        // event send when user moved back from AA iframe
        this.analytics.sendEvent('web3ToSCancelled', { login_type: this.isEmailFlow ? 'email' : 'phone' });
      }

      this.stepNumber -= 1;
    } else {
      // moving back to step 0 - if there was abandoned migration it is allowed again
      this.migrationAbandoned = false;

      this.stepNumber = 0;
    }

    this.password = '';
    this.isPasswordSubmitted = false;
    // remove loaded iframe, it will need to be loaded with new params
    this.dsnpParams = null;
    this.sessionId = null;
  }

  @action
  goToApp(isAlreadyMigrated) {
    const urlQueryNext = getRedirToInnerAppQueryParam();

    if (urlQueryNext) {
      window.location.href = urlQueryNext;
      if (urlQueryNext.indexOf('?download') !== -1) {
        window.setTimeout(() => (window.location.href = '/myworld'), 3500);
      }
    } else {
      // already migrated user should be redirected and info popup should be displayed
      window.location.href = `/myworld${isAlreadyMigrated ? '#migrated' : ''}`;
    }
  }

  @action
  resendEmail(e) {
    e.preventDefault();

    AccountApi.resendConfirmationLink(this.emailValue, this.traceId).then(() => {
      this.emailSentText = __('A new validation email has been sent to {email}', { email: this.emailValue });
    });
  }

  @action
  learnMoreClicked() {
    this.analytics.sendEvent('learnMoreClicked', { location: 'password screen', element: 'set a new password' });
  }

  @action
  challengeReadyCallback() {
    this.challengeReady = true;
  }
}
