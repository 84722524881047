import { createTemplateFactory as template } from '@ember/template-factory';
import { guidFor } from '@ember/object/internals';
import { camelize } from '@ember/string';
import { A } from '@ember/array';
import Ember from 'ember';
import { isString } from 'lodash';
export let splitRoutes = A();
export let waitRoutes = A();
export let allRoutes = A();
export let map = {};
export let parents = {};

function canNest(dsl) {
  return dsl.parent && dsl.parent !== 'application';
}

function getFullName(dsl, name, resetNamespace) {
  if (canNest(dsl) && resetNamespace !== true) {
    return dsl.parent + '.' + name;
  } else {
    return name;
  }
}

let _route = (self, name, _path, callback) => {
  if (_path) {
    if (isString(_path)) self.route(name, { path: _path }, callback);
    else self.route(name, _path);
  } else {
    self.route(name);
  }
};

export let route = (self, name, _path, callback) => {
  if (!map[self.parent]) map[self.parent] = A();

  if (!parents[self.parent]) parents[getFullName(self, name)] = self.parent;

  map[self.parent].push(getFullName(self, name));

  allRoutes.push(getFullName(self, name));

  _route(self, name, _path, callback);
};

export let split = (self, name, _path, callback) => {
  splitRoutes.push(getFullName(self, name));

  allRoutes.push(getFullName(self, name));

  _route(self, name, _path, callback);
};

export let wait = (self, name, _path, callback) => {
  waitRoutes.push(getFullName(self, name));

  allRoutes.push(getFullName(self, name));

  _route(self, name, _path, callback);
};

export let camelizedRoute = (text) => {
  var camelized = camelize(text.replace(/\./g, ' '));
  camelized = camelized.charAt(0).toUpperCase() + camelized.substr(1) + 'Route';
  return camelized;
};

export let dashed = (text) => text.replace(/\./g, '-');

export let slashed = (text) => text.replace(/\./g, '/');

export let registerSimpleTemplate = (templateName, helperName, className) => {
  // don't remove (MW)
  //var precompiler = require('ember-source/dist/ember-template-compiler');
  //console.log('withClass', JSON.parse(precompiler.precompile('<div class="hello">{{ mw-component model=model }}</div>')));
  //console.log('empty', JSON.parse(precompiler.precompile('{{ mw-component model=model }}')));

  const empty = `[[[1,[28,[35,0],null,[[\"model\"],[[33,1]]]]]],[],false,[\"routable/${helperName}\",\"model\"]]`;
  const withClass = `[[[10,0],[14,0,\"${className}\"],[12],[1,[28,[35,0],null,[[\"model\"],[[33,1]]]]],[13]],[],false,[\"routable/${helperName}\",\"model\"]]`;

  const block = className ? withClass : empty;

  const precompiled = {
    id: guidFor(block),
    block: block,
    moduleName: `mewe/pods/${templateName}/template.hbs`,
    isStrictMode: false,
  };

  Ember.TEMPLATES[slashed(templateName)] = template(precompiled);
};
