import { observer, computed } from '@ember/object';
import { on } from '@ember/object/evented';
import PromiseProxyMixin from '@ember/object/promise-proxy-mixin';
import ObjectProxy from '@ember/object/proxy';
import StoreApi from 'mewe/api/store-api';
import PS from 'mewe/utils/pubsub';
import PurchasesStore from 'mewe/stores/purchases-store';
import Storage from 'mewe/shared/storage';
import Verbose from 'mewe/utils/verbose';
import Service, { inject as service } from '@ember/service';

const verbose = Verbose({ prefix: '[Purchase Service]', color: 'darkorange', enabled: false }).log;

const ObjectPromiseProxy = ObjectProxy.extend(PromiseProxyMixin);

let promise = null;
let promiseResolve = null;

const fetchPurchased = () => {
  let retryCount = 0;

  promise = new Promise((resolve) => {
    StoreApi.purchased()
      .then(_fetchSuccess.bind(this))
      .catch((err) => {
        // sometimes err is undefined (found by sentry /issues/3348573786)
        if (err && err.status >= 500 && err.status < 600) {
          _fetchRetry(err, retryCount);
        } else {
          resolve(false);
        }
      });

    promiseResolve = resolve;
  });

  return promise;
};

let trialsPromise = null;
let trialsResolve = null;

const fetchTrials = () => {
  trialsPromise = new Promise((resolve) => {
    StoreApi.checkTrials()
      .then((response) => {
        PurchasesStore.getState().set('usedTrials', response);
        trialsResolve(response);
      })
      .catch(() => {
        trialsResolve(false);
      });

    trialsResolve = resolve;
  });

  return trialsPromise;
};

const _fetchSuccess = (response) => {
  let items = response.items;

  PurchasesStore.send('handlePurchased', items, true);

  Storage.set(Storage.keys.purchaseStore, JSON.stringify(items));

  try {
    const cartFromLS = JSON.parse(Storage.get(Storage.keys.storeItems));
    if (cartFromLS && cartFromLS.length) PurchasesStore.send('handleCart', cartFromLS);
  } catch (e) {
    verbose('cannot handle cart');
    promise.reject(e);
  }

  verbose('fetched purchased from server');

  promiseResolve(response);
};

const _fetchRetry = (err, retryCount) => {
  const _retryFunc = function (timeout) {
    setTimeout(() => {
      StoreApi.purchased().then(_fetchSuccess.bind(this)).catch(_fetchRetry.bind(this, err, retryCount));
    }, timeout);
  };

  if (retryCount === 0) {
    _retryFunc(3000);
  } else if (retryCount === 1) {
    _retryFunc(5000);
  } else if (retryCount === 2) {
    _retryFunc(10000);
  } else {
    verbose('err', err);
    promise.reject(err);
  }

  retryCount++;
};

const filterEmojiItems = (el) => el.itemId && el.itemId.indexOf('emoji-') > -1;

const getPurchased = () => {
  if (promise) return promise;
  else return fetchPurchased();
};

const getTrials = () => {
  if (trialsPromise) return trialsPromise;
  else return fetchTrials();
};

const checkStore = (name) => PurchasesStore.getState().get(name);

const promisify = (name) => {
  let promise = getPurchased().then(() => ({ purchased: checkStore(name) }));
  return ObjectPromiseProxy.create({ promise });
};

const resolved = () => ObjectPromiseProxy.create({ promise: Promise.resolve({ purchased: true }) });

export default Service.extend({
  settings: service('settings'),

  init: function () {
    this._super();

    try {
      const cached = JSON.parse(Storage.get(Storage.keys.purchaseStore));

      if (cached) {
        verbose('restore purchased items from storage');
        promise = Promise.resolve({ items: cached });
        PurchasesStore.send('handlePurchased', cached, true);
      }
    } catch (e) {}

    this.getPurchased().then(() => {
      if (!this.isDestroyed && !this.isDestroying) {
        this.set('purchasesLoaded', true);
      }

      this.checkExpiredTheme();
    });

    this.getTrials();

    this.set('storeState', PurchasesStore.getState());

    PS.Sub('tokens.refresh', () => {
      verbose('token refresh - fetch purchased items');
      fetchPurchased();
    });

    PS.Sub('navigation.reload', () => {
      verbose('page refresh - fetch purchased items');
      fetchPurchased();
    });

    PS.Sub('item.expired', this.wsExpiredItem.bind(this));
  },

  cacheNewItems(items) {
    try {
      const cached = JSON.parse(Storage.get(Storage.keys.purchaseStore));
      Storage.set(Storage.keys.purchaseStore, JSON.stringify(cached.concat(items)));
    } catch (e) {
      verbose('cannot cache new item');
    }

    PurchasesStore.send('handlePurchased', items);

    if (checkStore('hasDarkTheme')) this.set('darkTheme', resolved());
    if (checkStore('hasCallingSub')) this.set('callingSub', resolved());
    if (checkStore('hasDonorBadge')) this.set('donorBadge', resolved());
    if (checkStore('hasPremium')) this.set('premium', resolved());
    if (checkStore('hasJournals')) this.set('journals', resolved());
  },

  // switch to default theme if user has enabled other one but his 'premium' expired and he's not purchased theme
  checkExpiredTheme() {
    this.settings.promise().then(() => {
      const state = PurchasesStore.getState();
      const currentTheme = this.settings.activeTheme;

      if (currentTheme && currentTheme !== 'mewe') {
        const ownsCurrentTheme = state.purchasedItems.find((i) => i.itemId === `theme-${currentTheme}`);

        if (!state.hasPremium && !ownsCurrentTheme) {
          this.settings.saveTheme('mewe');
        }
      }
    });
  },

  wsExpiredItem({ itemId /* userId, provider, grantedBy */ }) {
    let item = PurchasesStore.getItem(itemId);

    if (item) {
      item.setProperties({
        isCancelled: false,
        provider: '', // clear provider to recompute isPurchased property
      });
    }
  },

  fetchPurchased() {
    return fetchPurchased();
  },

  getPurchased() {
    return getPurchased();
  },

  getTrials() {
    return getTrials();
  },

  purchasedEmojis() {
    return new Promise((resolve, reject) => {
      getPurchased()
        .then((response) => resolve(response && response.items ? response.items.filter(filterEmojiItems) : []))
        .catch(reject);
    });
  },

  cartUpdated: on(
    'init',
    observer(
      'storeState.cartItems.length',
      'storeState.cartItems.@each.selectedTierId',
      'storeState.cartItems.@each.optionalSubState',
      function () {
        if (!this.purchasesLoaded) return;

        const items = this.get('storeState.cartItems').map((item) => {
          let i = item.itemId;
          if (item.selectedTierId) i += `_${item.selectedTierId}`;
          if (item.optionalSubState) i += `_${item.optionalSubState}`;
          return i;
        });

        Storage.set(Storage.keys.storeItems, JSON.stringify(items));
      }
    )
  ),

  callingSub: computed(function () {
    return promisify('hasCallingSub');
  }),

  darkTheme: computed(function () {
    return promisify('hasDarkTheme');
  }),

  donorBadge: computed(function () {
    return promisify('hasDonorBadge');
  }),

  premium: computed(function () {
    return promisify('hasPremium');
  }),

  journals: computed(function () {
    return promisify('hasJournals');
  }),
});
