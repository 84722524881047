/* eslint-disable ember/no-get */
/* eslint-disable ember/use-brace-expansion */
import { A } from '@ember/array';
import { computed } from '@ember/object';
import { or, reads } from '@ember/object/computed';
import Model from 'mewe/utils/store-utils/model/model.js';
import { attr } from 'mewe/utils/store-utils/model/attribute';
import EnvironmentUtils from 'mewe/utils/environment-utils';

import { showNewToMeWePopup } from 'mewe/utils/dialogs-common';

const model = Model.extend({
  id: attr('string'),
  userId: attr('string'),
  name: attr('string'),
  nameDisplay: attr('string'), // needed for SS
  category: attr('object'),
  publicLinkId: attr('string'),
  followers: attr('number'),
  bannedCount: attr('number'),
  banned: attr('number'),
  description: attr('string', {
    defaultValue: '',
  }),
  phone: attr('string', {
    defaultValue: '',
  }),
  email: attr('string', {
    defaultValue: '',
  }),
  website: attr('string', {
    defaultValue: '',
  }),
  coverPhotoId: attr('string'),
  profilePhotoId: attr('string'),
  lastVisited: attr('number'),
  published: attr('boolean'),
  nsfw: attr('boolean'),
  allowFollowersToCommentPost: attr('boolean'),
  followsPageStories: attr('boolean'),
  isVerified: attr('boolean', {
    defaultValue: false,
  }),
  unseenCount: attr('number', {
    defaultValue: 0,
  }),
  newPosts: attr('number', {
    defaultValue: 0,
  }),
  newPostsIds: attr('array', {
    defaultValue: A(),
  }),
  _links: attr('object'),
  address: attr('object', {
    defaultValue: {},
  }),

  isOwner: attr('boolean', {
    defaultValue: false,
  }),

  isAdmin: attr('boolean', {
    defaultValue: false,
  }),

  // set on client side for open page feed - not authenticated user
  isOpenPage: attr('boolean', {
    defaultValue: false,
  }),

  wrapper: attr('object'), // undefined --> doesn't follow, true/false wrapper turned on/off

  isFollower: attr('boolean'),
  isBanned: attr('boolean'),

  isFetching: attr('boolean'),

  isOwnerAdmin: or('isAdmin', 'isOwner'),

  linkToRoute: computed(function () {
    return 'app.publicid';
  }),

  linkToId: reads('publicLinkId'),

  avatar: computed('_links.profilePhoto.href', 'isFetching', 'isOpenPage', function () {
    const href = this.get('_links.profilePhoto.href');

    return href
      ? // SG-25929 - when changing avatar, we set canvas.toDataURL() as avatar, we cannot add cdn prefix in this case
        ~href.indexOf('data:')
        ? href
        : EnvironmentUtils.getImgHost(true, this.isOpenPage) + href.replace('{static}', '1')
      : this.isFetching
      ? ''
      : `${EnvironmentUtils.getCdnHost()}/assets/predefined/cover-picture.jpg`;
  }),

  cover: computed('_links.coverPhoto.href', 'isFetching', 'isOpenPage', function () {
    const href = this.get('_links.coverPhoto.href');

    return href
      ? ~href.indexOf('data:')
        ? href
        : EnvironmentUtils.getImgHost(true, this.isOpenPage) + href.replace('{static}', '1')
      : this.isFetching
      ? ''
      : `${EnvironmentUtils.getCdnHost()}/assets/predefined/cover-picture.jpg`;
  }),

  addressCityText: computed('address.cityAndState', 'address.zipCode', function () {
    let text = this.get('address.cityAndState');
    if (this.get('address.zipCode')) {
      if (text) text += ', ' + this.get('address.zipCode');
      else text = this.get('address.zipCode');
    }
    return text;
  }),

  hasAnyAdressInfo: or('addressCityText', 'address.country', 'address.street'),

  hasAnyContactInfo: or('phone', 'email', 'website'),

  publicUrl: computed('publicLinkId', function () {
    return EnvironmentUtils.correctHostnameAndPort(`/p/${this.publicLinkId}`);
  }),

  canInvite: or('isFollower', 'isOwnerAdmin'),

  // PAGE SUBSCRIPTION
  subscription: attr('object'),

  free: attr('object'),
  // free.until => date until when page is active without subscription
  // free.reason => why page is active without subscription (beta pages, transfer, payment error, until end of cancelled subscription)
  // free object is kept even after .until date when page is deactivated to know what was the reason of deactivation

  // page is active (can be published) and has active subscription
  hasActiveSubscription: computed('subscription.expiresAt', 'subscription.isCancelled', function () {
    return this.get('subscription.expiresAt') > new Date().getTime() && !this.get('subscription.isCancelled');
  }),

  activeTimeLeft: computed('free.until', function () {
    return this.get('free.until') - new Date().getTime();
  }),

  customInteraction: computed('isOpenPage', function () {
    if (this.isOpenPage) return showNewToMeWePopup;
  }),
});

model.reopenClass({
  resourceName: 'page',
});

export default model;
