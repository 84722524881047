import storage from 'mewe/shared/storage';
import { keys } from 'mewe/shared/keys';
import tokenManager from 'mewe/shared/token-manager';

// calling this when user is not logged in yet
// this is just after successful registratoin request
// so do not add any API requests here
export const afterRegistration = function (params, user) {
  storage.markRegistration();

  storage.remove(keys.smsSent);

  storage.remove(keys.emailInvitationData);

  if (!user.locale && params.locale) user.locale = params.locale;
  tokenManager.set({ user: user });

  trackNewRegistration(params);
};

export const afterEmailConfirmation = function (token) {
  tokenManager.set(token);
};

const trackNewRegistration = (params) => {
  let location = 'homepage';

  if (params.contactInviteId) location = '/contact-public';
  else if (params.groupPublicId) location = '/group-public';
  else if (params.pagePublicId) location = '/page-public';
  else if (params.eventPublicId) location = '/event-public';
  else if (params.invitationId) location = '/contact-invitation';
};
