import Ember from 'ember';
import Post from './post-model';
import { attr, hasMany } from 'mewe/utils/store-utils/model/attribute';
import Collection from './collection';
import { ds } from 'mewe/stores/ds';
import { map, curry, compact, flatten, includes } from 'lodash';

const sort = (field) => (a, b) => {
  if (a.get(field) > b.get(field)) {
    return -1;
  } else if (a.get(field) < b.get(field)) {
    return 1;
  }

  return 0;
};

// feedInHome - param to distinguish if e.g. group feed is in /groups or /home location
// we don't show group featured posts on top of group/all feed at home
// but BE is still sending 'featured' flag and we need to handle it on FE
const featuredFirst = (field, feedInHome) => (a, b) => {
  // sort by 'featured' only if feed isn't in home location or if post is not from group
  if (!feedInHome || !a.groupId) {
    if (a.featured) return -1;
  }

  if (!feedInHome || !b.groupId) {
    if (b.featured) return 1;
  }

  return sort(field)(a, b);
};

/*
 * photos which are part of multiphoto posts are not included as they are displayed as part of multiphoto posts, to display them as one post is not desirable
 * SG-14617, SG-13759
 */
const filterPostsForFeed = (posts, tag) => {
  if (tag) {
    return posts.filter((p) => {
      return includes(p.hashTags, tag);
    });
  }

  return posts.filter((p) => {
    return !p.multiPostId || p.featured === true;
  });
};

var model = Collection.extend({
  type: attr('string', {
    defaultValue: null,
  }),

  group: attr('string', {
    defaultValue: null,
  }),

  theme: attr('string', {
    defaultValue: null,
  }),

  tag: attr('string', {
    defaultValue: null,
  }),

  filter: attr('string', {
    defaultValue: null,
  }),

  threadId: attr('string', {
    defaultValue: null,
  }),

  isFetching: attr('boolean', {
    defaultValue: true, // at the moment of model creation it's true as it's not fetched SG-24747
  }),

  order: attr('string'),

  posts: hasMany(Post),

  connectionFail: attr('boolean'),
  feedNotFound: attr('boolean'),

  nextPage: attr('string', {
    defaultValue: null,
  }),

  sortedBy: Ember.computed('order', function () {
    switch (this.order) {
      case 'scheduled':
        return 'scheduled';

      case 'original':
      default:
        return 'createdAt';
    }
  }),

  sortedPosts: Ember.computed('posts.[]', 'posts.length', 'isFetching', 'isFetchingMore', function () {
    const filteredPosts = filterPostsForFeed(this.posts, this.tag);

    if (filteredPosts) {
      if (this.sortedBy) {
        return filteredPosts.sort(sort(this.sortedBy));
      } else {
        return filteredPosts;
      }
    }
  }),

  sortedPostsFeaturedFirst: Ember.computed(
    'sortedPosts.@each.featured',
    'sortedPosts.[]',
    'sortedPosts.length',
    'isFetching',
    'isFetchingMore',
    'feedInHome',
    function () {
      const filteredPosts = filterPostsForFeed(this.sortedPosts, this.tag);

      if (filteredPosts) {
        return filteredPosts.sort(featuredFirst(this.sortedBy, this.feedInHome));
      }
    }
  ),

  offset: Ember.computed('posts.length', function () {
    return this.get('posts.length');
  }),

  safeMode: attr('boolean', {
    defaultValue: false,
  }),
});

model.reopenClass({
  resourceName: 'feed',
});

export default model;

export const insert = (feed, item) => {
  const existing = feed?.posts.findBy('id', item.id);

  if (existing && !item.isWS) {
    // posts from WS don't have full permissions,
    //if posting response comes later than WS then permissions are fixed here
    existing.set('permissions', item.permissions);
  } else {
    feed?.posts.pushObject(item);
  }

  return feed;
};

export const storePosts = curry((feed, data) => feed.set('posts', data));

export const pushPosts = curry((feed, data) => feed.posts.pushObjects(data));

export const removePost = (params) =>
  compact(
    flatten(
      map(ds.feeds, (feed) => {
        if (!feed || !feed.posts || feed.id === 'mycloud') return;
        return feed.posts.removeObjects(feed.posts.filterBy('postItemId', params.postItemId));
      })
    )
  );

export const allPosts = () =>
  flatten(Object.keys(ds.feeds).map((feed) => ds.feeds[feed].posts)).filter(
    (item, pos, self) => self.indexOf(item) == pos
  );
