import Ember from 'ember';
import { each, flow, isString, cond, stubTrue, conforms } from 'lodash';
import { FeedTypes as T } from 'mewe/constants';
import { ds } from 'mewe/stores/ds';
import FeedAPI from 'mewe/api/feed-api';
import ContactsApi from 'mewe/api/contacts-api';
import PagesApi from 'mewe/api/pages-api';
import HomeApi from 'mewe/api/home-api';
import DiscoverApi from 'mewe/api/discover-api';
import GroupStore from 'mewe/stores/group-store';
import Feed, { storePosts, pushPosts } from 'mewe/stores/models/feed-model';
import { setNextPage } from 'mewe/stores/models/collection';
import { intoHash, toSimpleUser, toSimpleGroup, fetchUtil, isMore, unsetFlag } from 'mewe/fetchers/utils';
import { toPost } from 'mewe/stores/models/post-model';
import { get, emberSet, tap, getOrCreate, map } from 'mewe/utils/fp';
import { storePages } from 'mewe/fetchers/fetch-pages';
import { handle as handleEvents } from 'mewe/fetchers/fetch-events';
import PublicPagesApi from 'mewe/api/public-pages-api-unauth';

export const deserializeAndStoreAuthors = flow(map(toSimpleUser), intoHash(ds.authors));
export const deserializeAndStoreSimpleGroups = flow(map(toSimpleGroup), intoHash(ds.simpleGroups));

export const handleOtherStores = (data) => {
  // TODO: rework into ds
  if (data.groups) deserializeAndStoreSimpleGroups(data.groups);
  if (data.users) deserializeAndStoreAuthors(data.users);
  if (data.post && data.post.user) deserializeAndStoreAuthors([data.post.user]);
  if (data.events) handleEvents('all-events', data.events);
  if (data.pages) storePages(data.pages);

  return data;
};

export const handleFeed = (collection, params, apiCall) => (data) => {
  if (collection.type === T.DISCOVER) {
    each(data.feed, (p) => (p.discoverScope = p.pageId ? 'page' : 'group'));
    data.order = 'original'; // no ordering, BE takes care of that
  }

  if (collection.type === T.OPEN_PROFILE) {
    each(data.feed, (p) => (p.isOpenProfilePost = true));
  }

  if (collection.type === T.OPEN_PAGE) {
    each(data.feed, (p) => {
      p.isOpenPagePost = true;
      p.postedByPage = true;
    });
  }

  if (collection.type === T.GROUP_PREVIEW) {
    each(data.feed, (p) => (p.isGroupPreview = true));
  }

  let fn = isMore(params) ? pushPosts : storePosts;
  fn(emberSet(collection, { order: data.order, safeMode: data.safeMode }), Ember.A(data.feed.map(toPost)));
  collection.set('apiCall', apiCall);
  return data;
};

export const fetchFeed = (api, collection, params = {}) =>
  fetchUtil(api(params), collection, params)
    .then(tap(flow(get('users'), deserializeAndStoreAuthors)))
    .then(tap(setNextPage(collection)))
    .then(tap(handleOtherStores))
    .then(handleFeed(collection, params, api))
    .finally(unsetFlag(collection));

export default fetchFeed;

export const getFeed = (id, type, params) => {
  var feed = getOrCreate(ds.feeds, Feed.extend({ id: id, type: type }), id);
  if (params !== void 0) {
    emberSet(feed, { tag: params && params.hashtag, feedInHome: params && params.feedInHome });
  }

  return feed;
};

export const getAllFeed = (p) => getFeed(T.ALL, T.ALL, p);

export const getContactsFeed = (p) => getFeed(T.CONTACTS, T.CONTACTS, p);

export const getGroupsFeed = (p) => getFeed(T.GROUPS, T.GROUPS, p);

export const getGroupFeed = (id, p) => getFeed(id, T.GROUP, p);

export const getGroupPendingFeed = (id, p) => getFeed(`pending-${id}`, T.GROUP_PENDING, p);

export const getGroupPreviewFeed = (id, p) => getFeed(`preview-${id}`, T.GROUP_PREVIEW, p);

export const getPageFeed = (id, p) => getFeed(id, T.PAGE, p);

export const getPagesFeed = (p) => getFeed(T.PAGES, T.PAGES, p);

export const getProfileFeed = (id, p) => getFeed(id, T.CONTACT, p);

export const getScheduledFeed = (id, p) => getFeed(`scheduled-${id}`, T.SCHEDULED_PROFILE_POSTS, p);

export const getGroupScheduledFeed = (id, p) => getFeed(`scheduled-${id}`, T.SCHEDULED_GROUP_POSTS, p);

export const getEventFeed = (id, p) => getFeed(id, T.EVENT, p);

export const getEventPendingFeed = (id, p) => getFeed(`pending-${id}`, T.EVENT_PENDING, p);

export const getPrivatePostFeed = (id, p) => getFeed(id, T.PRIVACYMAIL, p);

export const getGroupProfileFeed = (userId, groupId, p) => getFeed([userId, groupId].join('-'), T.GROUP_CONTACT, p);

export const getEventProfileFeed = (userId, eventId, p) => getFeed([userId, eventId].join('-'), T.EVENT_CONTACT, p);

export const getWrapperFeed = (id, p) => getFeed(`wrapper-${id}`, T.WRAPPER, p);

export const getDiscoverFeed = (p) => getFeed(T.DISCOVER, T.DISCOVER, p);

export const getOpenProfileFeed = (id, p) => getFeed(id, T.OPEN_PROFILE, p);

export const getOpenPageFeed = (id, p) => getFeed(id, T.OPEN_PAGE, p);

export const getFeedForPost = cond([
  [conforms({ eventId: isString }), (post) => getEventFeed(post.eventId)],
  [conforms({ groupId: isString }), (post) => getGroupFeed(post.groupId)],
  [conforms({ privacymail: isString }), (post) => getPrivatePostFeed(post.threadId)],
  [conforms({ pageId: isString }), (post) => getPageFeed(post.pageId)],
  [stubTrue, () => getMyWorldFeed()],
]);

export const fetchFeedAll = (params = {}) =>
  fetchFeed(
    (p) => FeedAPI.fetchAllFeed(p),
    getAllFeed({ hashtag: params.hashtag, feedInHome: params.feedInHome }),
    params
  );

export const fetchFeedDiscover = (params = {}) =>
  fetchFeed((p) => DiscoverApi.fetchFeed(p), getDiscoverFeed(params), params);

export const fetchFeedOpenProfile = (handle, userId) =>
  fetchFeed((p) => PublicPagesApi.postsfeed(userId), getOpenProfileFeed(handle));

export const fetchFeedContacts = (params = {}, filter = null) =>
  fetchFeed((p) => FeedAPI.fetchContactsFeed(p, filter), getContactsFeed({ hashtag: params.hashtag }), params);

export const fetchFeedGroups = (params = {}) =>
  fetchFeed(
    (p) => FeedAPI.fetchAllGroupsFeed(p),
    getGroupsFeed({ hashtag: params.hashtag, feedInHome: params.feedInHome }),
    params
  );

export const fetchFeedPage = (id, params = {}) =>
  fetchFeed((p) => PagesApi.fetchFeed(id, p), getPageFeed(id, { hashtag: params.hashtag }), params);

export const fetchFeedPagePublic = (uuid, params = {}) =>
  fetchFeed((p) => PublicPagesApi.getPagePublicPostsFeed(uuid, p), getOpenPageFeed(uuid, params));

export const fetchFeedPages = (params = {}) =>
  fetchFeed((p) => PagesApi.fetchFeed(T.PAGES, p), getPagesFeed({ hashtag: params.hashtag }), params);

export const fetchFeedGroup = (id, params = {}) =>
  fetchFeed((p) => FeedAPI.fetchGroupFeed(id, p), getGroupFeed(id, params), params);

export const fetchFeedGroupPending = (id, params = {}) =>
  fetchFeed((p) => FeedAPI.fetchFeedGroupPending(id, p), getGroupPendingFeed(id, params), params);

export const fetchFeedGroupPreview = (id, params = {}) =>
  fetchFeed((p) => FeedAPI.fetchFeedGroupPreview(id, p), getGroupPreviewFeed(id, params), params);

export const fetchFeedProfile = (id, params = {}) =>
  fetchFeed((p) => ContactsApi.getUserFeed(id, p), getProfileFeed(id, params), params);

export const fetchFeedProfileGroup = (userId, groupId, params = {}) =>
  fetchFeed((p) => FeedAPI.fetchMembersFeed(userId, groupId, p), getGroupProfileFeed(userId, groupId, params), params);

export const fetchFeedProfileEvent = (userId, eventId, params = {}) =>
  fetchFeed(
    (p) => FeedAPI.fetchEventMemberFeed(eventId, userId, p),
    getGroupProfileFeed(userId, eventId, params),
    params
  );

export const fetchFeedEvent = (id, params = {}) =>
  fetchFeed((p) => FeedAPI.fetchEventFeed(id, p), getEventFeed(id, { hashtag: params.hashtag }), params);

export const fetchFeedEventPending = (id, params = {}) =>
  fetchFeed((p) => FeedAPI.fetchEventFeedPending(id, p), getEventPendingFeed(id, params), params);

export const fetchFeedPrivatePosts = (id, params = {}) =>
  fetchFeed(
    (p) => FeedAPI.fetchPrivatePosts(Object.assign(p, { threadId: id })),
    getPrivatePostFeed(id, params),
    params
  );

export const fetchFeedScheduled = (id, params = {}) =>
  fetchFeed((p) => FeedAPI.getUserFeedScheduled(p), getScheduledFeed(id, params), params);

export const fetchGroupFeedScheduled = (groupId, params = {}) =>
  fetchFeed((p) => FeedAPI.getGroupFeedScheduled(groupId, p), getGroupScheduledFeed(groupId, params), params);

const wrappersApiCall = (scopeId, id) =>
  scopeId == T.EVENT ? (p) => FeedAPI.fetchEventFeed(id, p) : (p) => HomeApi.getWrapperPosts(scopeId, id, p);

export const fetchFeedWrapper = (id, scopeId, params = {}) =>
  fetchFeed(wrappersApiCall(scopeId, id), getWrapperFeed(id, params), params);

export const getMyWorldFeed = (scope, params = {}) => {
  params.feedInHome = true; // to distinguish groups/pages feed displayed in home location

  switch (scope) {
    case T.CONTACTS:
      return getContactsFeed(params);
    case T.GROUPS:
      return getGroupsFeed(params);
    case T.PAGES:
      return getPagesFeed(params);
    case T.DISCOVER:
      return getDiscoverFeed(params);
    default:
      return getAllFeed(params);
  }
};

export const fetchFeedMyWorld = (scope, params = {}, filter = null) => {
  params.feedInHome = true; // to distinguish groups/pages feed displayed in home location

  switch (scope) {
    case T.CONTACTS:
      return fetchFeedContacts(params, filter);
    case T.GROUPS:
      return fetchFeedGroups(params);
    case T.PAGES:
      return fetchFeedPages(params);
    case T.DISCOVER:
      return fetchFeedDiscover(params, scope);
    default:
      return fetchFeedAll(params);
  }
};

export const checkGroupPendingFeed = (groupId) => {
  FeedAPI.checkGroupPendingFeed(groupId).then((data) => {
    GroupStore.getState({ id: groupId.replace('pending-', '') }).set('hasPendingPosts', data.exists);
  });
};

export const checkGroupScheduledFeed = (groupId) => {
  FeedAPI.checkGroupScheduledFeed(groupId).then((data) => {
    GroupStore.getState({ id: groupId.replace('pending-', '') }).set('hasScheduledPosts', data.exists);
  });
};
