import Ember from 'ember';
import { later } from '@ember/runloop';

import * as DOMPurify from 'dompurify';
import { unescape } from 'lodash';

import FunctionalUtils from 'mewe/shared/functional-utils';
import PS from 'mewe/utils/pubsub';
import { getThemeFromHtmlClass } from 'mewe/utils/color-utils';
import { getTitleCount } from 'mewe/shared/storage';
import { htmlSafe } from '@ember/template';
import MathUtils from 'mewe/utils/math-utils';

import isDefinedFunc from 'mewe/utils/isDefined';
import isUndefinedFunc from 'mewe/utils/isUndefined';

import { isIE, isEdge } from 'mewe/shared/utils';
import themesManifest from 'mewe/utils/rev-manifest.json';

if (!window.Audio) window.Audio = () => {};

export const callingAudio = new Audio('/assets/audio/call.mp3');

const notificationAudio = new Audio('/assets/audio/notification.mp3');

const CONSECUTIVE_MSGS_THRESHOLD = 120; // seconds

/**
 * import MiscellaneousUtils from 'mewe/utils/miscellaneous-utils';
 *
 * e.g.
 * let { isUndefined, isDefined, areMessagesConsecutive } = MiscellaneousUtils;
 */

export const isDefined = isDefinedFunc;

export const isUndefined = isUndefinedFunc;

export function areMessagesConsecutive(msgA, msgB) {
  const areMessagesConsecutive =
    Math.abs(msgA.date - msgB.date) < CONSECUTIVE_MSGS_THRESHOLD &&
    msgA.computedAuthorId === msgB.computedAuthorId &&
    !msgA.chatEventMessage && // SG-30290, SG-32386 consecutive msgs don't apply if one of them is about chat event
    !msgB.chatEventMessage;

  return areMessagesConsecutive;
}

export function trimAndLower(txt = '') {
  return txt.toLowerCase().trim();
}

export function hexToRGB(hex, alpha) {
  if (!hex) return;

  var r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
  } else {
    return 'rgb(' + r + ', ' + g + ', ' + b + ')';
  }
}

export function processCloudStorageData(data = {}) {
  const totalAmount = data.availableTotal;
  const usedAmount = data.used?.total;
  const leftAmount = totalAmount - usedAmount;

  let percentageUsed = Math.round((usedAmount / totalAmount) * 100);
  if (percentageUsed === Infinity || isNaN(percentageUsed)) {
    percentageUsed = 0;
  }

  return {
    totalGB: MathUtils.bytesToGBText(totalAmount),
    usedGB: MathUtils.bytesToGBText(Math.max(0, usedAmount)),
    leftGB: MathUtils.bytesToGBText(Math.max(0, leftAmount)),
    usedPercent: percentageUsed,
    usageBarStyle: htmlSafe(`width: ${percentageUsed}%;`),
  };
}

export default {
  areMessagesConsecutive: areMessagesConsecutive,
  isDefined: isDefined,
  isUndefined: isUndefined,

  err: function (message) {
    throw new Error(message);
  },

  /**
   * it's bad and ugly..., but for now serves as a temporary fix
   * unless BE merges some improvements.
   */
  removeHtmlTagsFromText: function (str) {
    let el = document.createElement('div');
    el.innerHTML = str;

    return el.innerText;
  },

  updateDocumentTitle: function (type, name, doBlink, fileName) {
    let staticTitle = __('MeWe - The Next-Gen Social Network');
    const getTitleWithNotiCountOrStatic = () => {
      let titleCount = getTitleCount();

      if (titleCount > 0) return '(' + (titleCount > 99 ? '99+' : titleCount) + ')' + staticTitle;
      else return staticTitle;
    };

    if (doBlink && getTitleCount() > 0) {
      const textToBlink =
        type === 'message' && name
          ? __('{userName} sent a message.', {
              userName: name,
            })
          : __('New Notification');
      let c = 0;

      if (window.meweTitleInterval) clearInterval(window.meweTitleInterval);

      window.meweTitleInterval = setInterval(function () {
        if (c % 2) {
          document.title = getTitleWithNotiCountOrStatic();
          c++;
        } else {
          document.title = textToBlink;
          c++;
        }

        if (c === 6) clearInterval(window.meweTitleInterval);
      }, 500);
    } else {
      document.title = getTitleWithNotiCountOrStatic();
    }
  },

  playNotificationSound: function () {
    notificationAudio.play();
  },

  copiedToClipboardConfirmation: function () {
    FunctionalUtils.info(__('Copied to the Clipboard'));
  },

  copyToClipboardOld: function (text) {
    var shortcut;
    if (navigator.appVersion.indexOf('Mac') != -1) {
      shortcut = __('Copy to Clipboard: Cmd+C');
    } else {
      shortcut = __('Copy to Clipboard: Ctrl+C');
    }

    // window.prompt(shortcut, text); // we can't use it, default text is not supported e.g. in Safari 8
    import(/* webpackChunkName: 'app.dialog.simple-dialog' */ 'mewe/dialogs/simple-dialog').then((Dialog) => {
      Dialog.default
        .make({
          type: 'alert-simple',
          title: shortcut,
          message: `<textarea class='full'>${text}</textarea>`,
          noEscaping: true,
          width: 555,
          onOpen: function () {
            document.querySelector('.alert-simple-dialog textarea').select();
          },
        })
        .send('open');
    });
  },

  copyToClipboard: function (text) {
    var self = this;

    if (!text) return;

    // For IE.
    if (window.clipboardData) {
      window.clipboardData.setData('Text', text);
      self.copiedToClipboardConfirmation();
    } else {
      // Create a temporary element off screen.
      var tmpElem = document.createElement('textarea');
      tmpElem.style.position = 'absolute';
      tmpElem.style.top = '-1000px';
      tmpElem.style.left = '-1000px';

      document.body.appendChild(tmpElem);

      tmpElem.value = text;

      tmpElem.select();

      try {
        if (document.execCommand('copy', false, null)) {
          self.copiedToClipboardConfirmation();
        } else {
          self.copyToClipboardOld(text);
        }
      } catch (e) {
        self.copyToClipboardOld(text);
      }

      document.body.removeChild(tmpElem);
    }
  },

  deleteCookie: function (name) {
    document.cookie = name + '=; Expires=Thu, 01 Jan 1970 00:00:01 GMT;'; // localhost version
    document.cookie =
      name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;domain=' + window.location.hostname + ';';
  },

  hexToRgb: function (hex, alpha) {
    var num = parseInt(hex.slice(1, hex.length), 16),
      r = (num >> 16) & 255,
      g = (num >> 8) & 255,
      b = num & 255;

    return alpha ? 'rgba(' + [r, g, b, alpha].join() + ')' : 'rgb(' + [r, g, b].join() + ')';
  },

  tillFirstSpace: function (str) {
    if (str.indexOf(' ') === -1) {
      return str;
    } else {
      return str.slice(0, str.indexOf(' '));
    }
  },

  // https://github.com/muaz-khan/DetectRTC/blob/master/DetectRTC.js
  isWebRTCSupported: function () {
    if (isIE() || isEdge()) return false;

    var isWebRTCSupported = false;
    ['RTCPeerConnection', 'webkitRTCPeerConnection', 'mozRTCPeerConnection', 'RTCIceGatherer'].forEach(function (item) {
      if (isWebRTCSupported) {
        return;
      }

      if (item in window) {
        isWebRTCSupported = true;
      }
    });

    return isWebRTCSupported;
  },
};

export const setTheme = (themeName, doAnimate) => {
  themeName = themeName || 'mewe';

  const currentTheme = getThemeFromHtmlClass();
  const $customThemeCss = document.getElementById('custom-theme-styles-head');
  const themeClass = `theme-${themeName}`;
  const themesSource = {};

  Object.keys(themesManifest).map(function (key) {
    themesSource[key.replace('.css', '')] = '/assets/themes/' + themesManifest[key];
  });

  let isThemeLoaded = themeName === 'mewe', // default theme doesn't need to be loaded
    isAnimationDone = false;

  if (themeClass !== 'theme-mewe' && !themesSource[themeClass]) return;

  if (currentTheme === themeName) return;

  // theme switch after css is loaded firstly
  const switchTheme = () => {
    // remove previous theme class and css file from DOM if exists already
    if ($customThemeCss) {
      $customThemeCss.remove();
    }

    document.querySelector('html').classList.remove(`theme-${currentTheme}`);
    document.querySelector('html').classList.add(`${themeClass}`);

    PS.Pub('theme.changed', themeName);

    // delay moving body up for loading effect
    later(() => {
      document.querySelector('html').classList.remove('switching-theme');
    }, 800);
  };

  // slide down animation which lasts 1s, only when user changed theme (not on initial load)
  if (doAnimate) {
    window.scroll(0, 0); // scroll before animation to avoid problems
    document.querySelector('html').classList.add('switching-theme');
  } else {
    switchTheme();
  }

  // css needs to be loaded only for themes other than default
  if (themeName !== 'mewe') {
    const url = themesSource[themeClass];

    let link = document.createElement('link');
    link.id = 'custom-theme-styles-head';
    link.rel = 'stylesheet';
    link.type = 'text/css';
    link.href = `${url}`;
    link.onload = () => {
      // if not user action then switch was done right away
      if (doAnimate) {
        // switch is done after loading style but no sooner than after 1s to see whole animation
        if (isAnimationDone) switchTheme();
        else isThemeLoaded = true;
      }
    };

    // prepend (NOT append) so new styles have higher priority than existing
    document.querySelector('head').prepend(link);
  }

  // timeout set to same time as duration of slide down animation
  // meanwhile css is loaded and then theme class is added and slide up animation done
  later(() => {
    if (isThemeLoaded) switchTheme();
    else isAnimationDone = true;
  }, 800);
};

export const isValidUrlParam = (text) => {
  text = encodeURIComponent(text);
  let url;
  try {
    url = new URL(`${location.origin}/${text}`);
  } catch {}

  return url && url.href && url.href.replace(location.origin, '').indexOf(text) !== -1;
};

export const purifyHtmlString = (htmlString) => {
  return DOMPurify.sanitize(unescape(htmlString));
};

export const getWalletHost = () => {
  if (~location.host.indexOf('mewe.com')) {
    return `https://www.amplicaaccess.com`;
  } else if (~location.host.indexOf('qa-groupl.es')) {
    return `https://testnet.amplicaaccess.com`;
  } else {
    return `https://dev-custodial-wallet.liberti.social`;
  }
};
