import Route from 'mewe/routes/route';
import AccountApi from 'mewe/api/account-api';
import ContactsApi from 'mewe/api/contacts-api';
import GroupApi from 'mewe/api/group-api';
import EventsApi from 'mewe/api/events-api';
import PublicPagesApi from 'mewe/api/public-pages-api-unauth';
import { later } from '@ember/runloop';
import { isMobile } from 'mewe/shared/utils';
import { afterEmailConfirmation } from 'mewe/shared/registration';
import { getQueryStringParams } from 'mewe/shared/utils';
import { useFeatureHint } from 'mewe/utils/feature-hint';
import { unescape } from 'lodash';

export default class ConfirmAccountRoute extends Route {
  isMobile = isMobile();

  model(params) {
    this.confirmAccount(params.id);
  }

  confirmAccount(confirmationId) {
    AccountApi.confirmAccount(confirmationId)
      .then((data) => {
        afterEmailConfirmation(data);

        this.user = data.user;
        this.params = getQueryStringParams();

        // timeout to have some time to run afterEmailConfirmation function
        later(
          this,
          () => {
            const redirectionType = Object.keys(this.params)[0]?.toLowerCase() || 'default';
            useFeatureHint('new-registration');
            AccountApi.checkHint({ hint: 'new-registration' });
            this.redirectToApp(redirectionType);
          },
          1000
        );
      })
      .catch(() => (window.location = '/myworld'));
  }

  redirectToApp(type) {
    switch (type) {
      case 'pageurlid':
        this.redirectToPage(this.params.pageUrlId);
        break;

      case 'groupid':
        this.redirectToGroup(this.params.groupId);
        break;

      case 'eventid':
        this.redirectToEvent(this.params.eventId);
        break;

      default:
        this.defaultRedirect();
        break;
    }
  }

  redirectFtue() {
    if (this.isMobile) {
      window.location = `/congratulations/normal/${encodeURIComponent(unescape(this.user.firstName))}`;
    } else {
      window.location = '/myworld#ftue';
    }
  }

  redirectToPage(pageUrlId) {
    if (this.isMobile) {
      PublicPagesApi.getPage(pageUrlId)
        .then((data) => (window.location = `/congratulations/page/${encodeURIComponent(unescape(data.page.name))}`))
        .catch(this.redirectFtue);
    } else {
      window.location = `/p/${pageUrlId}`;
    }
  }

  redirectToEvent(eventUrlId) {
    if (this.isMobile) {
      EventsApi.getEventData(eventUrlId)
        .then((data) => (window.location = `/congratulations/event/${encodeURIComponent(unescape(data.event.name))}`))
        .catch(this.redirectFtue);
    } else {
      window.location = `/event/${eventUrlId}`;
    }
  }

  // when user joined a group, redirect to group, otherwise to FTUE dialog on myworld
  redirectToGroup(groupId) {
    const groupType = this.params.groupType.toLowerCase();

    if (this.isMobile) {
      if (groupType == 'publicapply') {
        window.location = '/congratulations/application/success';
        return;
      }

      GroupApi.fetch()
        .then((data) => {
          const group = data.confirmedGroups?.find((g) => !g.isUniversal);

          if (group) {
            // isConfirmed if user was invited before registration, so he didn't apply but accepted invitation
            if (group.isPublicApply && !group.isConfirmed) {
              window.location = `/congratulations/application/${group.publicUrlId}`;
            } else if (group.isPublic) {
              window.location = `/congratulations/public/${group.publicUrlId}`;
            } else {
              window.location = `/congratulations/private/${encodeURIComponent(unescape(group.name))}`;
            }
          } else {
            this.redirectFtue();
          }
        })
        .catch(this.redirectFtue);
    } else {
      switch (groupType) {
        case 'public':
          // window.location = `/group/${groupId}?isFirstGroupOpening=true`;
          // isFirstGroupOpening param is non needed as log as we have ftue-premium-dialog which shows group-initialisation on 'close'
          window.location = `/group/${groupId}`;
          break;

        case 'publicapply':
          window.location = '/myworld#application';
          break;

        default:
          this.redirectFtue();
          break;
      }
    }
  }

  defaultRedirect() {
    if (this.isMobile) {
      ContactsApi.list()
        .then((data) => {
          if (data.contacts?.length) {
            window.location = `/congratulations/contact/${encodeURIComponent(unescape(data.contacts[0].user?.name))}`;
          } else {
            this.redirectFtue();
          }
        })
        .catch(this.redirectFtue);
    } else {
      // if user registered on a Public Invitation page then we want to redirect him to that profile right after confirming his account
      // we check if that is the case by checking user's invitations list if there is an user invited during the registration process
      ContactsApi.invited()
        .then((data) => {
          const invitation = data.contacts?.[0] || null;

          if (invitation?.invitee?.contactInviteId) {
            window.location = `/i/${invitation.invitee?.contactInviteId}`;
          } else {
            this.redirectFtue();
          }
        })
        .catch(this.redirectFtue);
    }
  }
}
