import { get } from '@ember/object';
import { Theme, FeedTypes } from 'mewe/constants';

const getPostScopeAndId = (post) => {
  let res = { scope: null, scopeId: null };

  if (!post) return res;

  if (get(post, 'eventId')) {
    // post with eventId is a post inside event, post with eventInfo is a post about created event (and still can have groupId or be in home feed)
    res.scope = Theme.EVENT;
    res.scopeId = get(post, 'eventId');
  } else if (get(post, 'groupId') && get(post, 'groupId') !== Theme.CONTACTS) {
    res.scope = Theme.GROUP;
    res.scopeId = get(post, 'groupId');
  } else if (get(post, 'privacymail') || get(post, 'threadId')) {
    res.scope = Theme.PRIVATEPOSTS;
    res.scopeId = get(post, 'threadId');
  } else if (get(post, 'pageId')) {
    res.scope = Theme.PAGE;
    res.scopeId = get(post, 'pageId');
  } else {
    res.scope = Theme.CONTACTS;
    res.scopeId = Theme.CONTACTS;
  }

  return res;
};

const getHomeAggregatedFeed = (post) => {
  const { scope } = getPostScopeAndId(post);

  if (scope === Theme.GROUP) return FeedTypes.GROUPS;
  if (scope === Theme.PAGE) return FeedTypes.PAGES;

  return FeedTypes.CONTACTS;
};

export const getPostTheme = (post, theme) =>
  [FeedTypes.ALL, FeedTypes.DISCOVER].includes(theme) ? getPostScopeAndId(post).scope : theme;

export default {
  getPostScopeAndId: getPostScopeAndId,
  getHomeAggregatedFeed: getHomeAggregatedFeed,
};
