import Service from '@ember/service';
import { setComponentTemplate } from '@ember/component';
import { getOwner } from '@ember/application';
import { tracked } from '@glimmer/tracking';
import { A } from '@ember/array';
export default class DynamicPopupsService extends Service {
  @tracked components = A();

  async openPopup(popupName, args = {}) {
    const owner = getOwner(this);
    const factory = owner.factoryFor(`component:${popupName}`);
    if (!factory) {
      let [template, module] = await Promise.all([
        import(`../pods/components/dropdowns/${popupName}/template.hbs`),
        import(`../pods/components/dropdowns/${popupName}`),
      ]);
      setComponentTemplate(template.default, module.default);
      owner.register(`component:${popupName}`, module.default);
    }
    this.components.pushObject({
      name: popupName,
      args,
    });
  }
}
