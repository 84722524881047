export default class RequestError extends Error {
  constructor(message, options) {
    super(message, options);

    let r = options?.cause?.response;

    this.data = r.data;
    this.status = r?.status;
    this.statusText = r?.statusText;
    this.headers = r?.headers;
    this.config = r?.config;
    this.request = r?.request;

    this.name = 'RequestError';
  }
}
