import { get } from '@ember/object';
import Ember from 'ember';
import Component from '@ember/component';
import { alias } from '@ember/object/computed';
import { each, matches } from 'lodash';

import Controller from 'mewe/action-controllers/action-controller';
import PS from 'mewe/utils/pubsub';
import GS from 'mewe/stores/group-store';
import Comment from 'mewe/stores/models/comment-model';
import JSONSerializer from 'mewe/utils/store-utils/serializers/json-serializer';
import Verbose from 'mewe/utils/verbose';
import { newCommentsVisible } from 'mewe/constants';
import FeedUtils from 'mewe/utils/feed-utils';

const S = JSONSerializer.create();

const verbose = Verbose({ prefix: '[Comment-WS]', color: 'navy', enabled: false }).log;

const isEOwner = (uid, E) => E.get('owner.id') === uid;
const isOwner = (uid, obj) => get(obj, 'owner.id') === uid;
const isCommentOwner = (uid, postE, comment) => isOwner(uid, comment) || isEOwner(uid, postE);
const isPageSuperuser = (postE, comment) => {
  return comment.postedByPage && postE.get('page.isOwnerAdmin');
};
const findComment = (postE, commentId) => (postE ? postE.get('comments.feed').findBy('id', commentId) : null);
const findReply = (postE, commentId, replyId) => {
  let comment = findComment(postE, commentId);
  return comment ? comment.replies.findBy('id', replyId) : null;
};
const findNotSaved = (collection, commentE, props) =>
  collection.filterBy('notSaved', true).find((E) => matches(commentE.getProperties(props))(E.getProperties(props)));

const getGroupPermissions = ({ comment, postE, groupE, uid }) => {
  const currentUserIsGroupOwner = isEOwner(uid, groupE);
  const commentAuthorIsGroupOwner = isEOwner(comment.owner.id, groupE);

  return {
    canRemove: groupE.isConfirmed
      ? isCommentOwner(uid, postE, comment) ||
        currentUserIsGroupOwner ||
        (groupE.isOwnerAdmin && !commentAuthorIsGroupOwner)
      : false,
    canEmojify: groupE.isConfirmed ? true : false,
  };
};

const createComment = ({ comment, postE, groupE, uid }) => {
  return Object.assign(
    {},
    comment,
    {
      isNew: true,
      canEdit: isOwner(uid, comment) || isPageSuperuser(postE, comment),
      follows: isOwner(uid, comment),
    },
    groupE && comment.system === 'group'
      ? getGroupPermissions({ comment, postE, groupE, uid })
      : { canRemove: isCommentOwner(uid, postE, comment) || isPageSuperuser(postE, comment) }
  );
};

const wrap = (cx) => (fn) => (data) => {
  let posts = FeedUtils.matchPostsFromAllFeeds(data.postItemId);

  posts.forEach((postE) => fn.call(cx, data, postE));
};

export default Controller.extend({
  uid: alias('globals.currentUser.id'),

  init() {
    this._super();

    let forEachPost = wrap(this),
      doWithFoundComment = (fn) => (data) => {
        let posts = FeedUtils.matchPostsFromAllFeeds(data.postItemId);

        posts.forEach((post) => {
          let foundComment = get(data, 'replyTo')
            ? findReply(post, get(data, 'replyTo'), get(data, 'id'))
            : findComment(post, get(data, 'id'));
          if (foundComment) fn.call(this, foundComment, post, data);
        });
      };

    PS.Sub('comment.add', forEachPost(this.addCommentOrReply));
    PS.Sub('comment.remove', forEachPost(this.remove));
    PS.Sub('comment.edit', forEachPost(this.edit));
    PS.Sub('comment.emoji.add', doWithFoundComment(this.emojiAdd));
    PS.Sub('comment.emoji.remove', doWithFoundComment(this.emojiRemove));
    PS.Sub('comment.link.updated', doWithFoundComment(this.updateCommentLink));
  },

  addCommentOrReply(comment, postE) {
    return get(comment, 'replyTo') ? this.addReply(comment, postE) : this.addComment(comment, postE);
  },

  addComment(data, postE) {
    verbose('addComment - ws data:', data);

    if (findComment(postE, data.id)) return;

    let comment = createComment({ comment: data, postE, groupE: GS.getState({ id: data.groupId }), uid: this.uid });
    let commentE = S.deserializeOne(Comment, comment);
    let commentsE = postE.get('comments.feed');

    verbose('addComment - comment:', comment);

    if (!findNotSaved(commentsE, commentE, ['textDisplay', 'postItemId', 'owner.id'])) {
      Ember.beginPropertyChanges();

      commentE.set('hideNew', postE.get('comments.newCommentsCount') >= newCommentsVisible);

      commentsE.pushObject(commentE);
      postE.incrementProperty('comments.total');
      postE.incrementProperty('comments.newCommentsCount');

      Ember.endPropertyChanges();
    }
  },

  addReply(data, postE) {
    verbose('addReply: ', data);

    const commentE = findComment(postE, data.replyTo);

    if (!commentE) return;

    let replyAlreadyExists = commentE.get('replies.length') && commentE.replies.findBy('id', data.id);

    if (replyAlreadyExists) return;

    let reply = createComment({ comment: data, postE, groupE: GS.getState({ id: data.groupId }), uid: this.uid });
    let replyE = S.deserializeOne(Comment, reply);
    let repliesE = commentE.replies;

    if (!findNotSaved(repliesE, replyE, ['textDisplay', 'replyTo', 'owner.id'])) {
      repliesE.pushObject(replyE);
      commentE.incrementProperty('repliesCount');
    }
  },

  remove(comment, postE) {
    verbose('remove');
    if (!comment || !postE) return;

    let commentE = findComment(postE, get(comment, 'replyTo') || comment.id);
    if (!commentE) return;

    if (get(comment, 'replyTo')) {
      let replyE = findReply(postE, get(comment, 'replyTo'), comment.id);

      if (replyE) {
        commentE.replies.removeObject(replyE);

        if (commentE?.repliesCount) {
          commentE.decrementProperty('repliesCount');
        }
      }
    } else {
      postE.get('comments.feed').removeObject(commentE);

      if (postE.comments?.total) {
        postE.decrementProperty('comments.total');
      }
    }
  },

  edit(comment, postE) {
    verbose('edit');

    let commentE = get(comment, 'replyTo')
      ? findReply(postE, get(comment, 'replyTo'), comment.id)
      : findComment(postE, comment.id);

    if (!postE || !commentE) return;

    commentE.setProperties({
      textServer: comment.newText,
      editedAt: comment.editedTime / 1000,
    });
  },

  emojiAdd(comment, postE, data) {
    each(data.emojis, (e) => {
      this.dispatch('emojify', 'addEmoji', comment, e, data.userId);
    });
  },

  emojiRemove(comment, postE, data) {
    each(data.emojis, (e) => {
      this.dispatch('emojify', 'removeEmoji', comment, e, data.userId);
    });
  },

  updateCommentLink(comment, postE, data) {
    comment.setProperties({
      'link._links.thumbnail': { href: data.photoUrl },
      'link.thumbnailSize': data.size,
    });
  },
});
