/**
 * import FunctionalUtils from 'mewe/shared/functional-utils';
 */
import { escape } from 'lodash';

export default {
  defaultErrorMessage: () => __(`We're sorry, something seems to have gone wrong. Please try again soon.`),

  message(message, msgType, action, options = {}) {
    let duration = options.duration || 3000;
    let newMess = '<ul>';

    if (action) {
      newMess += '<li>' + message + '</li>';
    } else {
      if (message.constructor == Array) {
        for (var i = 0; i < message.length; i++) {
          newMess += '<li>' + escape(message[i]) + '</li>';
        }
      } else {
        newMess += '<li>' + escape(message) + '</li>';
      }
    }

    newMess += '<svg class="svg_cancel p-absolute right cursor-pointer"><use xlink:href="#svg_cancel"></use></svg>';
    newMess += '</ul>';

    const existingNotice = document.querySelector('#notices-container');
    if (existingNotice) existingNotice.remove();

    const noticeWrapper = document.createElement('div');
    noticeWrapper.id = 'notices-container';

    const noticeMsg = document.createElement('div');
    noticeMsg.id = 'notices-msg';
    noticeMsg.classList = msgType;
    noticeMsg.innerHTML = newMess;

    noticeWrapper.appendChild(noticeMsg);
    document.body.appendChild(noticeWrapper);

    const noticeCloseBtn = document.querySelector('#notices-container .svg_cancel');
    if (noticeCloseBtn)
      noticeCloseBtn.addEventListener('click', () => {
        this.hideMessage();
      });

    window.clearTimeout(window.popupMsgTimeout);

    window.popupMsgTimeout = setTimeout(() => {
      this.hideMessage();
    }, duration);
  },

  hideMessage() {
    // just in case there were multiple messages for some reson we need to handle them all
    const noticeMsg = document.querySelectorAll(`#notices-msg`);
    noticeMsg.forEach((el) => {
      el.classList.add('fade-out');
    });

    // fade-out effect lasts for 1s
    setTimeout(() => {
      noticeMsg.forEach((el) => {
        const parent = el.parentNode;
        if (parent) parent.remove();
      });
    }, 1000);
  },

  error(message) {
    this.message(message, 'error');
  },

  info(message) {
    this.message(message, 'info');
  },

  notice(message) {
    this.message(message, 'notice');
  },

  err(message) {
    throw new Error(message);
  },

  handleGroupJoiningError(data) {
    switch (data.errorCode) {
      case 624:
        this.error(__('Sorry - you’re not allowed to join this group'));
        break;
      default:
        this.error(this.defaultErrorMessage());
        break;
    }
  },

  showDefaultErrorMessage() {
    this.error(this.defaultErrorMessage());
  },

  showLimitedPostingNotice() {
    this.message(
      __(
        `To keep MeWe's open groups safe from spammers, new members are not permitted to share links or images in open groups until after the first day they join. Thanks for your patience and understanding!`
      ),
      'notice',
      null,
      { duration: 15000 }
    );
  },

  showErrorRetryPost(action, options) {
    this.message(
      __(
        `This post could not be shared. Check your internet connection and <u id='error-action'>click to try again.</u>`
      ),
      'error',
      action,
      options
    );
  },

  showErrorRetryMessage(action, options) {
    this.message(
      __(`This message didn't send. Check your internet connection and <u id='error-action'>click to try again.</u>`),
      'error',
      action,
      options
    );
  },

  showErrorMaxCharacterLength() {
    this.error(__('Sorry, you have exceeded the character limit'));
  },
};
