import Service from '@ember/service';
import StoryblokClient from 'storyblok-js-client';
import i18n from 'i18next';

export default class StoryblokService extends Service {
  config = {
    region: 'us',
    accessToken: 'WvpMvnI0MDS4VQc3ag1jfgtt', // TOOD: make env vatiable
  };

  storyblok = new StoryblokClient(this.config);

  richTextResolver = this.storyblok.richTextResolver;

  async loadStory(path) {
    try {
      const response = await this.storyblok.get(`cdn/stories/${path}`, {
        version: 'draft',
        language: i18n.language,
      });
      return response.data.story;
    } catch (err) {
      console.log(err); // eslint-disable-line
    }
  }
}
