import ApiUtil from './api';

class Api extends ApiUtil {
  constructor() {
    super();

    this.unsubscribe = {
      groupInvitation: (id) => {
        return this.post('/account/group-invite-unsubscribe/' + id);
      },

      contactInvitation: (id) => {
        return this.post('/account/contact-invite-unsubscribe/' + id);
      },

      newsletter: (id) => {
        return this.post('/account/newsletter-unsubscribe/' + id);
      },

      emailNotifications: (id) => {
        return this.post('/account/notifications-unsubscribe/' + id);
      },
    };
  }

  contact(publicId) {
    return this.get(`/public/${publicId}`, null, 'apiCacheRoot');
  }

  postsfeed(publicId) {
    return this.get(`/home/user/${publicId}/postsfeed/public`, null, 'apiCacheRoot');
  }

  group(publicId) {
    return this.get('/group/public/' + publicId);
  }

  groupById(groupId) {
    return this.get('/group/publicById/' + groupId);
  }

  getEvent(publicId) {
    return this.get('/event2/public/' + publicId);
  }

  getEventById(eventId) {
    return this.get('/event2/publicById/' + eventId);
  }

  getPage(publicId) {
    return this.get(`/pages/page/${publicId}/public`);
  }

  getPagePublicPostsFeed(uuid, params) {
    return this.getNextPageOr(`/pages/page/${uuid}/postsfeed`, params, 'apiCacheRoot');
  }

  getPageHashTags(params, pageId) {
    const headers = { data: params };
    return this.get(`/pages/page/${pageId}/hashtags`, headers, 'apiCacheRoot');
  }

  checkEmailAvailability(email) {
    return this.get('/auth/checkEmail?email=' + encodeURIComponent(email.toLowerCase()));
  }

  validatePhone(params, traceId = '') {
    return this.post('/validation/phone/send', {
      data: JSON.stringify(params),
      traceId,
    });
  }

  registerAccount(params, traceId = '') {
    return this.post(
      '/auth/register',
      {
        data: JSON.stringify(params),
        traceId,
      },
      'apiRoot3'
    );
  }

  inviteeData(mergeId) {
    return this.post(`/mycontacts/mail-invite-confirm/${mergeId}`);
  }

  inviterData(invitationId) {
    return this.get('/mycontacts/inviter/' + invitationId);
  }

  checkAccountByEmail(email) {
    return this.get(`/auth/check/user/email?platform=web&email=${encodeURIComponent(email.toLowerCase())}`);
  }

  checkAccountByPhone(code, phone) {
    return this.get(`/auth/check/user/phone?countryCode=${encodeURIComponent(code)}&phone=${phone}`);
  }

  checkHandleWithEmail(params, traceId = '') {
    params.platform = 'web';

    return this.get(`/dsnp/signup/email/payload?${this.toUrlParams(params)}`, {
      non401: true,
      traceId,
    });
  }

  checkHandleWithPhone(params, traceId = '') {
    return this.get(`/dsnp/signup/phone/payload?${this.toUrlParams(params)}`, {
      non401: true,
      traceId,
    });
  }

  sendDsnpRegistrationSms(params, traceId = '') {
    return this.post(`/validation/phone/dsnp/signup`, {
      data: JSON.stringify(params),
      traceId,
    });
  }

  sendDsnpLoginSms(params, traceId = '') {
    return this.post(`/validation/phone/dsnp/login`, {
      data: JSON.stringify(params),
      traceId,
    });
  }

  getMigrationPayload(handle, traceId = '') {
    return this.get(`/dsnp/migrate/payload?handle=${handle}`, { traceId });
  }

  confirmMigration(params, traceId = '') {
    return this.post(`/dsnp/migrate`, {
      data: JSON.stringify(params),
      traceId,
    });
  }

  getRegistrationInfo() {
    return this.get(`/registration/info`);
  }
}

export default new Api();
