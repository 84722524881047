import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import PublicPagesApi from 'mewe/api/public-pages-api-unauth';
import Verbose from 'mewe/utils/verbose';

const verbose = Verbose({ prefix: '[Signup/config]', color: 'orange', enabled: true }).log;

export default class MwHomeRegistrationPageComponent extends Component {
  @service router;
  @service analytics;

  @tracked content = this.args.model?.content;
  @tracked authMode = null; // "mewe" / "dsnp_amplica"
  @tracked useSmsV2Flow = false; // true / false
  @tracked configLoaded = false;

  constructor() {
    super(...arguments);

    this.storyblokBridge = new window.StoryblokBridge();

    this.storyblokBridge.on('input', (e) => {
      this.content = e?.story.content;
    });

    PublicPagesApi.getRegistrationInfo()
      .then((res) => {
        this.setAuthMode(res.defaultRegistrationMode);
        this.setSmsVersion(res.useSmsV2Flow);
      })
      .catch(() => {
        this.setAuthMode('mewe'); // fallback to mewe just in case
        this.setSmsVersion(false); // fallback to mewe just in case
      })
      .finally(() => {
        this.configLoaded = true;

        verbose(`mode=${this.authMode}, smsV2=${this.useSmsV2Flow}`);
      });
  }

  setAuthMode(valueFromBe) {
    // registration mode can be forced by passing from parent component (e.g. on /web3 page)
    if (this.args.authMode) {
      this.authMode = this.args.authMode;
    }
    // registration mode can be forced by passing hash "mode" in url
    else if (~window.location.hash.indexOf('mode=')) {
      const mode = window.location.hash.slice('mode='.length + 1);
      if (mode) this.authMode = mode;
    }
    // only if there is no value from parent and no forced mode from url, then use BE value
    else {
      this.authMode = valueFromBe || 'mewe'; // fallback to mewe just in case
    }
  }

  setSmsVersion(valueFromBe) {
    if (~window.location.hash.indexOf('smsV2=')) {
      const smsV2 = window.location.hash.slice('smsV2='.length + 1);
      this.useSmsV2Flow = smsV2 === 'true';
    }
    // only if there is no forced version from url, then use BE value
    else {
      this.useSmsV2Flow = valueFromBe || false; // fallback to v1 just in case
    }
  }
}
