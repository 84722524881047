import EmberObject from '@ember/object';
import Ember from 'ember';
import UserCurrent from 'mewe/stores/models/user-current-model';
import storage from 'mewe/shared/storage';
import { getStorageCurrentUser } from 'mewe/shared/storage';
import config from 'mewe/config';
import cookie from 'mewe/shared/cookie';
import dispatcher from 'mewe/dispatcher';
import { browserTzId } from 'mewe/utils/datetime-utils';
import { isObject } from 'lodash';

let state = UserCurrent.create({ isFetching: true });

if (config.environment != 'prod') {
  var data = (window.Data = window.Data || {});
  data.CurrentUser = state;
}

const self = EmberObject.extend(Ember.ActionHandler, {
  getCacheNotification: function () {
    let cacheNotifications = storage.get(storage.keys.notifications);

    if (cacheNotifications) {
      try {
        let notifications = JSON.parse(cacheNotifications);
        if (isObject(notifications)) {
          return notifications;
        } else {
          storage.remove(storage.keys.notifications);
        }
      } catch (e) {
        storage.remove(storage.keys.notifications);
      }
    }

    return null;
  },

  init() {
    if (config.testing) {
      this.send('handle', {
        id: '525c8e4fe4b0cfcd837583a6',
        publicLinkId: 'kajo.marton',
        firstName: 'Kajom',
        fprint: 'F116391112905013NHMH',
        lastName: 'Marton',
        _links: {
          avatar: {
            href: '/api/v2/photo/profile/{imageSize}/525c8e4fe4b0cfcd837583a6?group=&f=F991770575197MBK5PT',
            templated: true,
          },
        },
        profileImageId: '53a1f69ee4b08dbfe723510d',
        timezone: 'Europe/Oslo',
        locale: 'en',
      });
      return;
    }

    let cacheUser = getStorageCurrentUser();

    if (cacheUser) {
      this.send('handle', cacheUser);
    } else {
      cookie.remove(storage.keys.currentUser);
      storage.remove(storage.keys.currentUser);

      this.send('handle', {}); // store is in fact created in handler based on team
    }

    // more reliable without teardown
    window.addEventListener('storage', (ev) => {
      const wasLoggedOut = ev && ev.key === storage.keys.logout && ev.newValue;

      if (wasLoggedOut) {
        window.setTimeout(() => {
          window.location.reload(true);
        }, 0);
      }
    });
  },

  getState() {
    return state;
  },

  actions: {
    handle: function (res, shouldResolve) {
      Ember.beginPropertyChanges();

      const browserTz = browserTzId();

      if (res.timezone && res.timezone !== browserTz) {
        dispatcher.dispatch('client-data', 'saveTimezone', browserTz);
        res.timezone = browserTz;
      }

      // if (storage.get(storage.keys.developerTimezone)) {
      //   res.timezone = storage.get(storage.keys.developerTimezone);
      // }

      const setNotificationsSettingsFromLS = (state) => {
        const cacheNotifications = this.getCacheNotification();
        if (cacheNotifications && cacheNotifications[res.id]) {
          state.notifications.setProperties(cacheNotifications[res.id]);
        }
      };

      // saving curent user (or other client data like settings)
      state.setProperties(res);

      storage.set(storage.keys.currentUser, state.toJson());
      setNotificationsSettingsFromLS(state);

      // shouldResolve should be passed when currentUser is sent to handle (response from /login or /me/info)
      // we have also other situations when 'handle' action is called (saving currentUser data)
      // and we don't want to resolve state.promise in those cases
      if (shouldResolve) {
        state.deferred.resolve();
        state.set('isFetching', false);
      }

      Ember.endPropertyChanges();

      if (storage.get(storage.keys.registration)) {
        // timeout because there will be missing ctx
        setTimeout(() => {
          dispatcher.dispatch('notification', 'turnOffShort');
        }, 500);
      }
    },
  },
});

export default self.create();
