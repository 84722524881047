import { registerDeprecationHandler } from '@ember/debug';

// Disabling some deprecations
export function initialize() {
  registerDeprecationHandler((message, options, next) => {
    if (!window.deprecationsCount) {
      window.deprecationsCount = {};
    }

    if (window.deprecationsCount[options.id]) {
      window.deprecationsCount[options.id] += 1;
    } else {
      window.deprecationsCount[options.id] = 1;
      console.warn(message);
    }

    // missing 'this' context for property in template
    // those should be more visible to be fixed immediately
    if (options.id === 'this-property-fallback') {
      console.error(message);
    }
  });
}

export default { initialize };
