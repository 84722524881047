/* eslint-disable lines-between-class-members */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { later } from '@ember/runloop';

import { getQueryStringParams, toUrlParams } from 'mewe/shared/utils';
import CurrentUserStore from 'mewe/stores/current-user-store';
import tokenManager from 'mewe/shared/token-manager';
import axios from 'axios';
import { isInJail, isLocked } from 'mewe/utils/jail-utils';
import Storage from 'mewe/shared/storage';
import * as Sentry from '@sentry/ember';
import { isMobile } from 'mewe/shared/utils';

export default class MwDsnpLoginConfirmComponent extends Component {
  @service router;
  @service analytics;
  @service dynamicDialogs;

  @tracked sameBrowserError = false;
  @tracked requestFailed = false;

  constructor() {
    super(...arguments);

    this.urlParams = getQueryStringParams();

    const platform = this.urlParams?.platform;

    if ((platform === 'ios' || platform === 'android') && isMobile()) {
      this.showAppDialog();

      // we want to trigger this only once, that's why it's done
      // here in constructor instead of showAppDialog function
      this.analytics.sendEvent('popupViewed', 'Confirmation Link Redirect');
    } else {
      this.doLogin();
    }
  }

  showAppDialog() {
    this.dynamicDialogs.openDialog('simple-dialog-new', {
      closeable: false, // can be closed by "cancel" button only
      confirmBtnText: __('Open MeWe app'),
      cancelBtnText: __('Use browser'),
      message: __(`We recommend using the MeWe app on your mobile device. If you have it installed the button below will open it for you.`),
      onConfirm: () => {
        window.location = `mewe://${window.location.host}${window.location.pathname}${window.location.search}`;
        // confirm button closes the popup. In case the app is not installed we show the popup again so that user can try browser option
        setTimeout(() => this.showAppDialog(), 2000);

        if (!this.redirectAppTracked) {
          this.analytics.sendEvent('buttonClicked', 'Confirmation Link Redirect - app');
          this.redirectAppTracked = true; // we want to track this only once
        }
      },
      onClose: () => {
        if (!this.redirectBrowserTracked) {
          this.analytics.sendEvent('buttonClicked', 'Confirmation Link Redirect - browser');
          this.redirectAppTracked = true; // we want to track this only once
        }

        this.doLogin();
      }
    });
  }

  doLogin() {
    const dsnpAuthDataRaw = Storage.get(Storage.keys.dsnpAuthData);

    let { token, sessionId, email, trc, t } = this.urlParams;

    if (!email) {
      if (dsnpAuthDataRaw) {
        const dsnpAuthData = JSON.parse(dsnpAuthDataRaw);
        email = dsnpAuthData.email;
      }
    }

    // state info for Sentry in case of errors in the process
    let errorInfo = {
      queryParams: window.location.search,
      storageData: dsnpAuthDataRaw,
      missingEmail: !email,
      missingTraceId: !trc,
      missingSessionId: !sessionId,
      missingCode: !token,
    };

    if (!email || !token || !sessionId) {
      Sentry.captureException(new Error(`Web3 login email confirmation - missing params.`, errorInfo));
      this.sameBrowserError = true;
      return;
    }

    axios({
      url: '/api/v3/auth/login',
      method: 'POST',
      headers: {
        'content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
        'X-Trace-Id': trc,
      },
      data: toUrlParams({
        username: email,
        sessionId: sessionId,
        code: token,
        t: t || Date.now(),
      }),
    })
      .then((res) => {
        Storage.remove(Storage.keys.dsnpAuthData);

        let response = res.data;
        tokenManager.set(response, false);
        CurrentUserStore.send('handle', response.user, true);

        this.analytics.sendEvent('loggedIn', {
          // `is_web3` is superproperty set before sending each event in the service,
          // but for `loggedIn` it has to be set here because service won't know yet if user is DSNP
          is_web3: true,
          login_type: 'email',
        });

        // postponed to allow analytics to record events before reload,
        // this can be removed once we redirect without reloading page
        later(
          this,
          () => {
            CurrentUserStore.getState().deferred.promise.then(() => {
              if (isInJail(response)) {
                this.router.transitionTo('lockout');
              } else if (isLocked(response)) {
                this.router.transitionTo('verify');
              } else {
                window.location = '/';
              }
            });
          },
          1000
        );
      })
      .catch((error) => {
        this.requestFailed = true;

        errorInfo.respError = error;
        Sentry.captureException(new Error(`Web3 login email confirmation - login request failed.`, errorInfo));
      });
  }
}
