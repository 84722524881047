import axios from 'axios';
import Session from 'mewe/shared/session';
import FunctionalUtils from 'mewe/shared/functional-utils.js';
import { storeCollectionItems } from 'mewe/stores/models/collection';

export const fetchChallenges = () => {
  return axios
    .get('/api/v2/account/challenges-available', {
      headers: {
        'X-CSRF-Token': Session.getCsrfToken(),
        'content-type': 'application/json; charset=UTF-8',
      },
    })
    .then((res) => {
      if (res.data?.challenges?.length) {
        storeCollectionItems(ds.challenges, res.data.challenges);
      }
    })
    .catch((error) => {
      FunctionalUtils.showDefaultErrorMessage();

      // otherwise will keep refreshing until gw enabled
      if (!error.response || error.response.status !== 403) {
        window.location.reload();
      }
    });
};
