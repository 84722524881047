import { A } from '@ember/array';
import EmberObject, { computed } from '@ember/object';
import Ember from 'ember';
import { filterBy } from '@ember/object/computed';
import { each } from 'lodash';
import StoreItem from 'mewe/stores/models/store-item-model';
import StoreSubscription from 'mewe/stores/models/store-subscription-model';
import StorePayment from 'mewe/stores/models/store-payment-model';
import JSONSerializer from 'mewe/utils/store-utils/serializers/json-serializer';
import { storeData } from 'mewe/pods/components/routable/app-store/config-mewe-store-v6101';
import EmojiUtils from 'mewe/utils/emoji-utils';

const s = JSONSerializer.create();

const state = EmberObject.extend({
  cartItems: computed('cartItemsSelected.[]', () => A(state.get('cartItemsSelected'))),

  cartItemsSelected: filterBy('storeItems', 'isInCart', true),

  storeItems: A(s.deserializeMany(A(), StoreItem, storeData.items)),

  purchasedItems: A(),

  subscriptions: A(),
  subscriptionsLoaded: false,

  payments: A(),
  paymentsLoaded: false,

  cartTotalPrice: computed('cartItems.@each.priceComputed', () => {
    return (
      state.cartItems
        .map((i) => parseFloat(i.priceComputed))
        .reduce((a, b) => a + b, 0)
        .toFixed(2) || '0.00'
    );
  }),

  hasCallingSub: computed(
    'purchasedItems.length',
    () => !!state.get('purchasedItems').findBy('itemId', 'calls-voicevideo')
  ),

  hasDarkTheme: computed(
    'purchasedItems.length',
    () => !!state.get('purchasedItems').find((i) => i.itemId === 'theme-dark')
  ),

  hasDonorBadge: computed('purchasedItems.length', () => !!state.get('purchasedItems').findBy('itemId', 'donation')),

  hasStorageSub: computed(
    'purchasedItems.length',
    () => !!state.get('storeItems').findBy('itemId', 'storage').get('ownedTierItemId')
  ),

  hasJournals: computed(
    'purchasedItems.length',
    () =>
      !!state.get('purchasedItems').findBy('itemId', 'stories-journal') ||
      !!state.get('purchasedItems').findBy('itemId', 'premium')
  ),

  hasPremium: computed('purchasedItems.length', () => !!state.get('purchasedItems').findBy('itemId', 'premium')),

  hasMonthlyDonation: computed(
    'purchasedItems.length',
    () => !!state.get('purchasedItems').find((i) => i.itemId === 'donation-monthly')
  ),
}).create();

const getState = () => state;
const getItem = (itemId) => state.storeItems.findBy('itemId', itemId);
const getItemByProductId = (productId) =>
  state.storeItems.find((item) => item.productId === productId || productId.indexOf(item.productIdBase) !== -1);
const createItemInstance = (itemId) =>
  s.deserializeOne(
    StoreItem,
    storeData.items.find((i) => i.itemId === itemId)
  );

// hope to rework this function after I'll get BE improvements, for now it's hard to make without such hack with more general code as structure of Storage subscriptions is different
const setStorageSubscription = (purchasedItems) => {
  // there can be multiple storage items for different tiers if user downgraded his plan, trying to find the lowest one because it's always the current one
  const subscription =
    purchasedItems.find((i) => i.itemId === 'storage-monthly-t1') ||
    purchasedItems.find((i) => i.itemId === 'storage-monthly-t2') ||
    purchasedItems.find((i) => i.itemId === 'storage-monthly-t3');

  if (subscription && !subscription.isCancelled) {
    getItem('storage').setProperties({
      ownedTierItemId: subscription.itemId,
      provider: subscription.provider,
    });
  }
};

const self = EmberObject.extend(Ember.ActionHandler, {
  getState: getState,
  getItem: getItem, // get by itemId
  getItemByProductId: getItemByProductId, // get by productId
  createItemInstance: createItemInstance,

  actions: {
    handlePurchased: (purchased, isInitialFetch) => {
      const updatePurchasedItem = (storeItem, purchasedItem) => {
        storeItem.setProperties({
          isCancelled: purchasedItem.isCancelled,
          isInCart: false,
          provider: purchasedItem.provider,
          grantedBy: purchasedItem.grantedBy ? [purchasedItem.grantedBy] : null,
          expiresAt: purchasedItem.expiresAt,
        });
      };

      each(purchased, (p) => {
        const allItems = getState().get('storeItems');
        let storeItem = allItems.find((i) => i.itemId === p.itemId);

        if (storeItem) {
          updatePurchasedItem(storeItem, p);
        }

        // semi-hack, we use premium-promotion item in store so that item has to be updated
        if (p.itemId === 'premium') {
          storeItem = getItem('premium-promotion');
          updatePurchasedItem(storeItem, p);
        }

        if (p.itemId === 'premium' || p.itemId === 'premium-promotion') {
          const premiumGrantedItems = allItems.filter((i) => i.grantedBy?.length && ~i.grantedBy.indexOf('premium'));
          each(premiumGrantedItems, (i) => i.set('isGranted', true));

          EmojiUtils.addPurchasedItems(premiumGrantedItems); // this will make granted emoji packs available in picker
        }
      });

      setStorageSubscription(purchased);

      if (isInitialFetch) getState().set('purchasedItems', A(purchased));
      else getState().get('purchasedItems').pushObjects(purchased);
    },

    handleCart: (items) => {
      each(items, (i) => {
        const opts = i.split('_');
        let storeItem = getItem(opts[0]);

        if (storeItem) {
          storeItem.setProperties({ isInCart: true, selectedTierId: opts[1], optionalSubState: opts[2] });
        }
      });
    },

    handleSubscriptions: (items) => {
      let subscriptions = s.deserializeMany(A(), StoreSubscription, items);
      each(subscriptions, (s) => s.set('storeItem', getItemByProductId(s.productId)));

      getState().set('subscriptions', subscriptions);
      getState().set('subscriptionsLoaded', true);
    },

    handlePayments: (items) => {
      getState().set('payments', s.deserializeMany(A(), StorePayment, items));
      getState().set('paymentsLoaded', true);
    },
  },
});

export default self.create();
