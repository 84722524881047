import { computed } from '@ember/object';
import { A } from '@ember/array';
import Model from 'mewe/utils/store-utils/model/model';
import Emojis from './emojis-model';
import { attr, belongsTo } from 'mewe/utils/store-utils/model/attribute';
import { modelText } from 'mewe/stores/models/mixins/model-text';
import { modelEmojisReady } from 'mewe/stores/models/mixins/model-emojis-ready';
import { modelEmojisList } from 'mewe/stores/models/mixins/model-emojis-list';
import { modelGifUrls } from 'mewe/stores/models/mixins/model-gif-urls';
import { modelOwner } from 'mewe/stores/models/mixins/model-owner';
import toDisplay from 'mewe/stores/text-parsers/to-display-comment';
import CurrentUserStore from 'mewe/stores/current-user-store';

var model = Model.extend(modelEmojisReady, modelEmojisList, modelText, modelGifUrls, modelOwner, {
  id: attr('string'),
  userId: attr('string'),
  postId: attr('string'),
  postItemId: attr('string'),
  textServer: attr('string'),
  createdAt: attr('number'),
  scope: attr('string'), // <--- ss
  sharedPostId: attr('string'), // <--- ss
  searchGroupId: attr('string'), // <--- ss
  groupId: attr('string'),
  eventId: attr('string'),
  pageId: attr('string'),
  editedAt: attr('number'),
  repliesCount: attr('number', {
    defaultValue: 0,
  }),
  replyTo: attr('string'),
  canRemove: attr('boolean'),
  canDownload: attr('boolean'),
  canEdit: attr('boolean'),
  canEmojify: attr('boolean', {
    defaultValue: true,
  }),
  pending: attr('boolean'),
  system: attr('string'),
  isNew: attr('boolean'),
  hideNew: attr('boolean'),
  photo: attr('object'),
  link: attr('object'),
  language: attr('string'),
  audio: attr('object'),
  textParts: attr('array'),
  follows: attr('boolean'),
  document: attr('object'),
  replies: attr('array', {
    defaultValue: function () {
      return A();
    },
  }),
  isComment: attr('boolean', {
    defaultValue: true,
  }),
  emojis: belongsTo(Emojis, {
    defaultValue: function () {
      return Emojis.create();
    },
  }),
  postedByPage: attr('boolean'),
  notSaved: attr('boolean'),

  stickers: attr('object'),

  sticker: computed('stickers', function () {
    return this.get('stickers.length') ? this.get('stickers')[0] : null;
  }),

  textDisplay: computed('textServer', 'emojisReady', 'stickers', function () {
    const options = {
      groupId: this.get('groupId'),
      eventId: this.get('eventId'),
      textPartsHighlighted: this.get('textPartsHighlighted'),
      noStickers: this.get('stickers.length') > 0,
    };
    return this.postparser(toDisplay(this.preparser(this.get('textServer')), undefined, options));
  }),

  textPartsHighlighted: computed('textParts', function () {
    return this.get('textParts') ? A(this.get('textParts')).filterBy('isHighlighted') : null;
  }),

  isCurrentUserCommentOwner: computed('owner.id', function () {
    let currentUser = CurrentUserStore.getState();
    if (!currentUser || !this.owner) return false;
    return this.owner.id === currentUser.id;
  }),
});

model.reopenClass({
  resourceName: 'comment',
  aliases: {
    text: 'textServer',
  },
});

export default model;
